import React from "react";
import { IoSearchSharp } from "react-icons/io5";
import {
  Modal,
  ModalHeader,
  ModalBody,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from "reactstrap";
import searchObjects from "./searchObjects.json";

const SearchModal = ({ toggleSearch, searchModal, setSearchModal }) => {
  const [nameFocus, setNameFocus] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");
  const [filteredSearchValue, setFilteredSearchValue] = React.useState([]);

  // Cümleyi almak için fonksiyon
  const getSentenceWithHighlightedWord = (content, searchTerm) => {
    const lowerSearchTerm = searchTerm.toLowerCase();

    // İçeriği cümlelere bölelim
    const sentences = content.split(/([.!?])/); // Noktalama işaretlerinden cümleleri ayırıyoruz
    let resultSentence = "";

    // Cümlelerde arama terimini bulalım
    for (let i = 0; i < sentences.length; i++) {
      const sentence = sentences[i].trim();
      if (sentence.toLowerCase().includes(lowerSearchTerm)) {
        // Kelimenin geçtiği cümleyi bulduk
        // Kelimeyi sarı arka planla vurgulamak için
        resultSentence = sentence.replace(
          new RegExp(`(${searchTerm})`, "gi"),
          "<span style='background-color: yellow;'>$1</span>"
        );
        break;
      }
    }

    return resultSentence;
  };

  React.useEffect(() => {
    if (searchValue === "") {
      setFilteredSearchValue([]);
    } else {
      setFilteredSearchValue(
        searchObjects.filter(
          (values) =>
            values.title
              .toLocaleLowerCase("tr-TR")
              .includes(searchValue.toLocaleLowerCase("tr-TR")) ||
            values.content
              .toLocaleLowerCase("tr-TR")
              .includes(searchValue.toLocaleLowerCase("tr-TR"))
        )
      );
    }
  }, [searchValue]);

  return (
    <>
      <Modal isOpen={searchModal} toggle={toggleSearch} scrollable={true}>
        <ModalHeader toggle={toggleSearch} style={{ alignSelf: "center" }}>
          <h4>Site İçi Arama</h4>
        </ModalHeader>
        <ModalBody>
          <InputGroup
            className={
              nameFocus
                ? "no-border input-lg input-group-focus"
                : "no-border input-lg"
            }
          >
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="now-ui-icons">
                  <IoSearchSharp />
                </i>
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Aramak istediğiniz metni giriniz..."
              type="text"
              onFocus={() => setNameFocus(true)}
              onBlur={() => setNameFocus(false)}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            ></Input>
          </InputGroup>
          <ol>
            {filteredSearchValue.map((value) => {
              // Content içinde eşleşen kelimenin bulunduğu cümleyi alıyoruz
              const highlightedSentence = getSentenceWithHighlightedWord(value.content, searchValue);

              return (
                <li key={value.url}>
                  <a href={value.url}>{value.title}</a>
                  <p
                    style={{ color: "black" }}
                    dangerouslySetInnerHTML={{ __html: highlightedSentence }}
                  ></p>
                </li>
              );
            })}
          </ol>
        </ModalBody>
      </Modal>
    </>
  );
};

export default SearchModal;
