import WhiteNavbar from "components/Navbars/WhiteNavbar";
import React, { useEffect } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async";
import { FaCheck } from "react-icons/fa";
import Footer from "components/Footers/Footer";
import { HashLink } from "react-router-hash-link";
import { useSize } from "components/SizeControl/SizeControl";
import { Accordion, AccordionTab } from "primereact/accordion";
import imageHeader from "../assets/ossaPictures/fizyoterapi/alanlar/SkolyozHeader.png";
import PictureHeader from "components/PictureHeader/PictureHeader";
import { useNavigate } from "react-router-dom";

const Skolyoz = () => {
  const { under1200, under992 } = useSize();
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
    const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin

    if (targetLink) {
      // Otomatik tıklama
      targetLink.click();
      const imgElement = document.querySelector(".gt-current-lang img");
      const newImgSrc = "https://cdn.gtranslate.net/flags/32/tr.png"; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
      console.log(imgElement);
      imgElement.src = newImgSrc;
      imgElement.width = "32";
      imgElement.height = "32";
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>SKOLYOZ | OSSA CLINIC</title>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="OSSA CLINIC sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
          data-rh="true"
        />
        <link
          rel="canonical"
          href="https://www.ossaclinic.com"
        />
        <meta property="og:title" content="SKOLYOZ | OSSA CLINIC" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="OSSA CLINIC sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
        />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content="https://www.ossaclinic.com"
        />
      </Helmet>
      <WhiteNavbar navbarSelection={"about-us"} />
      <PictureHeader imageHeader={imageHeader} />
      <div>
        <Container style={{ fontFamily: "Nucleo Outline" }}>
          <div>
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>SKOLYOZ</b>
            </h2>
          </div>
          <Row>
            <Col className="ml-auto" md="6" style={{ alignContent: "center" }}>
              <img
                alt="..."
                className="img rounded"
                src={require("assets/ossaPictures/fizyoterapi/alanlar/skolyoz.png")}
              ></img>
            </Col>
            <Col
              className="mr-auto mt-5 mt-md-3"
              md="6"
              data-aos="zoom-in"
              data-aos-duration="2000"
              style={{ alignSelf: "center" }}
            >
              <p>
                <b>Skolyoz</b>, omurganın yana doğru eğrilmesi ile karakterize
                olan bir durumdur. Bu sorun, özellikle çocuklarda görülen yaygın
                bir omurga problemidir. <b>Ossa Fizik Tedavi Merkezi</b>, uzman
                fizyoterapistleri ve son teknolojik cihazları ile skolyoz
                tedavisi konusunda etkili çözümler sunmaktadır.
              </p>

              <p>
                <b>Ossa Fizik Tedavi Merkezi</b>'nde skolyoz tedavisinde
                kullanılan yöntemler arasında manuel terapi, egzersizler,
                akupunktur, masaj ve lazer terapi yer almaktadır. Uzman
                fizyoterapistler, hastaların bireysel ihtiyaçlarına göre özel
                tedavi planları hazırlayarak skolyozun semptomlarını azaltmak ve
                hareket kabiliyetini artırmak için çalışır.
              </p>

              <p>
                <b>Ossa Fizik Tedavi Merkezi</b>'nin skolyoz tedavisi, sadece
                semptomları hafifletmekle kalmaz, aynı zamanda sorunun kökenine
                inerek nedenlerini de ortadan kaldırmaya çalışır. Bu sayede,
                hastaların skolyoz problemleri uzun vadede tekrarlamaz. Ayrıca,
                merkezin uzman diyetisyenleri de müşterilere sağlıklı beslenme
                alışkanlıkları kazandırmak ve skolyoz sorunlarını önlemek için
                özel beslenme programları hazırlamaktadır. Sonuç olarak,{" "}
                <b>Ossa Fizik Tedavi Merkezi</b>, skolyoz tedavisi konusunda
                uzman kadrosu ve son teknolojik cihazları ile müşterilerine en
                iyi hizmeti sunmaktadır.
              </p>
            </Col>
          </Row>
          <div className="title">
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>SIKÇA SORULAN SORULAR</b>
            </h2>
          </div>
          <br></br>
          <div className="card">
          <Accordion>
                <AccordionTab header="Skolyoz nedir?">
                    <p className="panel-txt">
                        Skolyoz, omurganın yana doğru eğriliği ile karakterize bir durumdur.
                    </p>
                </AccordionTab>
                <AccordionTab header="Skolyoz neyin sonucunda oluşur?">
                    <p className="panel-txt">
                        Skolyoz, genellikle nedeni bilinmeyen idiopatik olarak adlandırılan bir durum olmakla birlikte, bazı durumlarda nöromüsküler hastalıklar veya doğumsal anomaliler de skolyoza neden olabilir.
                    </p>
                </AccordionTab>
                <AccordionTab header="Skolyoz hangi yaşlarda ortaya çıkabilir?">
                    <p className="panel-txt">
                        Skolyoz genellikle çocukluk veya adolesan dönemlerinde ortaya çıkar.
                    </p>
                </AccordionTab>
                <AccordionTab header="Skolyozun belirtileri nelerdir?">
                    <p className="panel-txt">
                        Skolyozun belirtileri arasında omurga eğriliği, sırtta asimetri, omuz veya kalça yüksekliği, sırt ağrısı sayılabilir.
                    </p>
                </AccordionTab>
                <AccordionTab header="Skolyoz nasıl teşhis edilir?">
                    <p className="panel-txt">
                        Skolyoz, fizik muayene, röntgen, MRI ve/veya diğer görüntüleme testleri ile teşhis edilebilir.
                    </p>
                </AccordionTab>
                <AccordionTab header="Skolyoz nasıl tedavi edilir?">
                    <p className="panel-txt">
                        Skolyoz tedavisi, durumun ciddiyetine ve hastanın yaşı dahil olmak üzere çeşitli faktörlere bağlı olarak değişebilir. Fizik tedavi, korse, egzersiz, cerrahi müdahale gibi yöntemler kullanılabilir.
                    </p>
                </AccordionTab>
                <AccordionTab header="Skolyoz korsesi ne işe yarar?">
                    <p className="panel-txt">
                        Skolyoz korsesi, skolyozun ilerlemesini yavaşlatmak ve omurganın daha fazla eğrilmemesini sağlamak için kullanılır.
                    </p>
                </AccordionTab>
                <AccordionTab header="Skolyoz cerrahi müdahale gerektirir mi?">
                    <p className="panel-txt">
                        Skolyozun şiddetine ve ilerlemesine bağlı olarak, bazı durumlarda cerrahi müdahale gerekebilir.
                    </p>
                </AccordionTab>
                <AccordionTab header="Ossa Fizik Tedavi merkezi, skolyoz tedavisinde nasıl yardımcı olabilir?">
                    <p className="panel-txt">
                        Ossa Fizik Tedavi merkezi, skolyoz tedavisi konusunda uzman bir ekip sunar. Uzmanlarımız, skolyoz korsesi tedavisi, egzersiz terapisi ve manuel terapi gibi yöntemlerle tedaviye yaklaşırlar.
                    </p>
                </AccordionTab>
                <AccordionTab header="Skolyoz tedavisinde neden Ossa Fizik Tedavi merkezi tercih edilmelidir?">
                    <p className="panel-txt">
                        Ossa Fizik Tedavi merkezi, skolyoz tedavisinde son derece deneyimli bir ekibe sahiptir ve hastaların ihtiyaçlarına uygun özelleştirilmiş tedavi planları sunar. Yeni teknolojiler ile donatılmış kliniklerimizde en etkili tedavileri sunuyoruz.
                    </p>
                </AccordionTab>
            </Accordion>
          </div>
          <div>
            <div className="title">
              <h2
                className="ml-auto mr-auto text-center"
                style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                <b>VİDEO İÇERİKLERİ</b>
              </h2>
            </div>
            <br></br>
            <br></br>

            <Row className="justify-content-center">
              <Col md="6">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/AEimikwyZ9E?si=1u6bQlu2zhhz40x-"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </Col>
              <Col md="6">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/HFng8vCnUgQ?si=ClJr2QGujCiyG9tD"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </Col>
              
            </Row>
          </div>
          <div className="team-2 pb-0 pt-0">
          <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <div className="title">
                    <h2
                      className="title ml-auto mr-auto text-center"
                      style={{
                        color: "#123274",
                        fontFamily: "Benguiat Regular",
                      }}
                    >
                      DİĞER UZMANLIK ALANLARIMIZ
                    </h2>
                  </div>
                </Col>
              </Row>
              <Row data-aos="fade-up" data-aos-duration="1000">
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/bel-fitigi")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/belFitigi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/boyun-agrisi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/boyunAgrisi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/diz-agrisi")
                        }
                      >
                        <img
                          alt="..."
                          style={{ border: "1px solid black" }}
                          className="img img-raised rounded img-hover"
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/dizAgrisi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row data-aos="fade-up" data-aos-duration="1000">
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/omurga-sagligi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/omurgaSagligi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/gebelik-platesi")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/gebelikPlates.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/sporcu-sagligi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          style={{ border: "1px solid black" }}
                          className="img img-raised rounded img-hover"
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/sporcuSagligi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row
                data-aos="fade-up"
                data-aos-duration="1000"
                style={{ justifyContent: "center" }}
              >
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/sirt-agrisi")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/sırtAgrisi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/osteopati")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/osteopati.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/recovery")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/recovery.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                
              </Row>
              <Row
                data-aos="fade-up"
                data-aos-duration="1000"
                style={{ justifyContent: "center" }}
              >
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/manuel-terapi")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/manuelTerapi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/rehabilitasyonlar")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/rehabilitasyon.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/klinik-plates")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/klinikPlates.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
             
            </Container>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default Skolyoz;
