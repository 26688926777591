import React, { useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { TypeAnimation } from "react-type-animation";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import WhiteNavbar from "components/Navbars/WhiteNavbar";
import { Button, Card, CardTitle, Col, Container, Row } from "reactstrap";
import { AiFillBackward, AiFillForward } from "react-icons/ai";
import { Link, useParams } from "react-router-dom";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import "../../assets/css/content-styles.css"

const PostFrameworkUpdated = ({ post, posts }) => {
  const [modal, setModal] = React.useState(false);
  const [toggled, setToggled] = React.useState(null);
  const [toggleWidth, setToggleWidth] = React.useState(false);
  const [blogLinkIndex,setBlogLinkIndex] = React.useState(null);
  const [previousPost,setPreviousPost] = React.useState()
  const [nextPost,setNextPost] = React.useState()

  const reversedData = [...posts].reverse();

  useEffect(()=>{
    function nextItem(array, currentItem) {
      const currentIndex = array.indexOf(currentItem); // Geçerli öğenin dizideki indeksi
      if (currentIndex === -1 || currentIndex === array.length - 1) {
        // Geçerli öğe dizide bulunmuyor veya son öğe ise
        return null; // Sonraki öğe yok, null döndür
      } else {
        return array[currentIndex + 1]; // Sonraki öğeyi döndür
      }
    }
  
    function previousItem(array, currentItem) {
      const currentIndex = array.indexOf(currentItem); // Geçerli öğenin dizideki indeksi
      if (currentIndex === -1 || currentIndex === 0) {
        // Geçerli öğe dizide bulunmuyor veya ilk öğe ise
        return null; // Önceki öğe yok, null döndür
      } else {
        return array[currentIndex - 1]; // Önceki öğeyi döndür
      }
    }
    setPreviousPost(previousItem(posts, post)) ;
    setNextPost(nextItem(posts, post)) ;
  },[posts,post])
  

  const handleResize = () => {
    if (window.innerWidth < 1200) {
      setToggleWidth(true); // Renamed state variable
    } else {
      setToggleWidth(false); // Renamed state variable
    }
  };

  React.useEffect(() => {
    handleResize(); // Set initial state based on window width
    window.addEventListener('resize', handleResize); // Add event listener for window resize
    return () => {
      window.removeEventListener('resize', handleResize); // Clean up event listener
    };
  }, []);
  // console.log(toggled)


 
  return (
    <div style={{ fontFamily: "Nucleo Outline" }}>
      <WhiteNavbar />
      <div style={{ height: !toggleWidth && "3em" }}></div>
      <div className="section">
        <Container>
          <Row>
            <Col
              xl="3"
              className="section hideScroll widthResponsive"
              style={{
                position: toggled === true ? "relative" : "fixed",
                overflowY: "scroll",
                top: 0,
                bottom: 0,
              }}
            >
              <Sidebar
                width={toggled === true ? "100%" : "90%"}
                backgroundColor={toggled ? "#ffffff" : undefined}
                onClick={() => setToggled(false)}
                toggled={toggled}
                breakPoint="xl"
                className="deneme"
              >
                <h2
                  className="title text-center baslık"
                  style={{
                    backgroundColor: "#fff",
                    marginTop: toggleWidth ? "70px" : "60px",
                  }}
                >
                  {" "}
                  Güncel Yazılar
                </h2>
                <Menu
                closeOnClick="true"
                  menuItemStyles={{
                    button: ({ level, active }) => {
                      // only apply styles on first level elements of the tree
                      console.log(level);
                      if (level === 0)
                        return {
                          "&:hover": {
                            color: active ? "white" : "black",
                            backgroundColor: active ? "#095891" : undefined,
                          },
                          backgroundColor: active ? "#095891" : undefined,
                          color: active ? "white" : "black",
                          zIndex: "5",
                          borderRadius: "12px",
                          padding: "15px",
                        };
                    },
                    label: { whiteSpace: "normal" },
                  }}
                >
                  {reversedData?.map((post, index) => {
                    return (
                      <MenuItem
                        component={
                          <Link
                            to={"/blog/" + post?.slug}
                            onClick={() => window.scrollTo(0, 0)}
                          />
                        }
                        active={window.location.href.endsWith(
                          "/blog/" + post?.slug
                        )}
                        style={{ height: "auto" }}
                      >
                        {post.title}
                      </MenuItem>
                    );
                  })}
                </Menu>
              </Sidebar>
            </Col>

            <Col className="ml-auto col-xl-9">
              <br></br>
              <br></br>
              <Card className="card-plain card-blog">
                <div className="card-image">
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="..."
                      className="img img-raised rounded postWidthHeight"
                      src={post?.title_image}
                      // width="1124"
                      // height="941.150"
                    ></img>
                  </a>
                </div>

               
                <div className="blog-content image-style-align-center">
                  <h1 className="title" style={{fontFamily:"Benguiat Regular", textAlign:"center"}}>{post?.title}</h1>
                  {ReactHtmlParser(post?.content)}
                </div>
                <br></br>
                <Col className="ml-auto mx-auto text-center">
                  {post?.id !== posts?.[posts.length-1].id && (
                    <a
                      href={`/blog/${
                        nextPost?.slug
                      }`}
                      style={{ display: "inline-block", height: "2rem" }}
                    >
                      <AiFillBackward size={30} />
                      <span>
                        <strong
                          style={{ verticalAlign: "-webkit-baseline-middle" }}
                        >
                          Önceki Post
                        </strong>
                      </span>
                    </a>
                  )}
                  &emsp; &emsp; &emsp; &emsp;
                  {post?.id !== posts?.[0].id && (
                    <a
                    href={`/blog/${
                      previousPost?.slug
                    }`}
                      style={{ display: "inline-block", height: "2rem" }}
                    >
                      <span>
                        <strong
                          style={{ verticalAlign: "-webkit-baseline-middle" }}
                        >
                          Sonraki Post
                        </strong>
                      </span>
                      <AiFillForward size={30} />
                    </a>
                  )}
                  <br></br>
                  {toggleWidth && (
                    <Button
                      className="sb-button text-center"
                      style={{ backgroundColor: "#095891" }}
                      onClick={() => setToggled(!toggled)}
                    >
                      Güncel Yazılar
                    </Button>
                  )}
                </Col>
                <br></br>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default PostFrameworkUpdated;
