import React, { useState, useEffect } from "react";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container } from "reactstrap";
import Aos from "aos";
import { Button } from "reactstrap";

const GoogleReviews = () => {
  const [reviews, setReviews] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    Aos.init();
  }, []);

  useEffect(() => {
    axios
      .get("https://ossaklinik.pythonanywhere.com/api/reviews/")
      .then((response) => {
        setReviews(response.data);
      })
      .catch((error) => {
        setError(error);
      });
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  // Sort reviews by review_date in descending order
  const sortedReviews = reviews.sort((a, b) => new Date(b.review_date) - new Date(a.review_date));

  // Display only the most recent 5 reviews
  const recentReviews = sortedReviews.slice(0, 5);

  return (
    <div style={{ fontFamily: "Nucleo Outline"}}>
      <Container data-aos="fade-up" data-aos-duration="1000" >
      <div style={{ height:"5rem"}}></div>
        <div className="title">
          <h2 className="title ml-auto mr-auto text-center" style={{ color: "#12683e" ,fontFamily:"Benguiat Regular"}}>
            GOOGLE YORUMLARI
          </h2>
        </div>
        <br/>
        <h3 style={{display:"none"}}>Beginner Driver Education-Online G1 Preparation-In-Car Driving-Lessons-G/G2 Road Test.</h3>
        {recentReviews.length > 0 && (
          <div style={{overflowX: window.innerWidth<=1200 && "hidden", height: window.innerWidth<=1200 && "600px"}}>
            <Slider
              dots={true}
              infinite={true}
              speed={500}
              slidesToShow={1}
              slidesToScroll={1}
            >
              {recentReviews.map((review) => (
                <div key={review.id} style={{ textAlign: "center" }}>
                  <div className="info" style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                    <div className="icon icon-circle">
                      <i className="now-ui-icons">
                        <img src={review.profile_photo_url} alt="profilePhoto" width="auto" height="auto" />
                      </i>
                    </div>
                    <h4 className="info-title">{review.author_name}</h4>
                    
                    <p style={{ textAlign: "center", fontWeight: "bold",lineHeight:"2rem" }}>
                      <b>Yorum Tarihi:</b> {new Date(review.review_date).toLocaleDateString()}
                    </p>
                    <h5 style={{ textAlign: "center",lineHeight:"2rem" }}>
                      <b>Derecelendirme:</b> ⭐⭐⭐⭐⭐
                    </h5>
                  </div>
                  <p className="hideScroll" style={{ textAlign: "center", height:"20rem", overflowY:"scroll",fontSize:"15px"}}>{review.text}<br></br>
                  <Button
                            className="btn"
                            style={{ backgroundColor: "#00b25a",textAlign: "center"}}
                            target="_blank"
                            href="https://www.google.com/maps/place/ANTE+SA%C4%9ELIK+Ossa+Clinic+Fizyoterapi+ve+Sa%C4%9Fl%C4%B1kl%C4%B1+Ya%C5%9Fam+Merkezi/@40.7684633,29.9655973,17z/data=!4m8!3m7!1s0x14cb4fe137de965b:0x48c87ec869c185c4!8m2!3d40.7684593!4d29.9681722!9m1!1b1!16s%2Fg%2F11hd06cbfm?entry=ttu"
                            
                          >
                            Tüm Yorumları Gör
                          </Button>
                  </p>
                  
                </div>
              ))}
            </Slider>
            
          </div>
        )}
      </Container>
      <br></br>
      <br></br>
    </div>
  );
};

export default GoogleReviews;
