import Aos from "aos";
import axios from "axios";
import Footer from "components/Footers/Footer";
import WhiteNavbar from "components/Navbars/WhiteNavbar";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState("");
  React.useEffect(() => {
    document.body.classList.add("contact-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("contact-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  useEffect(() => {
    Aos.init();
  }, []);
  useEffect(() => {
    axios
      .get("https://ossaklinik.pythonanywhere.com/postApi/posts/")
      .then((response) => {
        setPosts(response.data);
      })
      .catch((error) => {
        setError(error);
      });
  }, []);

  useEffect(() => {
    console.log(posts);
  }, [posts]);

  const reversedData = [...posts].reverse();

  // Veriyi 3'erli gruplara bölmek için bir yardımcı fonksiyon
  const chunkArray = (array, size) => {
    const chunkedArr = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArr.push(array.slice(i, i + size));
    }
    return chunkedArr;
  };

  // Veriyi 3'erli gruplara böler
  const chunkedData = chunkArray(reversedData, 3);
  console.log(chunkedData);
  return (
    <>
      <Helmet>
        <title>BLOG SAYFASI | OSSA CLINIC</title>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="Önemli Bilgiler | OSSA CLINIC"
          data-rh="true"
        />
        <link rel="canonical" href="/blog" />
        <meta property="og:title" content="BLOG SAYFASI | OSSA CLINIC" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="Önemli Bilgiler | OSSA CLINIC"
        />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content="https://www.ossaclinic.com/blog"
        />
      </Helmet>
      <div style={{ fontFamily: "Nucleo Outline" }}>
        <WhiteNavbar />
        <Container>
          <div className="section">
            <div style={{ height: "3em" }}></div>
            <Row >
              <Col
                className="ml-auto mr-auto text-center"
                
                md="8"
              >
                <div className="title">
                  <h2 className="title ml-auto mr-auto text-center baslık">
                    BLOG YAZILARI
                  </h2>
                </div>
                <div style={{ height: "2em" }}></div>
              </Col>
            </Row>
            {chunkedData.map((row, index) => (
              <Row data-aos={index === 0 ? undefined : "fade-up"} data-aos-duration={index === 0 ? undefined : "1000"}>
                {row.map((item) => (
                  <Col key={item.id} md="4">
                  <Card className="card-plain card-blog">
                    <div className="card-image">
                      <a href={`/blog/${item?.slug}`}>
                        <img
                          alt="..."
                          className="img rounded img-raised"
                          src={item?.title_image}
                          height="238.33"
                          width="476.66"
                        ></img>
                      </a>
                    </div>
                    <CardBody>
                      <CardTitle tag="h4">
                        <a
                          href={`/blog/${item?.slug}`}
                          style={{ color: "#060c86", borderStyle: "none" }}
                        >
                          {item?.title}
                        </a>
                      </CardTitle>
                      <div>
                        {item?.short_description}... &ensp;
                        <a href={`/blog/${item?.slug}`}>Devamını Oku</a>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                ))}
              </Row>
            ))}
            
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default Blog;
