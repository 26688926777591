import WhiteNavbar from "components/Navbars/WhiteNavbar";
import React, { useEffect } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async";
import { FaCheck } from "react-icons/fa";
import Footer from "components/Footers/Footer";
import { HashLink } from "react-router-hash-link";
import { useSize } from "components/SizeControl/SizeControl";
import { Accordion, AccordionTab } from "primereact/accordion";
import imageHeader from "../assets/ossaPictures/fizyoterapi/alanlar/DizAgrisiHeader.png";
import PictureHeader from "components/PictureHeader/PictureHeader";
import { useNavigate } from "react-router-dom";

const DizAgrisi = () => {
  const { under1200, under992 } = useSize();
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
    const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin

    if (targetLink) {
      // Otomatik tıklama
      targetLink.click();
      const imgElement = document.querySelector(".gt-current-lang img");
      const newImgSrc = "https://cdn.gtranslate.net/flags/32/tr.png"; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
      console.log(imgElement);
      imgElement.src = newImgSrc;
      imgElement.width = "32";
      imgElement.height = "32";
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>DİZ AĞRISI | OSSA CLINIC</title>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="OSSA CLINIC sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
          data-rh="true"
        />
        <link
          rel="canonical"
          href="https://www.ossaclinic.com"
        />
        <meta property="og:title" content="DİZ AĞRISI | OSSA CLINIC" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="OSSA CLINIC sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
        />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content="https://www.ossaclinic.com"
        />
      </Helmet>
      <WhiteNavbar navbarSelection={"about-us"} />
      <PictureHeader imageHeader={imageHeader} />
      <div>
        <Container style={{ fontFamily: "Nucleo Outline" }}>
          <div>
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>DİZ AĞRISI</b>
            </h2>
          </div>
          <Row>
            <Col className="ml-auto" md="6" style={{ alignContent: "center" }}>
              <img
                alt="..."
                className="img rounded"
                src={require("assets/ossaPictures/fizyoterapi/alanlar/dizAgrisi.png")}
              ></img>
            </Col>
            <Col
              className="mr-auto mt-5 mt-md-3"
              md="6"
              data-aos="zoom-in"
              data-aos-duration="2000"
              style={{ alignSelf: "center" }}
            >
              <p>
                Diz ağrısı, yaşamın herhangi bir döneminde ortaya çıkabilen
                yaygın bir sorundur. Diz ağrısı, hareketsiz yaşam tarzı, spor
                yaralanmaları veya yaşlanma gibi nedenlerle oluşabilir.{" "}
                <b>Ossa Fizik Tedavi Merkezi</b>, uzman fizyoterapistleri ve son
                teknolojik cihazları ile müşterilerine diz ağrısı tedavisi
                konusunda etkili çözümler sunmaktadır.
              </p>
              <p>
                <b>Ossa Fizik Tedavi Merkezi</b>'nde diz ağrısı tedavisinde
                kullanılan yöntemler arasında manuel terapi, egzersizler,
                akupunktur, masaj ve lazer terapi yer almaktadır. Uzman
                fizyoterapistler, hastaların bireysel ihtiyaçlarına göre özel
                tedavi planları hazırlayarak diz ağrısını azaltmak ve hareket
                kabiliyetini artırmak için çalışır.
              </p>
              <p>
                <b>Ossa Fizik Tedavi Merkezi</b>'nin diz ağrısı tedavisi, sadece
                semptomları hafifletmekle kalmaz, aynı zamanda sorunun kökenine
                inerek nedenlerini de ortadan kaldırmaya çalışır. Bu sayede,
                hastaların diz ağrısı problemleri uzun vadede tekrarlamaz.
                Ayrıca, merkezin uzman diyetisyenleri de müşterilere sağlıklı
                beslenme alışkanlıkları kazandırmak ve diz ağrısı sorunlarını
                önlemek için özel beslenme programları hazırlamaktadır. Sonuç
                olarak, <b>Ossa Fizik Tedavi Merkezi</b>, diz ağrısı tedavisi
                konusunda uzman kadrosu ve son teknolojik cihazları ile
                müşterilerine en iyi hizmeti sunmaktadır.
              </p>
            </Col>
          </Row>
          <div className="title">
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>SIKÇA SORULAN SORULAR</b>
            </h2>
          </div>
          <br></br>
          <div className="card">
          <Accordion>
                <AccordionTab header="Diz ağrısı neden oluşur?">
                    <p className="panel-txt">
                        Diz ağrısı birçok nedenden kaynaklanabilir, bu nedenler arasında yaralanmalar, osteoartrit, romatoid artrit, menisküs yırtığı, bağ yaralanması ve tendonit sayılabilir.
                    </p>
                </AccordionTab>
                <AccordionTab header="Diz ağrısı ne kadar sürebilir?">
                    <p className="panel-txt">
                        Diz ağrısı süresi kişiden kişiye değişebilir. Hafif ağrılar genellikle birkaç gün içinde geçerken, ciddi yaralanmalar veya durumlarda aylarca sürebilir.
                    </p>
                </AccordionTab>
                <AccordionTab header="Diz ağrısı nasıl önlenir?">
                    <p className="panel-txt">
                        Diz ağrısını önlemek için düzenli egzersiz yapmak, uygun ayakkabı giymek, uygun teknikleri kullanarak spor yapmak, aşırı kilodan kurtulmak ve yeterli dinlenme almak önerilir.
                    </p>
                </AccordionTab>
                <AccordionTab header="Diz ağrısı için hangi doktora gitmeliyim?">
                    <p className="panel-txt">
                        Diz ağrısı için ortopedi uzmanına görünmek en uygun olanıdır.
                    </p>
                </AccordionTab>
                <AccordionTab header="Diz ağrısı için egzersiz yapmak zararlı mı?">
                    <p className="panel-txt">
                        Diz ağrısı için uygun egzersizler yapmak genellikle yararlıdır. Ancak özellikle ciddi bir yaralanması olan kişilerin önceden doktorlarına danışmaları önerilir.
                    </p>
                </AccordionTab>
                <AccordionTab header="Diz ağrısı tedavisi nasıl yapılır?">
                    <p className="panel-txt">
                        Diz ağrısı tedavisinde fizik tedavi, ilaçlar, cerrahi müdahale, enjeksiyonlar veya diğer alternatif tedaviler kullanılabilir.
                    </p>
                </AccordionTab>
                <AccordionTab header="Hangi durumlarda diz ağrısının ciddi olduğu söylenebilir?">
                    <p className="panel-txt">
                        Diz ağrısı ciddi bir yaralanmanın veya diğer sorunların belirtisi olabileceğinden, ciddi veya sürekli bir ağrı durumunda mutlaka doktora başvurulmalıdır.
                    </p>
                </AccordionTab>
                <AccordionTab header="Diz ağrısı için ne tür ilaçlar kullanılabilir?">
                    <p className="panel-txt">
                        Diz ağrısı için kullanılan ilaçlar arasında ağrı kesiciler, anti-inflamatuar ilaçlar ve kortikosteroidler bulunur.
                    </p>
                </AccordionTab>
                <AccordionTab header="Diz ağrısı tedavisi süresi nedir?">
                    <p className="panel-txt">
                        Diz ağrısı tedavi süresi kişiden kişiye farklılık gösterir. Tedavinin süresi genellikle yaralanmanın ciddiyetine ve tedavinin tipine bağlıdır.
                    </p>
                </AccordionTab>
                <AccordionTab header="Ossa Fizik Tedavi merkezi diz ağrısı tedavisi için nasıl yardımcı olabilir?">
                    <p className="panel-txt">
                        Ossa Fizik Tedavi merkezi diz ağrısı tedavisinde uzmanlaşmış bir ekip sunar. Uzman fizyoterapistleri, en son teknolojiyle donatılmış ekipmanları ve özelleştirilmiş tedavi planlarıyla diz ağrısı tedavisinde yardımcı olabilirler.
                    </p>
                </AccordionTab>
            </Accordion>
          </div>
          <div>
            <div className="title">
              <h2
                className="ml-auto mr-auto text-center"
                style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                <b>VİDEO İÇERİKLERİ</b>
              </h2>
            </div>
            <br></br>
            <br></br>

            <Row className="justify-content-center">
              <Col md="6">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/gKBXkJcZmJs?si=_J2ofEs1SkVe_7lW"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </Col>
              
            </Row>
          </div>
          <div className="team-2 pb-0 pt-0">
          <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <div className="title">
                    <h2
                      className="title ml-auto mr-auto text-center"
                      style={{
                        color: "#123274",
                        fontFamily: "Benguiat Regular",
                      }}
                    >
                      DİĞER UZMANLIK ALANLARIMIZ
                    </h2>
                  </div>
                </Col>
              </Row>
              <Row data-aos="fade-up" data-aos-duration="1000">
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/bel-fitigi")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/belFitigi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/boyun-agrisi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/boyunAgrisi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/gebelik-platesi")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/gebelikPlates.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row data-aos="fade-up" data-aos-duration="1000">
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/omurga-sagligi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/omurgaSagligi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/skolyoz")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/skolyoz.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/sporcu-sagligi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          style={{ border: "1px solid black" }}
                          className="img img-raised rounded img-hover"
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/sporcuSagligi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row
                data-aos="fade-up"
                data-aos-duration="1000"
                style={{ justifyContent: "center" }}
              >
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/sirt-agrisi")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/sırtAgrisi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/osteopati")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/osteopati.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/recovery")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/recovery.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                
              </Row>
              <Row
                data-aos="fade-up"
                data-aos-duration="1000"
                style={{ justifyContent: "center" }}
              >
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/manuel-terapi")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/manuelTerapi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/rehabilitasyonlar")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/rehabilitasyon.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/klinik-plates")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/klinikPlates.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
             
            </Container>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default DizAgrisi;
