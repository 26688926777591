import Aos from "aos";
import axios from "axios";
import Footer from "components/Footers/Footer";
import WhiteNavbar from "components/Navbars/WhiteNavbar";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

const Videolar = () => {
  const [videos, setVideos] = useState("");
  const [error, setError] = useState("");
  React.useEffect(() => {
    document.body.classList.add("contact-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("contact-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  useEffect(() => {
    Aos.init();
  }, []);

  useEffect(() => {
    axios
      .get("https://ossaklinik.pythonanywhere.com/videoApi/videos/")
      .then((response) => {
        setVideos(response.data);
      })
      .catch((error) => {
        setError(error);
      });
  }, []);

  const reversedData = [...videos].reverse();

  // Veriyi 3'erli gruplara bölmek için bir yardımcı fonksiyon
  const chunkArray = (array, size) => {
    const chunkedArr = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArr.push(array.slice(i, i + size));
    }
    return chunkedArr;
  };

  // Veriyi 3'erli gruplara böler
  const chunkedData = chunkArray(reversedData, 2);

  return (
    <>
      <Helmet>
        <title>VİDEOLAR | OSSA CLINIC</title>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="Videolar | OSSA CLINIC"
          data-rh="true"
        />
        <link rel="canonical" href="/videos" />
        <meta property="og:title" content="Vİdeo Sayfası | OSSA CLINIC" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Videolar | OSSA CLINIC" />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content="https://www.ossaclinic.com/videolar"
        />
      </Helmet>
      <div style={{ fontFamily: "Nucleo Outline" }}>
        <WhiteNavbar />
        <Container>
          <div className="section">
            <div style={{ height: "3em" }}></div>
            <Row>
              <Col
                className="ml-auto mr-auto text-center"
                data-aos="fade-up"
                data-aos-duration="1000"
                md="8"
              >
                <div className="title">
                  <h2 className="title ml-auto mr-auto text-center baslık">
                    VİDEOLAR
                  </h2>
                </div>
                <div style={{ height: "1em" }}></div>
              </Col>
            </Row>
            {chunkedData.map((row, index) => (
              <Row
                data-aos={index === 0 ? undefined : "fade-up"}
                data-aos-duration={index === 0 ? undefined : "1000"}
                style={{ textAlign: "center" }}
              >
                {row.map((item) => (
                  <Col key={item.id} xl="6">
                    <Card className="card-plain card-blog">
                      <div className="card-image">
                        
                        {ReactHtmlParser(item?.youtube_url)}
                      </div>
                      <CardBody>
                        <CardTitle tag="h4">
                          <div
                            style={{ color: "#060c86", borderStyle: "none" }}
                          >
                            <b>{item?.title}</b>
                          </div>
                        </CardTitle>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            ))}
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default Videolar;
