import WhiteNavbar from "components/Navbars/WhiteNavbar";
import React, { useEffect } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async";
import { FaCheck } from "react-icons/fa";
import Footer from "components/Footers/Footer";
import { HashLink } from "react-router-hash-link";
import { useSize } from "components/SizeControl/SizeControl";
import { Accordion, AccordionTab } from "primereact/accordion";
import imageHeader from "../assets/ossaPictures/fizyoterapi/alanlar/RehabilitasyonHeader.png";
import PictureHeader from "components/PictureHeader/PictureHeader";
import { useNavigate } from "react-router-dom";

const Rehabilitasyonlar = () => {
  const { under1200, under992 } = useSize();
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
    const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin

    if (targetLink) {
      // Otomatik tıklama
      targetLink.click();
      const imgElement = document.querySelector(".gt-current-lang img");
      const newImgSrc = "https://cdn.gtranslate.net/flags/32/tr.png"; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
      console.log(imgElement);
      imgElement.src = newImgSrc;
      imgElement.width = "32";
      imgElement.height = "32";
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>REHABİLİTASYONLAR | OSSA CLINIC</title>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="OSSA CLINIC sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
          data-rh="true"
        />
        <link
          rel="canonical"
          href="https://www.ossaclinic.com"
        />
        <meta property="og:title" content="REHABİLİTASYONLAR | OSSA CLINIC" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="OSSA CLINIC sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
        />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content="https://www.ossaclinic.com"
        />
      </Helmet>
      <WhiteNavbar navbarSelection={"about-us"} />
      <PictureHeader imageHeader={imageHeader} />
      <div>
        <Container style={{ fontFamily: "Nucleo Outline" }}>
          <div>
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>REHABİLİTASYONLAR</b>
            </h2>
          </div>
          <Row>
            <Col className="ml-auto" md="6" style={{ alignContent: "center" }}>
              <img
                alt="..."
                className="img rounded"
                src={require("assets/ossaPictures/fizyoterapi/alanlar/rehabilitasyon.png")}
              ></img>
            </Col>
            <Col
              className="mr-auto mt-5 mt-md-3"
              md="6"
              data-aos="zoom-in"
              data-aos-duration="2000"
              style={{ alignSelf: "center" }}
            >
              <h3><strong>Ante Sağlık Merkezi: Kapsamlı Rehabilitasyon Hizmetleri</strong></h3>
<p><strong>Ante Sağlık Merkezi</strong>, bireylerin sağlıklı bir yaşam sürdürmelerini desteklemek amacıyla kapsamlı rehabilitasyon hizmetleri sunmaktadır. Uzman ekibimiz, kişiye özel programlar geliştirmektedir. Rehabilitasyon hizmetlerimiz, hareket kısıtlılıklarını gidermeyi, ağrı yönetimini sağlamayı ve genel yaşam kalitesini artırmayı hedeflemektedir.</p>

<br/>

<h3><strong>Rehabilitasyon Hizmetlerimiz</strong></h3>
<p><strong>Ortopedik Rehabilitasyon:</strong> Ameliyat öncesi ve sonrası süreçte iskelet sistemindeki hastalıkların tedavisi için özel uygulamalar içerir.</p>
<p><strong>Nörolojik Rehabilitasyon:</strong> Sinir hasarına bağlı işlev kayıplarının tedavisinde, çeşitli fizyoterapi yöntemleri kullanılarak destek sağlar.</p>
<p><strong>Pulmoner Rehabilitasyon:</strong> Nefes darlığı çeken bireylerin akciğer sağlığını iyileştirmek için egzersiz ve eğitim programları sunar.</p>
<p><strong>Kas Hastalıkları Rehabilitasyonu:</strong> Kas kuvvetini artırmayı ve hareket sorunlarını önlemeyi hedefler.</p>
<p><strong>Obstetrik ve Ürojinekolojik Rehabilitasyon:</strong> Hamilelik ve doğum sonrası dönemlerde kadın sağlığını destekleyen egzersiz programları içerir.</p>
<p><strong>Sporcu Rehabilitasyonu:</strong> Spor yaralanmalarının hızlı ve etkili bir şekilde tedavi edilmesi için özel planlar hazırlar.</p>
<p><strong>Kardiyak Rehabilitasyon:</strong> Kalp hastalarının yaşam kalitesini artırmak için egzersiz ve eğitim programları uygular.</p>
<p><strong>Pediatrik Rehabilitasyon:</strong> Çocuklarda doğuştan veya sonradan gelen sorunların tedavisinde özel yöntemler kullanır.</p>
<p><strong>Ampute Rehabilitasyonu:</strong> Uzuv kaybı yaşayan bireylerin protez kullanma ve günlük hayata adaptasyon süreçlerini destekler.</p>
<p><strong>Kanser Rehabilitasyonu:</strong> Kanser tedavisine yardımcı olarak fiziksel yetersizliklerin giderilmesini hedefler.</p>
<p><strong>Vestibüler Rehabilitasyon:</strong> Denge kaybına sebep olan hastalıklar sonrası dengenin iyileştirilmesi hedeflenir.</p>

<br/>

<p><strong>Ante Sağlık Merkezi</strong>, tüm bu rehabilitasyon türlerini uzman sağlık profesyonelleri eşliğinde yürütmektedir. Bireylerin ihtiyaçlarına yönelik özel tedavi planları ile sağlıklarını en üst seviyeye taşımayı amaçlamaktayız.</p>

            </Col>
          </Row>
          <div className="title">
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>SIKÇA SORULAN SORULAR</b>
            </h2>
          </div>
          <br></br>
          <div className="card">
          <Accordion>
  <AccordionTab header="Rehabilitasyon nedir?">
    <p>
      Rehabilitasyon, bireylerin fiziksel ve ruhsal engellerini gidermeye yönelik uygulamalar bütünüdür ve bağımsız yaşam becerilerini kazandırmayı hedefler. Ante Sağlık Merkezi olarak, bu süreçte uzman ekibimizle kişiye özel tedavi programları sunarak bireylerin sağlıklarını en üst düzeye çıkarmayı amaçlıyoruz.
    </p>
  </AccordionTab>
  <AccordionTab header="Rehabilitasyon türleri nelerdir?">
    <p>
      Ante Sağlık Merkezi'nde uygulanan rehabilitasyon türleri arasında ortopedik, nörolojik, sporcu, kardiyak ve pediatrik rehabilitasyon yer almaktadır. Her bir tür, bireylerin özel ihtiyaçlarına göre özelleştirilmiş programlarla desteklenerek fiziksel bağımsızlıklarını kazanmalarına yardımcı olmaktadır.
    </p>
  </AccordionTab>
  <AccordionTab header="Rehabilitasyon hizmetleri neden önemlidir?">
    <p>
      Rehabilitasyon hizmetleri, bireylerin fiziksel iyilik hallerini artırarak bağımsız yaşamlarını sürdürebilmelerini sağlar. Ante Sağlık Merkezi, bu hizmetleri kişiye özel yaklaşımlarla sunarak, hastaların yaşam kalitesini önemli ölçüde yükseltmeyi hedeflemektedir.
    </p>
  </AccordionTab>
  <AccordionTab header="Rehabilitasyon hizmetleri kimlere uygulanır?">
    <p>
      Rehabilitasyon hizmetleri, yaralanma veya hastalık sonrası iyileşme sürecine ihtiyaç duyan herkes için Ante Sağlık Merkezi'nde uygulanmaktadır. Bu hizmetler, bireylerin bağımsız yaşam becerilerini yeniden kazanmalarına yardımcı olmaktadır.
    </p>
  </AccordionTab>
  <AccordionTab header="Rehabilitasyon nasıl uygulanır?">
    <p>
      Ante Sağlık Merkezi'nde bireyin ihtiyaçlarına göre özelleştirilmiş bir değerlendirme ile başlar ve ardından fiziksel terapi, egzersiz programları ve psikolojik destek gibi yöntemlerle uygulanır. Terapi süreci, düzenli takip ve ilerleme değerlendirmeleri ile desteklenir.
    </p>
  </AccordionTab>
  <AccordionTab header="Rehabilitasyon hizmetlerinin faydaları nelerdir?">
    <p>
      Rehabilitasyon hizmetleri, bireylerin fiziksel ve zihinsel fonksiyonlarını artırarak bağımsızlıklarını destekler ve yaşam kalitelerini yükseltir. Ante Sağlık Merkezi'nde sunulan bu hizmetler, kişiye özel terapi planları ile ağrı yönetimi, hareket kabiliyeti geliştirme ve psikolojik destek sağlamaktadır.
    </p>
  </AccordionTab>
  <AccordionTab header="Rehabilitasyon hizmetleri kim tarafından uygulanır?">
    <p>
      Rehabilitasyon hizmetleri, uzman fizyoterapistler tarafından uygulanır. Ante Sağlık Merkezi, bu alanda deneyimli bir ekip ile bireylerin ihtiyaçlarına uygun terapi süreçlerini yürütmektedir.
    </p>
  </AccordionTab>
  <AccordionTab header="Rehabilitasyon hizmetleri hangi şikayetler içindir?">
    <p>
      Kas ve iskelet sistemi yaralanmaları, ağrılar, hareket kısıtlılıkları, cerrahi sonrası iyileşme, nörolojik bozukluklar, spor yaralanmaları ve kronik ağrı gibi şikayetler için uygulanmaktadır. Ante Sağlık Merkezi, bireylerin özel ihtiyaçlarına göre kişiselleştirilmiş rehabilitasyon programları sunmaktadır.
    </p>
  </AccordionTab>
  <AccordionTab header="Hangi durumlarda rehabilitasyon hizmeti için fizyoterapistle çalışmalıdır?">
    <p>
      Ante Sağlık Merkezi'nde, kas veya eklem yaralanmaları, ameliyat sonrası iyileşme, kronik ağrılar ve nörolojik hastalıklar gibi durumlarda uzman fizyoterapistlerle çalışarak kişiye özel rehabilitasyon hizmetlerinden faydalanabilirsiniz.
    </p>
  </AccordionTab>
  <AccordionTab header="Ante Sağlık Merkezi’ne nasıl ulaşım sağlayabilirim?">
    <p>
      Ante Sağlık Merkezi’ne ulaşım sağlamak için Yahya Kaptan Mahallesi, Şehit Ali İhsan Çakmak Sokak No:10, Kat:2, 41040 İzmit/Kocaeli adresini kullanabilirsiniz. Merkez, toplu taşıma araçlarıyla da erişilebilir. Detaylı ulaşım bilgileri için iletişim numaramızdan bize ulaşabilirsiniz.
    </p>
  </AccordionTab>
</Accordion>

          </div>
          <div className="team-2 pb-0 pt-0">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <div className="title">
                    <h2
                      className="title ml-auto mr-auto text-center"
                      style={{
                        color: "#123274",
                        fontFamily: "Benguiat Regular",
                      }}
                    >
                      DİĞER UZMANLIK ALANLARIMIZ
                    </h2>
                  </div>
                </Col>
              </Row>
              <Row data-aos="fade-up" data-aos-duration="1000">
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/bel-fitigi")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/belFitigi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/boyun-agrisi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/boyunAgrisi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/diz-agrisi")
                        }
                      >
                        <img
                          alt="..."
                          style={{ border: "1px solid black" }}
                          className="img img-raised rounded img-hover"
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/dizAgrisi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row data-aos="fade-up" data-aos-duration="1000">
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/omurga-sagligi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/omurgaSagligi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/skolyoz")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/skolyoz.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/sporcu-sagligi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          style={{ border: "1px solid black" }}
                          className="img img-raised rounded img-hover"
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/sporcuSagligi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row
                data-aos="fade-up"
                data-aos-duration="1000"
                style={{ justifyContent: "center" }}
              >
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/sirt-agrisi")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/sırtAgrisi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/osteopati")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/osteopati.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/recovery")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/recovery.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                
              </Row>
              <Row
                data-aos="fade-up"
                data-aos-duration="1000"
                style={{ justifyContent: "center" }}
              >
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/manuel-terapi")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/manuelTerapi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/gebelik-platesi")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/gebelikPlates.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/klinik-plates")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/klinikPlates.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
             
            </Container>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default Rehabilitasyonlar;
