import React from 'react'

const Redirect = () => {
    React.useEffect(() => {
        window.location.href = 'https://www.doktortakvimi.com/klinikler/ante-saglik';
      }, []);
    
      return <div>Redirecting...</div>;
}

export default Redirect