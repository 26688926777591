import WhiteNavbar from "components/Navbars/WhiteNavbar";
import React, { useEffect } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async";
import { FaCheck } from "react-icons/fa";
import Footer from "components/Footers/Footer";
import { HashLink } from "react-router-hash-link";
import { useSize } from "components/SizeControl/SizeControl";
import { Accordion, AccordionTab } from "primereact/accordion";
import imageHeader from "../assets/ossaPictures/psikolojikDanismanlik/psikolojikDanismanlikHeader.png";
import PictureHeader from "components/PictureHeader/PictureHeader";
import { useNavigate } from "react-router-dom";

const Psikoloji = () => {
  const { under1200, under992 } = useSize();
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
    const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin

    if (targetLink) {
      // Otomatik tıklama
      targetLink.click();
      const imgElement = document.querySelector(".gt-current-lang img");
      const newImgSrc = "https://cdn.gtranslate.net/flags/32/tr.png"; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
      console.log(imgElement);
      imgElement.src = newImgSrc;
      imgElement.width = "32";
      imgElement.height = "32";
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>PSİKOLOJİK DANIŞMANLIK | OSSA CLINIC</title>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="OSSA CLINIC sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
          data-rh="true"
        />
        <link
          rel="canonical"
          href="https://www.ossaclinic.com"
        />
        <meta property="og:title" content="PSİKOLOJİK DANIŞMANLIK | OSSA CLINIC" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="OSSA CLINIC sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
        />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content="https://www.ossaclinic.com"
        />
      </Helmet>
      <WhiteNavbar navbarSelection={"about-us"} />
      <PictureHeader imageHeader={imageHeader} />
      <div>
        <Container style={{ fontFamily: "Nucleo Outline" }}>
          <div>
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>PSİKOLOJİK DANIŞMANLIK</b>
            </h2>
          </div>
          <Row>
            <Col className="ml-auto" md="6" style={{ alignContent: "center" }}>
              <img
                alt="..."
                className="img rounded"
                src={require("assets/ossaPictures/psikolojikDanismanlik/psikolojikDanismanlik.png")}
              ></img>
            </Col>
            <Col
              className="mr-auto mt-5 mt-md-3"
              md="6"
              data-aos="zoom-in"
              data-aos-duration="2000"
              style={{ alignSelf: "center" }}
            >
              <p>
                Psikolojik danışmanlık, bireylerin ruh sağlığını korumak ve
                geliştirmek için kritik bir role sahiptir.{" "}
                <b>Ossa Fizik Tedavi Merkezi</b>, psikolojik danışmanlık
                hizmetleri ile müşterilerine duygusal ve zihinsel sağlıklarını
                iyileştirmelerine yardımcı olmaktadır. Merkezin uzman
                psikologları, her müşterinin bireysel ihtiyaçlarına uygun özel
                terapi planları hazırlayarak onların ruh sağlığını
                desteklemektedir.
              </p>
              <p>
                <b>Ossa Fizik Tedavi Merkezi</b>'nin psikolojik danışmanlık
                hizmetleri, sadece stres ve kaygı gibi günlük yaşam sorunlarıyla
                başa çıkmak isteyenler için değil, aynı zamanda depresyon,
                anksiyete, travma sonrası stres bozukluğu gibi daha ciddi
                psikolojik problemlerle mücadele edenler için de uygundur. Uzman
                psikologlar, müşterilerin yaşam tarzlarına ve hedeflerine göre
                özel terapi yöntemleri uygulayarak daha sağlıklı ve dengeli bir
                yaşam sürdürmelerini sağlar.
              </p>
              <p>
                <b>Ossa Fizik Tedavi Merkezi</b>'nin psikolojik danışmanlık
                hizmetleri, fizyoterapi sürecinde de büyük önem taşır.
                Fizyoterapi tedavisi alan hastaların ruhsal sağlığı, tedavinin
                etkisini artırmak için çok önemlidir. Bu nedenle, merkezde yer
                alan uzman psikologlar, fizyoterapi tedavisi alan hastaların
                duygusal ve zihinsel ihtiyaçlarına uygun terapi planları
                hazırlayarak tedavi sürecine destek olmaktadır. Sonuç olarak,{" "}
                <b>Ossa Fizik Tedavi Merkezi</b>'nin psikolojik danışmanlık
                hizmetleri, sağlıklı bir yaşam sürdürmek isteyenler için ideal
                bir seçenektir.
              </p>
            </Col>
          </Row>
          <div className="title">
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>SIKÇA SORULAN SORULAR</b>
            </h2>
          </div>
          <br></br>
          <div className="card">
            <Accordion>
              <AccordionTab header="Psikolojik danışmanlık hizmetleri nedir?">
                <p>
                  Psikolojik danışmanlık hizmetleri, bireylerin duygusal ve
                  zihinsel sağlıklarını koruma ve geliştirme amacıyla verilen
                  destek ve rehberlik hizmetleridir. Bu hizmetler, stres, kaygı,
                  depresyon gibi günlük yaşam sorunlarıyla başa çıkma, kişisel
                  gelişim ve yaşam kalitesini artırma gibi konuları kapsar.
                </p>
              </AccordionTab>
              <AccordionTab header="Psikolog kimdir?">
                <p>
                  Psikologlar, bireylerin zihinsel ve duygusal sağlıklarını
                  iyileştirmek için eğitim almış ve bu alanda uzmanlaşmış
                  profesyonellerdir. Psikologlar, terapi ve danışmanlık yoluyla
                  bireylerin sorunlarına çözüm bulmalarına yardımcı olurlar.
                </p>
              </AccordionTab>
              <AccordionTab header="Hangi durumlarda psikoloğa başvurulmalıdır?">
                <p>
                  Stres, kaygı, depresyon, travma sonrası stres bozukluğu,
                  ilişki sorunları, kişisel gelişim ihtiyaçları ve diğer
                  zihinsel sağlık sorunları durumlarında psikoloğa
                  başvurulabilir.
                </p>
              </AccordionTab>
              <AccordionTab header="Online danışmanlık hizmetleri sunuyor musunuz?">
                <p>
                  Evet, Psikolojik Danışmanlık hizmetimiz online olarak da
                  sunulmaktadır. Böylece, müşterilerimiz evlerinin konforunda
                  uzman psikologlarımızdan destek alabilirler.
                </p>
              </AccordionTab>
              <AccordionTab header="Psikolojik danışmanlık hizmetleri kişiye özel mi?">
                <p>
                  Evet, psikolojik danışmanlık hizmetlerimiz bireysel
                  ihtiyaçlara göre özelleştirilmektedir. Her bireyin durumu ve
                  ihtiyaçları farklı olduğundan, terapilerimiz kişiye özel
                  olarak planlanır ve uygulanır.
                </p>
              </AccordionTab>
              <AccordionTab header="Psikologlarınız hangi konularda uzmandır?">
                <p>
                  Ossa Fizik Tedavi Merkezi'ndeki psikologlar, stres yönetimi,
                  kaygı, depresyon, travma, ilişki sorunları, kişisel gelişim ve
                  daha pek çok konuda uzmandır.
                </p>
              </AccordionTab>
              <AccordionTab header="Psikolog randevuları ne kadar sürmektedir?">
                <p>
                  Psikolog randevuları genellikle 45-60 dakika arasında
                  sürmektedir.
                </p>
              </AccordionTab>
              <AccordionTab header="Hangi ödeme yöntemlerini kabul ediyorsunuz?">
                <p>
                  Ossa Fizik Tedavi Merkezi, nakit, kredi kartı veya banka
                  havalesi yoluyla ödeme kabul etmektedir.
                </p>
              </AccordionTab>
              <AccordionTab header="Randevu almak için nasıl iletişime geçebilirim?">
                <p>
                  Randevu almak için Ossa Fizik Tedavi Merkezi'nin web sitesinde
                  yer alan iletişim bilgilerinden veya telefon numaralarından
                  bizimle iletişime geçebilirsiniz.
                </p>
              </AccordionTab>
              <AccordionTab header="Psikolojik danışmanlık hizmetlerinizden nasıl yararlanabilirim?">
                <p>
                  Ossa Fizik Tedavi Merkezi'ndeki psikologlarımızla randevu
                  alarak psikolojik danışmanlık hizmetlerinden
                  yararlanabilirsiniz. Psikologlarımız, size uygun terapi
                  yöntemleriyle destek sağlayabilir ve ruh sağlığınızı
                  iyileştirmeye yardımcı olabilir. Ayrıca, web sitemizde yer
                  alan bilgilendirici yazılarımızdan da faydalanabilirsiniz.
                </p>
              </AccordionTab>
            </Accordion>
          </div>
          <div>
            <div className="title">
              <h2
                className="ml-auto mr-auto text-center"
                style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                <b>VİDEO İÇERİKLERİ</b>
              </h2>
            </div>
            <br></br>
            <br></br>

            <Row className="justify-content-center">
              <Col md="6">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/3p6JjqZYavk?si=wQNf_K5LZv_8uHqh"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </Col>
              <Col md="6">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/fyvNSRJhemU?si=T_jIVI6QRN14wrrv"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </Col>
            </Row>
          </div>
        </Container>

      </div>
      <Footer />
    </>
  );
};

export default Psikoloji;
