import WhiteNavbar from "components/Navbars/WhiteNavbar";
import React, { useEffect } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async";
import { FaCheck } from "react-icons/fa";
import Footer from "components/Footers/Footer";
import { HashLink } from "react-router-hash-link";
import { useSize } from "components/SizeControl/SizeControl";
import { Accordion, AccordionTab } from "primereact/accordion";
import imageHeader from "../assets/ossaPictures/teknolojikCihazlarlaDestek/alanlar/AndulasyonHeader.png";
import PictureHeader from "components/PictureHeader/PictureHeader";
import { useNavigate } from "react-router-dom";

const AndulasyonTerapi = () => {
  const { under1200, under992 } = useSize();
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
    const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin

    if (targetLink) {
      // Otomatik tıklama
      targetLink.click();
      const imgElement = document.querySelector(".gt-current-lang img");
      const newImgSrc = "https://cdn.gtranslate.net/flags/32/tr.png"; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
      console.log(imgElement);
      imgElement.src = newImgSrc;
      imgElement.width = "32";
      imgElement.height = "32";
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>ANDULASYON TERAPİ | OSSA CLINIC</title>
        <meta name="theme-color" content="#000000" />
        <meta name="description" content="OSSA CLINIC" data-rh="true" />
        <link rel="canonical" href="https://www.ossaclinic.com/" />
        <meta property="og:title" content="ANDULASYON TERAPİ | OSSA CLINIC" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="OSSA CLINIC" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="https://www.ossaclinic.com/" />
      </Helmet>
      <WhiteNavbar navbarSelection={"about-us"} />
      <PictureHeader imageHeader={imageHeader} />
      <div>
        <Container style={{ fontFamily: "Nucleo Outline" }}>
          <div>
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>ANDULASYON TERAPİ</b>
            </h2>
          </div>
          <Row>
            <Col className="ml-auto" md="6" style={{ alignContent: "center" }}>
              <img
                alt="..."
                className="img rounded"
                src={require("assets/ossaPictures/teknolojikCihazlarlaDestek/alanlar/andulasyon.png")}
              ></img>
            </Col>
            <Col
              className="mr-auto mt-5 mt-md-3"
              md="6"
              data-aos="zoom-in"
              data-aos-duration="2000"
              style={{ alignSelf: "center" }}
            >
              <h3>
                <strong>
                  Ante Sağlık Merkezi olarak, sağlık alanında sunduğumuz
                  yenilikçi hizmetler arasında andulasyon terapisi ön plana
                  çıkmaktadır.
                </strong>
              </h3>
              <p>
                Bu terapi, vücudun doğal iyileşme mekanizmalarını desteklemek
                amacıyla mekanik titreşimler ve kızılötesi ışınların birleşimini
                kullanır. Andulasyon terapisi, hem fizyolojik hem de psikolojik
                açıdan birçok sağlık problemine etkili bir çözüm sunar.
              </p>

              <br />

              <h3>
                <strong>Uygulama Alanları</strong>
              </h3>
              <p>
                <strong>1. Ağrı Yönetimi:</strong> Kronik ve akut ağrı
                durumlarında, andulasyon terapisi, vücudun kendi ağrı kesicileri
                olan endorfinlerin salınımını artırır. Bel ağrıları,
                fibromiyalji ve eklem rahatsızlıkları gibi durumlarda rahatlama
                sağlar.
              </p>
              <p>
                <strong>2. Dolaşım Problemleri:</strong> Kan akışını artırarak
                hücrelerin beslenmesini iyileştirir. Dolaşım bozuklukları
                yaşayan bireylerde kan damarlarının genişlemesine yardımcı olur.
              </p>
              <p>
                <strong>3. Metabolizma Hızlandırma:</strong> Hücrelerde enerji
                üretimini artırarak metabolizmayı hızlandırır. Kilo verme
                sürecine destek olmak amacıyla tercih edilir.
              </p>
              <p>
                <strong>4. Lenfatik Sistem Desteği:</strong> Lenfatik drenajı
                artırarak toksinlerin vücuttan atılmasına yardımcı olur. Bu,
                bağışıklık sistemini güçlendirir ve genel sağlığı iyileştirir.
              </p>
              <p>
                <strong>5. Stres ve Gerginlik Azaltma:</strong> Terapi sırasında
                kaslar gevşer, bu da stresin azaltılmasına katkıda bulunur.
                Kişi, daha huzurlu bir zihin durumuna ulaşır.
              </p>
              <p>
                <strong>6. Uyku Kalitesini Artırma:</strong> Andulasyon
                terapisi, uyku bozuklukları yaşayan bireyler için de faydalıdır.
                Rahatlama sağlayarak uyku kalitesini artırır.
              </p>

              <br />

              <h3>
                <strong>Seans Süreci</strong>
              </h3>
              <p>
                Andulasyon terapisi genellikle 15 ila 30 dakika sürmektedir.
                Seanslar sırasında, kişiye özel bir andulasyon yatağında
                uzanarak, kızılötesi ışınların ve titreşimlerin etkisini
                hissedeceksiniz. Tedavi, belirli bir program çerçevesinde
                düzenli olarak uygulanır ve genellikle çoklu seanslar
                gerektirmektedir.
              </p>
            </Col>
          </Row>
          <div className="title">
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>SIKÇA SORULAN SORULAR</b>
            </h2>
          </div>
          <br></br>
          <div className="card">
            <Accordion>
              <AccordionTab header="Andulasyon terapisi nedir ve nasıl çalışır?">
                <p>
                  Andulasyon terapisi, Ante Sağlık Merkezi'nde sunulan, mekanik
                  titreşimler ve kızılötesi ışınların birleşimiyle vücudun doğal
                  iyileşme süreçlerini destekleyerek ağrıyı hafifleten ve kan
                  dolaşımını artıran bir tedavi yöntemidir.
                </p>
              </AccordionTab>
              <AccordionTab header="Hangi sağlık problemleri için andulasyon terapisi öneriliyor?">
                <p>
                  Andulasyon terapisi, Ante Sağlık Merkezi'nde kronik ve akut
                  ağrılar, stres, uykusuzluk, kireçlenme, bel fıtığı,
                  fibromiyalji ve dolaşım problemleri gibi birçok sağlık
                  sorununun tedavisinde önerilmektedir.
                </p>
              </AccordionTab>
              <AccordionTab header="Seans süresi ne kadardır?">
                <p>
                  Ante Sağlık Merkezi'nde andulasyon terapisi seansları
                  genellikle 15 ila 30 dakika sürmektedir.
                </p>
              </AccordionTab>
              <AccordionTab header="Tedavi sırasında ağrı veya rahatsızlık hisseder miyim?">
                <p>
                  Andulasyon terapisi sırasında genellikle ağrı veya rahatsızlık
                  hissedilmez; terapi, rahatlatıcı bir deneyim sunarak kas
                  gerginliğini azaltır ve gevşeme sağlar.
                </p>
              </AccordionTab>
              <AccordionTab header="Andulasyon terapisi kaç seans gerektiriyor?">
                <p>
                  Ante Sağlık Merkezi'nde andulasyon terapisi, bireyin
                  ihtiyaçlarına ve hedeflerine bağlı olarak genellikle
                  değişiklik göstermektedir. Detaylı bilgi için uzmanlarımızla
                  görüşebilirsiniz.
                </p>
              </AccordionTab>
              <AccordionTab header="Andulasyon terapisinin yan etkileri var mı?">
                <p>
                  Andulasyon terapisinin genellikle yan etkisi yoktur, ancak
                  bazı bireylerde hafif bir rahatsızlık hissi veya kas
                  gerginliği görülebilir. Bu tür durumlar nadirdir ve çoğunlukla
                  seans sonrası geçmektedir. Herhangi bir endişeniz varsa,
                  uzmanlarımızla görüşebilirsiniz.
                </p>
              </AccordionTab>
              <AccordionTab header="Kilo verme sürecinde andulasyon terapisi ne kadar etkilidir?">
                <p>
                  Andulasyon terapisi, kilo verme sürecini destekleyerek
                  metabolizmayı hızlandırır, yağ yakımını artırır ve ödem
                  atımına yardımcı olur. Ancak en iyi sonuçlar, sağlıklı
                  beslenme ve düzenli egzersizle birlikte elde edilir. Bireysel
                  sonuçlar farklılık gösterebilir; bu nedenle, kişisel
                  hedeflerinizi desteklemek için uzmanlarımızla bir plan
                  oluşturmanız önerilir.
                </p>
              </AccordionTab>
              <AccordionTab header="Hangi sıklıkta seans almayı öneriyorsunuz?">
                <p>
                  Andulasyon terapisi için önerilen seans sıklığı, genellikle
                  haftada 2-3 kezdir. Ancak bu sıklık, bireyin ihtiyaçlarına ve
                  hedeflerine bağlı olarak değişiklik gösterebilir. En uygun
                  plan için uzmanlarımızla görüşmeniz faydalı olacaktır.
                </p>
              </AccordionTab>
              <AccordionTab header="Andulasyon terapisi kimler için uygundur?">
                <p>
                  Andulasyon terapisi, sağlıklı bireyler ile kronik ağrılar,
                  stres ve kilo verme hedefleri olanlar için uygundur, ancak
                  hamileler ve bazı sağlık sorunları olanların öncelikle
                  doktorlarına danışmaları önerilir.
                </p>
              </AccordionTab>
              <AccordionTab header="Andulasyon cihazı nasıl çalışıyor?">
                <p>
                  Andulasyon cihazı, vücuda titreşim ve kızılötesi ısı
                  uygulayarak kan dolaşımını artırır, hücrelerde enerji
                  üretimini destekler ve ağrı kesici endorfin salgısını teşvik
                  eder.
                </p>
              </AccordionTab>
              <AccordionTab header="Nasıl randevu alabilirim?">
                <p>
                  Ante Sağlık Merkezi'nden randevu almak için 0530 898 4114 veya
                  0262 503 8575 numaralarını arayabilir ya da Ante Sağlık
                  üzerinden ücretsiz ön değerlendirme randevusu
                  oluşturabilirsiniz.
                </p>
              </AccordionTab>
            </Accordion>
          </div>
          <div className="team-2 pb-0 pt-0">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <div className="title">
                    <h2
                      className="title ml-auto mr-auto text-center"
                      style={{
                        color: "#123274",
                        fontFamily: "Benguiat Regular",
                      }}
                    >
                      DİĞER UZMANLIK ALANLARIMIZ
                    </h2>
                  </div>
                </Col>
              </Row>
              <Row data-aos="fade-up" data-aos-duration="1000">
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/teknolojik-cihazlar-ile-destek/back-up-teknolojisi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/teknolojikCihazlarlaDestek/alanlar/backup.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/teknolojik-cihazlar-ile-destek/robotik-taramali-lazer"
                          )
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/teknolojikCihazlarlaDestek/alanlar/robotik.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default AndulasyonTerapi;
