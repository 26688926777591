import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./Navbars.module.scss";
import { Menubar } from "primereact/menubar";
import logo from "../../assets/ossaPictures/ossaLogo.png";
import onlineRandevu from "../../assets/ossaPictures/BizeUlasin.png";
import { Button, Container, Navbar } from "reactstrap";
import classNames from "classnames";
import { useSize } from "components/SizeControl/SizeControl";
import SearchModal from "components/SearchModal/SearchModal";
import SearchIcon from "../../assets/ossaPictures/searchObjects.png"
import { Image } from "primereact/image";

const WhiteNavbar = () => {

const {above960} = useSize()
  let scrollSize = 640;
  const [navbarColor, setNavbarColor] = useState(
    (document.documentElement.scrollTop > scrollSize ||
      document.body.scrollTop) > scrollSize
      ? ""
      : " navbar-transparent"
  );
  const [buyButtonColor, setBuyButtonColor] = useState(
    (document.documentElement.scrollTop > scrollSize ||
      document.body.scrollTop) > scrollSize
      ? "info"
      : "neutral"
  );
  const [logoStyle, setLogoStyle] = useState(true);
  const [hovered, setHovered] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [searchModal, setSearchModal] = useState(false);

  const toggleSearch = () => setSearchModal(!searchModal);

  useEffect(() => {
    const updateNavbarColor2 = () => {
      if (
        document.documentElement.scrollTop > scrollSize ||
        document.body.scrollTop > scrollSize
      ) {
        setNavbarColor("");
        setBuyButtonColor("info");
        setLogoStyle(false);
      } else if (
        document.documentElement.scrollTop < scrollSize ||
        document.body.scrollTop < scrollSize
      ) {
        setNavbarColor(" navbar-transparent");
        setBuyButtonColor("neutral");
        setLogoStyle(true);
      }
    };
    window.addEventListener("scroll", updateNavbarColor2);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor2);
    };
  }, []);

  const toggleDropdowns = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };


  const items = [
    {
      label: "ANA SAYFA",
      icon: "pi pi-fw pi-home",
      command: () => {
        window.location.href = "/";
      },
    },
    {
      label: "HAKKIMIZDA",
      icon: "pi pi-fw pi-info",
      command: () => {
        window.location.href = "/hakkimizda";
      },
    },
    {
      label: "HİZMETLERİMİZ",
      icon: "pi pi-fw pi-list",
      command: toggleDropdowns,
      items: [
        {
          label: "FİZİK TEDAVİ VE REHABİLİTASYON",
          items: [
            {
              separator: true,
            },
            {
              label: "Fizik Tedavi ve Rehabilitasyon",
              command: () => {
                window.location.href = "/fizik-tedavi-ve-rehabilitasyon";
              },
            },
            {
              separator: true,
            },
            {
              label: "Sırt Ağrısı",
              command: () => {
                window.location.href = "/fizik-tedavi-ve-rehabilitasyon/sirt-agrisi";
              },
            },
            {
              label: "Boyun Ağrısı",
              command: () => {
                window.location.href = "/fizik-tedavi-ve-rehabilitasyon/boyun-agrisi";
              },
            },
            {
              label: "Bel Fıtığı",
              command: () => {
                window.location.href = "/fizik-tedavi-ve-rehabilitasyon/bel-fitigi";
              },
            },
            {
              label: "Omurga Sağlığı",
              command: () => {
                window.location.href = "/fizik-tedavi-ve-rehabilitasyon/omurga-sagligi";
              },
            },
            {
              label: "Diz Ağrısı",
              command: () => {
                window.location.href = "/fizik-tedavi-ve-rehabilitasyon/diz-agrisi";
              },
            },
            {
              label: "Skolyoz",
              command: () => {
                window.location.href = "/fizik-tedavi-ve-rehabilitasyon/skolyoz";
              },
            },
            {
              label: "Sporcu Sağlığı",
              command: () => {
                window.location.href = "/fizik-tedavi-ve-rehabilitasyon/sporcu-sagligi";
              },
            },
            {
              label: "Osteopati",
              command: () => {
                window.location.href = "/fizik-tedavi-ve-rehabilitasyon/osteopati";
              },
            },
            {
              label: "Manuel Terapi",
              command: () => {
                window.location.href = "/fizik-tedavi-ve-rehabilitasyon/manuel-terapi";
              },
            },
            {
              label: "Recovery",
              command: () => {
                window.location.href = "/fizik-tedavi-ve-rehabilitasyon/recovery";
              },
            },
            {
              label: "Rehabilitasyonlar",
              command: () => {
                window.location.href = "/fizik-tedavi-ve-rehabilitasyon/rehabilitasyonlar";
              },
            },
            {
              label: "Klinik Plates",
              command: () => {
                window.location.href = "/fizik-tedavi-ve-rehabilitasyon/klinik-plates";
              },
            },
            {
              label: "Gebelik Platesi",
              command: () => {
                window.location.href = "/fizik-tedavi-ve-rehabilitasyon/gebelik-platesi";
              },
            },
          ],
        },
        {
          separator: true,
        },
        {
          label: "BESLENME VE DİYETETİK",
          command: () => {
            window.location.href = "/beslenme-ve-diyetetik";
          },
        },
        {
          separator: true,
        },
        {
          label: "PSİKOLOJİK DANIŞMANLIK",
          command: () => {
            window.location.href = "/psikolojik-danismanlik";
          },
        },
        {
          separator: true,
        },
        {
          label: "TEKNOLOJİK CİHAZLAR İLE DESTEK",
          items: [
            {
              separator: true,
            },
            {
              label: "Teknolojik Cihazlar İle Destek",
              command: () => {
                window.location.href = "/teknolojik-cihazlar-ile-destek";
              },
            },
            {
              separator: true,
            },
            {
              label: "Back-Up Teknolojisi",
              command: () => {
                window.location.href = "/teknolojik-cihazlar-ile-destek/back-up-teknolojisi";
              },
            },
            {
              label: "Robotik Taramalı Lazer",
              command: () => {
                window.location.href = "/teknolojik-cihazlar-ile-destek/robotik-taramali-lazer";
              },
            },
            {
              label: "Andulasyon Terapi",
              command: () => {
                window.location.href = "/teknolojik-cihazlar-ile-destek/andulasyon-terapi";
              },
            }
            
          ],
        },
      ],
    },
    {
      label: "MULTİMEDYA",
      icon: "pi pi-fw pi-video",
      items: [
        {
          label: "BLOG PAYLAŞIMLARI",
          command: () => {
            window.location.href = "/blog";
          },
        },
        {
          separator: true,
        },
        {
          label: "RESİMLER",
          command: () => {
            window.location.href = "/resimler";
          },
        },
        {
          separator: true,
        },
        {
          label: "VİDEOLAR",
          command: () => {
            window.location.href = "/videolar";
          },
        },
      ],
    },
    {
      label: "İLETİŞİM",
      icon: "pi pi-fw pi-envelope",
      command: () => {
        window.location.href = "/iletisim";
      },
    },
  ];
  const start = (
    <Link to="/">
      <img
        className={
          logoStyle
            ? `${styles.logoBackground} ${styles.logo}`
            : `${styles.logo}`
        }
        src={logo}
        alt="logo"
        width={200}
        height={60}
        title="OSSA CLINIC"
        loading="eager"
        rel="preload"
      />
    </Link>
  );

  const end = (
    <>
    <Link to="tel:+905308984114" className={styles.logoContainer}>
  <img
    src={onlineRandevu}
    alt="onlineRandevu"
    width={200}
    height={60}
    className={styles.logo}
  />
</Link>

    <Button className="btn-icon btn-round mr-1 navbar-blur" onClick={toggleSearch}>
    <Image src={SearchIcon} alt="searchIcon" />
  </Button>
    </>
  );

  const menubarClasses = classNames({
    scrolled: true,
    hovered: hovered,
    above960 : above960
  });

  return (
    <>
      <Navbar
        className={"fixed-top" + navbarColor}
        color="white"
        expand="lg"
        style={{
          paddingTop: "0px",
          paddingBottom: "5px",
          fontSize: "1rem",
          color: "white",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <Menubar
          model={items}
          start={start}
          end={end}
          className={menubarClasses}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        />
      </Navbar>
      <SearchModal toggleSearch={toggleSearch} searchModal={searchModal} setSearchModal={setSearchModal} />
    </>
  );
};

export default WhiteNavbar;
