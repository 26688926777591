import WhiteNavbar from "components/Navbars/WhiteNavbar";
import React, { useEffect } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async";
import { FaCheck } from "react-icons/fa";
import Footer from "components/Footers/Footer";
import { HashLink } from "react-router-hash-link";
import { useSize } from "components/SizeControl/SizeControl";
import { Accordion, AccordionTab } from "primereact/accordion";
import imageHeader from "../assets/ossaPictures/fizyoterapi/alanlar/OmurgaSagligiHeader.png";
import PictureHeader from "components/PictureHeader/PictureHeader";
import { useNavigate } from "react-router-dom";

const OmurgaSagligi = () => {
  const { under1200, under992 } = useSize();
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
    const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin

    if (targetLink) {
      // Otomatik tıklama
      targetLink.click();
      const imgElement = document.querySelector(".gt-current-lang img");
      const newImgSrc = "https://cdn.gtranslate.net/flags/32/tr.png"; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
      console.log(imgElement);
      imgElement.src = newImgSrc;
      imgElement.width = "32";
      imgElement.height = "32";
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>OMURGA SAĞLIĞI | OSSA CLINIC</title>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="OSSA CLINIC sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
          data-rh="true"
        />
        <link
          rel="canonical"
          href="https://www.ossaclinic.com"
        />
        <meta property="og:title" content="OMURGA SAĞLIĞI | OSSA CLINIC" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="OSSA CLINIC sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
        />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content="https://www.ossaclinic.com"
        />
      </Helmet>
      <WhiteNavbar navbarSelection={"about-us"} />
      <PictureHeader imageHeader={imageHeader} />
      <div>
        <Container style={{ fontFamily: "Nucleo Outline" }}>
          <div>
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>OMURGA SAĞLIĞI</b>
            </h2>
          </div>
          <Row>
            <Col className="ml-auto" md="6" style={{ alignContent: "center" }}>
              <img
                alt="..."
                className="img rounded"
                src={require("assets/ossaPictures/fizyoterapi/alanlar/omurgaSagligi.png")}
              ></img>
            </Col>
            <Col
              className="mr-auto mt-5 mt-md-3"
              md="6"
              data-aos="zoom-in"
              data-aos-duration="2000"
              style={{ alignSelf: "center" }}
            >
              <p>
                <b>Omurga sağlığı</b>, insan vücudunun şeklini korumak ve
                hareket etmek için hayati öneme sahip bir konudur. Omurga
                problemleri, yaşam kalitesini düşürür ve günlük hayatı olumsuz
                etkiler. Ossa Fizik Tedavi Merkezi, uzman fizyoterapistleri ve
                son teknolojik cihazları ile müşterilerinin omurga sağlıklarına
                odaklanarak en iyi tedavi yöntemlerini sunmaktadır.
              </p>

              <p>
                Ossa Fizik Tedavi Merkezi'nde omurga sağlığına yönelik tedaviler
                arasında <b>manuel terapi</b>, <b>egzersizler</b>,{" "}
                <b>akupunktur</b>, <b>masaj</b> ve <b>lazer terapi</b> gibi
                alternatif tedavi seçenekleri yer almaktadır. Uzman
                fizyoterapistler, hastaların bireysel ihtiyaçlarına göre özel
                tedavi planları hazırlayarak omurga problemlerini azaltmak ve
                hareket kabiliyetini artırmak için çalışır.
              </p>

              <p>
                Ossa Fizik Tedavi Merkezi'nin omurga sağlığı tedavisi, sadece
                semptomları hafifletmekle kalmaz, aynı zamanda sorunun kökenine
                inerek nedenlerini de ortadan kaldırmaya çalışır. Bu sayede,
                hastaların omurga problemleri uzun vadede tekrarlamaz. Ayrıca,
                merkezin uzman diyetisyenleri de müşterilere sağlıklı beslenme
                alışkanlıkları kazandırmak ve omurga sağlığı problemlerini
                önlemek için özel beslenme programları hazırlamaktadır. Sonuç
                olarak, Ossa Fizik Tedavi Merkezi omurga sağlığı tedavisi
                konusunda uzman kadrosu ve son teknolojik cihazları ile
                müşterilerine en iyi hizmeti sunmaktadır.
              </p>
            </Col>
          </Row>
          <div className="title">
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>SIKÇA SORULAN SORULAR</b>
            </h2>
          </div>
          <br></br>
          <div className="card">
          <Accordion activeIndex={0}>
                <AccordionTab header="Omurga sağlığımız neden önemlidir?">
                    <p>
                        Omurga sağlığı, vücudumuzun hareket kabiliyetini, dengeyi ve koordinasyonu sağlamakta kritik bir rol oynar. Ayrıca omurganın içinden geçen sinirlerin korunması da hayati önem taşır.
                    </p>
                </AccordionTab>
                <AccordionTab header="Omurga ağrısı neden oluşur?">
                    <p>
                        Omurga ağrısının birçok sebebi olabilir. Bunlar arasında yaşlanma, kötü duruş, yetersiz fiziksel aktivite, yaralanmalar ve bazı hastalıklar yer alabilir.
                    </p>
                </AccordionTab>
                <AccordionTab header="İyi bir duruş nasıl sağlanır?">
                    <p>
                        İyi bir duruş için dik oturmak, omuzların geriye doğru çekilmesi, başın hafifçe öne eğilmemesi ve ayakların düz bir şekilde yere basması gereklidir.
                    </p>
                </AccordionTab>
                <AccordionTab header="Omurga sağlığı için hangi egzersizler yapılabilir?">
                    <p>
                        Omurga sağlığı için sırt, karın ve kalça kaslarını güçlendirecek egzersizler yapılabilir. Yüzme, yoga ve pilates gibi aktiviteler de omurga sağlığına katkıda bulunabilir.
                    </p>
                </AccordionTab>
                <AccordionTab header="Omurga ağrısını önlemek için ne yapılabilir?">
                    <p>
                        Omurga ağrısını önlemek için düzenli olarak egzersiz yapmak, doğru duruşu korumak, yeterli uyku almak, sigara ve alkol tüketimini azaltmak önerilir.
                    </p>
                </AccordionTab>
                <AccordionTab header="Omurga ağrısı nasıl tedavi edilir?">
                    <p>
                        Omurga ağrısı tedavisi, ağrının sebebini belirlemeye bağlı olarak değişebilir. Fizik tedavi, ilaçlar, cerrahi müdahaleler gibi yöntemler kullanılabilir.
                    </p>
                </AccordionTab>
                <AccordionTab header="Ayakta çok zaman geçirmek omurga sağlığını nasıl etkiler?">
                    <p>
                        Ayakta çok zaman geçirmek, omurganın yükünü artırarak omurga ağrısına neden olabilir. Doğru ayakkabı seçimi ve düzenli ara vermek bu durumun önlenmesinde yardımcı olabilir.
                    </p>
                </AccordionTab>
                <AccordionTab header="Yetersiz uyku omurga sağlığını nasıl etkiler?">
                    <p>
                        Yetersiz uyku, kasların zayıflamasına ve ağrıya neden olabilir. Düzenli ve yeterli uyku, omurga sağlığı için önemlidir.
                    </p>
                </AccordionTab>
                <AccordionTab header="Hangi durumlarda omurga cerrahisi gereklidir?">
                    <p>
                        Omurga cerrahisi, omurga yaralanmaları, kırıkları, kaymaları veya bel fıtığı gibi durumlarda gerekebilir. Cerrahi müdahale, ağrının şiddetini azaltmak ve fonksiyonel iyileşmeyi hızlandırmak için kullanılabilir.
                    </p>
                </AccordionTab>
                <AccordionTab header="Ossa Fizik Tedavi merkezi hangi hizmetleri sunmaktadır?">
                    <p>
                        Ossa Fizik Tedavi merkezi, omurga sağlığı, ortopedi, kas iskelet sistemi rehabilitasyonu ve ağrı yönetimi konularında uzmanlaşmış bir merkezdir. Fizyoterapi, manuel terapi, osteopati gibi yöntemlerle hastalara profesyonel destek sunmaktadır.
                    </p>
                </AccordionTab>
            </Accordion>
          </div>
          <div className="team-2 pb-0 pt-0">
          <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <div className="title">
                    <h2
                      className="title ml-auto mr-auto text-center"
                      style={{
                        color: "#123274",
                        fontFamily: "Benguiat Regular",
                      }}
                    >
                      DİĞER UZMANLIK ALANLARIMIZ
                    </h2>
                  </div>
                </Col>
              </Row>
              <Row data-aos="fade-up" data-aos-duration="1000">
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/bel-fitigi")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/belFitigi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/boyun-agrisi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/boyunAgrisi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/diz-agrisi")
                        }
                      >
                        <img
                          alt="..."
                          style={{ border: "1px solid black" }}
                          className="img img-raised rounded img-hover"
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/dizAgrisi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row data-aos="fade-up" data-aos-duration="1000">
              <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/gebelik-platesi")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/gebelikPlates.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/skolyoz")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/skolyoz.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/sporcu-sagligi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          style={{ border: "1px solid black" }}
                          className="img img-raised rounded img-hover"
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/sporcuSagligi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row
                data-aos="fade-up"
                data-aos-duration="1000"
                style={{ justifyContent: "center" }}
              >
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/sirt-agrisi")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/sırtAgrisi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/osteopati")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/osteopati.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/recovery")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/recovery.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                
              </Row>
              <Row
                data-aos="fade-up"
                data-aos-duration="1000"
                style={{ justifyContent: "center" }}
              >
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/manuel-terapi")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/manuelTerapi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/rehabilitasyonlar")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/rehabilitasyon.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/klinik-plates")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/klinikPlates.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
          
            </Container>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default OmurgaSagligi;
