import WhiteNavbar from "components/Navbars/WhiteNavbar";
import React, { useEffect } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async";
import { FaCheck } from "react-icons/fa";
import Footer from "components/Footers/Footer";
import { HashLink } from "react-router-hash-link";
import { useSize } from "components/SizeControl/SizeControl";
import { Accordion, AccordionTab } from "primereact/accordion";
import imageHeader from "../assets/ossaPictures/teknolojikCihazlarlaDestek/alanlar/RobotikTaramaliLazerHeader.png";
import PictureHeader from "components/PictureHeader/PictureHeader";
import { useNavigate } from "react-router-dom";

const RobotikTaramaliLazer = () => {
  const { under1200, under992 } = useSize();
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
    const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin

    if (targetLink) {
      // Otomatik tıklama
      targetLink.click();
      const imgElement = document.querySelector(".gt-current-lang img");
      const newImgSrc = "https://cdn.gtranslate.net/flags/32/tr.png"; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
      console.log(imgElement);
      imgElement.src = newImgSrc;
      imgElement.width = "32";
      imgElement.height = "32";
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>ROBOTİK TARAMALI LAZER | OSSA CLINIC</title>
        <meta name="theme-color" content="#000000" />
        <meta name="description" content="OSSA CLINIC" data-rh="true" />
        <link rel="canonical" href="https://www.ossaclinic.com/" />
        <meta
          property="og:title"
          content="ROBOTİK TARAMALI LAZER | OSSA CLINIC"
        />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="OSSA CLINIC" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="https://www.ossaclinic.com/" />
      </Helmet>
      <WhiteNavbar navbarSelection={"about-us"} />
      <PictureHeader imageHeader={imageHeader} />
      <div>
        <Container style={{ fontFamily: "Nucleo Outline" }}>
          <div>
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>ROBOTİK TARAMALI LAZER</b>
            </h2>
          </div>
          <Row>
            <Col className="ml-auto" md="6" style={{ alignContent: "center" }}>
              <img
                alt="..."
                className="img rounded"
                src={require("assets/ossaPictures/teknolojikCihazlarlaDestek/alanlar/robotik.png")}
              ></img>
            </Col>
            <Col
              className="mr-auto mt-5 mt-md-3"
              md="6"
              data-aos="zoom-in"
              data-aos-duration="2000"
              style={{ alignSelf: "center" }}
            >
              <h3>
                <strong>
                  Ante Sağlık Merkezi, modern tıbbi teknolojinin en son
                  örneklerinden biri olan robotik taramalı lazer cihazını
                  kullanarak lazer terapisinde devrim niteliğinde bir gelişme
                  sunmaktadır.
                </strong>
              </h3>
              <p>
                Bu cihaz, monokromatik, polarize ve tutarlı lazer ışınları
                kullanarak yüksek hassasiyetle doku tedavisi
                gerçekleştirmektedir. Yüksek yoğunluklu lazer ışığı, derin
                dokulara ulaşma yeteneği sayesinde kas yaralanmalarından eklem
                problemlerine kadar geniş bir yelpazede etkili tedavi olanağı
                sunar.
              </p>

              <br />

              <h3>
                <strong>Robotik Tarama Sistemi</strong>
              </h3>
              <p>
                Robotik tarama sistemi, otomatik olarak belirlenen alanları
                hedef alarak tedavi sürecini hızlandırır ve etkinliği artırır.
                Gelişmiş biyostimülasyon özellikleri ile hücresel düzeyde
                iyileşmeyi destekler, ağrıyı azaltır ve inflamasyonu kontrol
                altına alır. <strong>Ante Sağlık Merkezi</strong>'ndeki uzman
                ekip, kullanıcı dostu arayüzü ve kolay uygulama protokolleri ile
                hem uzmanlar hem de hastalar için büyük kolaylık sağlar.
              </p>

              <br />

              <h3>
                <strong>Geniş Uygulama Alanı</strong>
              </h3>
              <p>Geniş uygulama alanı, robotik taramalı lazer cihazını rehabilitasyon, spor yaralanmaları ve ağrı yönetimi gibi birçok alanda vazgeçilmez bir araç haline getirir. Böylece, hastalarımız için hem hızlı hem de etkili bir iyileşme süreci sağlanmış olmaktadır.</p>
            </Col>
          </Row>
          <div className="title">
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>SIKÇA SORULAN SORULAR</b>
            </h2>
          </div>
          <br></br>
          <div className="card">
            <Accordion>
              <AccordionTab header="Robotik taramalı lazer nedir?">
                <p>
                  Robotik taramalı lazer, Ante Sağlık Merkezi'nde kullanılan,
                  hassas bir şekilde dokuları hedefleyen ve iyileşmeyi
                  hızlandıran gelişmiş bir lazer terapi teknolojisidir.
                </p>
              </AccordionTab>
              <AccordionTab header="Robotik taramalı lazer hangi sağlık problemlerinde kullanılır?">
                <p>
                  Robotik taramalı lazer, Ante Sağlık Merkezi'nde kas
                  yaralanmaları, eklem problemleri, tendinitler ve omurga
                  rahatsızlıkları gibi çeşitli sağlık sorunlarının tedavisinde
                  kullanılır.
                </p>
              </AccordionTab>
              <AccordionTab header="Robotik taramalı lazer süreci ne kadar sürer?">
                <p>
                  Robotik taramalı lazer süreci genellikle 20 ila 30 dakika
                  arasında sürer, ancak tedavi planına göre değişiklik
                  gösterebilir.
                </p>
              </AccordionTab>
              <AccordionTab header="Robotik taramalı lazer seansları arasında ne kadar süre olmalıdır?">
                <p>
                  Robotik taramalı lazer seansları arasında genellikle 1 ila 2
                  gün süre bırakılması önerilir, ancak bu süre bireysel
                  ihtiyaçlara göre değişebilir.
                </p>
              </AccordionTab>
              <AccordionTab header="Yan etkileri var mı?">
                <p>
                  Robotik taramalı lazerin genellikle yan etkileri minimaldir;
                  ancak, bazı hastalarda geçici cilt irritasyonu veya hafif ağrı
                  görülebilir. Her durumda, seans öncesi uzmanla detaylı bir
                  değerlendirme yapılması önemlidir.
                </p>
              </AccordionTab>
              <AccordionTab header="Tedaviye başlamadan önce bir değerlendirme gerekli mi?">
                <p>
                  Evet, tedaviye başlamadan önce uzman bir değerlendirme
                  gereklidir; bu, kişiye özel tedavi planı oluşturmak ve en
                  uygun yaklaşımı belirlemek için önemlidir.
                </p>
              </AccordionTab>
              <AccordionTab header="Daha önce geçirdiğim bir hastalık tedaviye engel mi?">
                <p>
                  Geçirdiğiniz bir hastalık, tedaviye engel olmayabilir; ancak,
                  bu durum bireysel sağlık geçmişinize bağlıdır. En iyi
                  yaklaşımı belirlemek için uzman bir değerlendirme önemlidir.
                </p>
              </AccordionTab>
              <AccordionTab header="Bu teknoloji güvenli midir?">
                <p>
                  Evet, robotik taramalı lazer teknolojisi genellikle güvenli
                  kabul edilir; ancak, tedavi öncesinde uzman bir değerlendirme
                  yapılması önerilir.
                </p>
              </AccordionTab>
              <AccordionTab header="Bu cihaz diğer ağrı yönetimi yöntemleriyle birlikte kullanılabilir mi?">
                <p>
                  Evet, robotik taramalı lazer cihazı, diğer ağrı yönetimi
                  yöntemleriyle birlikte güvenle kullanılabilir ve tedavi
                  sürecini destekleyebilir.
                </p>
              </AccordionTab>
              <AccordionTab header="Robotik taramalı lazer kim tarafından uygulanmaktadır?">
                <p>
                  Robotik taramalı lazer, genellikle uzman fizyoterapistler veya
                  rehabilitasyon hekimleri tarafından uygulanmaktadır.
                </p>
              </AccordionTab>
            </Accordion>
          </div>
          <div className="team-2 pb-0 pt-0">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <div className="title">
                    <h2
                      className="title ml-auto mr-auto text-center"
                      style={{
                        color: "#123274",
                        fontFamily: "Benguiat Regular",
                      }}
                    >
                      DİĞER UZMANLIK ALANLARIMIZ
                    </h2>
                  </div>
                </Col>
              </Row>
              <Row data-aos="fade-up" data-aos-duration="1000">
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/teknolojik-cihazlar-ile-destek/back-up-teknolojisi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/teknolojikCihazlarlaDestek/alanlar/backup.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/teknolojik-cihazlar-ile-destek/andulasyon-terapi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/teknolojikCihazlarlaDestek/alanlar/andulasyon.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default RobotikTaramaliLazer;
