import WhiteNavbar from "components/Navbars/WhiteNavbar";
import React, { useEffect } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async";
import { FaCheck } from "react-icons/fa";
import Footer from "components/Footers/Footer";
import { HashLink } from "react-router-hash-link";
import { useSize } from "components/SizeControl/SizeControl";
import { Accordion, AccordionTab } from "primereact/accordion";
import imageHeader from "../assets/ossaPictures/teknolojikCihazlarlaDestek/TeknolojikCihazlarlaDestekHeader.png";
import PictureHeader from "components/PictureHeader/PictureHeader";
import { useNavigate } from "react-router-dom";

const TeknolojikCihazlar = () => {
  const { under1200, under992 } = useSize();
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
    const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin

    if (targetLink) {
      // Otomatik tıklama
      targetLink.click();
      const imgElement = document.querySelector(".gt-current-lang img");
      const newImgSrc = "https://cdn.gtranslate.net/flags/32/tr.png"; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
      console.log(imgElement);
      imgElement.src = newImgSrc;
      imgElement.width = "32";
      imgElement.height = "32";
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>TEKNOLOJİK CİHAZLAR İLE DESTEK | OSSA CLINIC</title>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="OSSA CLINIC sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
          data-rh="true"
        />
        <link rel="canonical" href="https://www.ossaclinic.com" />
        <meta
          property="og:title"
          content="TEKNOLOJİK CİHAZLAR İLE DESTEK | OSSA CLINIC"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="OSSA CLINIC sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
        />
        <meta property="og:image" content="" />
        <meta property="og:url" content="https://www.ossaclinic.com" />
      </Helmet>
      <WhiteNavbar navbarSelection={"about-us"} />
      <PictureHeader imageHeader={imageHeader} />
      <div>
        <Container style={{ fontFamily: "Nucleo Outline" }}>
          <div>
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>TEKNOLOJİK CİHAZLAR İLE DESTEK</b>
            </h2>
          </div>
          <Row>
            <Col className="ml-auto" md="6" style={{ alignContent: "center" }}>
              <img
                alt="..."
                className="img rounded"
                src={require("assets/ossaPictures/teknolojikCihazlarlaDestek/teknolojikCihazlarlaDestek.png")}
              ></img>
            </Col>
            <Col
              className="mr-auto mt-5 mt-md-3"
              md="6"
              data-aos="zoom-in"
              data-aos-duration="2000"
              style={{ alignSelf: "center" }}
            >
              <h3>
                <strong>
                  Ante Sağlık Merkezi, modern sağlık hizmetlerinin öncüsü
                  olarak, danışanlarına en iyi rehabilitasyon deneyimini sunmayı
                  hedeflemektedir.
                </strong>
              </h3>
              <p>
                Günümüzde gelişen teknoloji, fiziksel terapi ve rehabilitasyon
                alanında yenilikçi çözümler sunan robotik cihazlar, sanal
                gerçeklik uygulamaları ve diğer ileri düzey teknolojilerle
                birleşerek tedavi süreçlerini daha etkili hale getirmektedir.
                Merkezimizde kullanılan <strong>Backup Teknolojisi</strong>,{" "}
                <strong>Robotik Taramalı Lazer</strong> ve{" "}
                <strong>Andulasyon Terapi Cihazı</strong> gibi yenilikçi
                araçlar, danışanların hareket kabiliyetlerini yeniden
                kazanmasına, güçlenmesine ve günlük yaşam aktivitelerini daha
                bağımsız bir şekilde gerçekleştirmesine yardımcı olmaktadır.
              </p>

              <br />

              <h3>
                <strong>
                  Deneyimli Fizyoterapistler ve Bireysel Tedavi Programları
                </strong>
              </h3>
              <p>
                Deneyimli fizyoterapistler eşliğinde, her danışanın bireysel
                ihtiyaçlarına uygun tedavi programları oluşturarak, bu
                teknolojilerin potansiyelinden en iyi şekilde faydalanılmasını
                sağlamaktadır. Teknolojik cihazların sağladığı destekle,
                rehabilitasyon süreçleri daha hızlı ve etkili bir şekilde
                ilerlemekte, danışanlarımızın sağlıklı bir yaşam sürmeleri için
                gereken temeller atılmaktadır.
              </p>
            </Col>
          </Row>
          <div className="title">
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>SIKÇA SORULAN SORULAR</b>
            </h2>
          </div>
          <br></br>
          <div className="card">
            <Accordion>
              <AccordionTab header="Teknolojik cihazlar kimler için uygulanır?">
                <p>
                  Ante Sağlık Merkezi'nde teknolojik cihazlar, nörolojik
                  hastalıklardan ortopedik rahatsızlıklara kadar geniş bir
                  yelpazedeki bireyler için uygulanarak, tedavi süreçlerini daha
                  etkili hale getirmektedir.
                </p>
              </AccordionTab>
              <AccordionTab header="Cihazlar kim tarafından uygulanır?">
                <p>
                  Ante Sağlık Merkezi'nde teknolojik cihazlar, uzman
                  fizyoterapistler tarafından uygulanarak, danışanların bireysel
                  ihtiyaçlarına uygun tedavi planları ile desteklenmektedir.
                </p>
              </AccordionTab>
              <AccordionTab header="Hangi durumlarda cihazlar kullanılır?">
                <p>
                  Teknolojik cihazlar, kas-iskelet sistemi yaralanmaları,
                  nörolojik rahatsızlıklar, post-operatif rehabilitasyon
                  süreçleri, denge ve koordinasyon problemleri gibi durumlarda
                  kullanılır. Ayrıca, fiziksel terapi gereksinimi duyan sağlıklı
                  bireylerde de önleyici tedavi amaçlı uygulanabilir.
                </p>
              </AccordionTab>
              <AccordionTab header="İleri teknolojik cihazlarının avantajları nelerdir?">
                <p>
                  İleri teknolojik cihazlar, Ante Sağlık Merkezi'nde bireysel
                  ihtiyaçlara özel tedavi programları sunarak hastaların
                  motivasyonunu artırır, iyileşme süreçlerini hızlandırır ve
                  rehabilitasyon süreçlerini daha etkili hale getirir.
                </p>
              </AccordionTab>
              <AccordionTab header="Cihazların zararı var mıdır?">
                <p>
                  İleri teknolojik cihazların genellikle zararı yoktur, ancak
                  yanlış kullanım veya uygun olmayan durumlarda yan etkiler
                  ortaya çıkabilir; bu nedenle, her zaman deneyimli
                  fizyoterapistler tarafından uygulanması önemlidir.
                </p>
              </AccordionTab>
              <AccordionTab header="Cihazların iyileşme sürecine etkisi nedir?">
                <p>
                  İleri teknolojik cihazlar, iyileşme sürecini hızlandırarak
                  tedaviye özel egzersizleri daha etkili ve motive edici hale
                  getirir, bu sayede danışanların fonksiyonel yeteneklerini
                  artırır.
                </p>
              </AccordionTab>
              <AccordionTab header="Ante Sağlık'ta hangi cihazlar kullanılır?">
                <p>
                  Backup Teknolojisi, Robotik Taramalı Lazer, Andulasyon Terapi
                  Cihazı gibi ileri teknolojik cihazlar kullanılmaktadır. Bu
                  cihazlar, rehabilitasyon süreçlerini destekleyerek
                  danışanların hareket kabiliyetlerini geliştirmelerine yardımcı
                  olmaktadır.
                </p>
              </AccordionTab>
              <AccordionTab header="İleri teknolojik cihazların fizyoterapiye katkısı nelerdir?">
                <p>
                  İleri teknolojik cihazlar, tedavi süreçlerini daha etkili hale
                  getirerek danışanların hareket kabiliyetini artırır ve
                  iyileşme sürelerini kısaltır.
                </p>
              </AccordionTab>
              <AccordionTab header="Cihazlar için nasıl randevu oluşturulur?">
                <p>
                  Cihazlar için randevu oluşturmak için 0530 898 4114 veya 0262
                  503 8575 numaralarını arayabilir veya Ante Sağlık üzerinden
                  ücretsiz ön değerlendirme randevusu oluşturabilirsiniz.
                </p>
              </AccordionTab>
              <AccordionTab header="Ante Sağlık Merkezi konum bilgisi nedir?">
                <p>
                  Ante Sağlık Merkezi, Yahya Kaptan Mahallesi, Şehit Ali İhsan
                  Çakmak Sokak No:10, Kat: 2, 41040 İzmit/Kocaeli adresinde
                  bulunmaktadır.
                </p>
              </AccordionTab>
            </Accordion>
          </div>
          <div className="team-2 pb-0 pt-0">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <div className="title">
                    <h2
                      className="title ml-auto mr-auto text-center"
                      style={{
                        color: "#123274",
                        fontFamily: "Benguiat Regular",
                      }}
                    >
                      UZMANLIK ALANLARIMIZ
                    </h2>
                  </div>
                </Col>
              </Row>
              <Row data-aos="fade-up" data-aos-duration="1000">
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/teknolojik-cihazlar-ile-destek/back-up-teknolojisi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/teknolojikCihazlarlaDestek/alanlar/backup.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/teknolojik-cihazlar-ile-destek/robotik-taramali-lazer"
                          )
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/teknolojikCihazlarlaDestek/alanlar/robotik.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/teknolojik-cihazlar-ile-destek/andulasyon-terapi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/teknolojikCihazlarlaDestek/alanlar/andulasyon.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default TeknolojikCihazlar;
