import WhiteNavbar from "components/Navbars/WhiteNavbar";
import React, { useEffect } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async";
import { FaCheck } from "react-icons/fa";
import Footer from "components/Footers/Footer";
import { HashLink } from "react-router-hash-link";
import { useSize } from "components/SizeControl/SizeControl";
import { Accordion, AccordionTab } from "primereact/accordion";
import imageHeader from "../assets/ossaPictures/fizyoterapi/header.png";
import PictureHeader from "components/PictureHeader/PictureHeader";
import { useNavigate } from "react-router-dom";

const FizikTedavi = () => {
  const { under1200, under992 } = useSize();
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
    const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin

    if (targetLink) {
      // Otomatik tıklama
      targetLink.click();
      const imgElement = document.querySelector(".gt-current-lang img");
      const newImgSrc = "https://cdn.gtranslate.net/flags/32/tr.png"; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
      console.log(imgElement);
      imgElement.src = newImgSrc;
      imgElement.width = "32";
      imgElement.height = "32";
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>FİZİK TEDAVİ VE REHABİLİTASYON | OSSA CLINIC</title>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="OSSA CLINIC sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
          data-rh="true"
        />
        <link
          rel="canonical"
          href="https://www.ossaclinic.com"
        />
        <meta property="og:title" content="FİZİK TEDAVİ VE REHABİLİTASYON | OSSA CLINIC" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="OSSA CLINIC sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
        />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content="https://www.ossaclinic.com"
        />
      </Helmet>
      <WhiteNavbar navbarSelection={"about-us"} />
      <PictureHeader imageHeader={imageHeader} />
      <div>
        <Container style={{ fontFamily: "Nucleo Outline" }}>
          <div>
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>FİZİK TEDAVİ VE REHABİLİTASYON</b>
            </h2>
          </div>
          <Row>
            <Col className="ml-auto" md="6" style={{ alignContent: "center" }}>
              <img
                alt="..."
                className="img rounded"
                src={require("assets/ossaPictures/fizyoterapi/fizyoterapi.png")}
              ></img>
            </Col>
            <Col
              className="mr-auto mt-5 mt-md-3"
              md="6"
              data-aos="zoom-in"
              data-aos-duration="2000"
              style={{ alignSelf: "center" }}
            >
              <p>
                Fizyoterapi, birçok insanın yaşam kalitesini artırmak ve
                ağrıları azaltmak için başvurduğu bir yöntemdir. Fizik tedavi
                merkezleri, hastaların fizyoterapi ihtiyaçlarını karşılamak için
                en sık ziyaret edilen sağlık kurumlarından biridir. Bu konuda
                hizmet sunan birçok merkez arasında,
                <b>Fizik Tedavi Merkezi</b> son teknoloji cihazları ve uzman
                kadrosuyla öne çıkıyor.
              </p>
              <p>
                <b>Fizik Tedavi Merkezi</b>, müşterilerinin fizyoterapi
                sürecinde en iyi deneyimi elde etmesi için son teknolojik
                imkanları ve alanında uzman fizyoterapistleri bir araya
                getirerek hizmet vermektedir. Merkezde uygulanan fizyoterapi
                yöntemleri sayesinde hastaların hareket kabiliyetleri artırılır
                ve ağrıları azaltılır. Ayrıca,
                <b>Ossa Fizik Tedavi Merkezi</b>'nin geniş hizmet yelpazesi
                sayesinde birçok hastalık ve rahatsızlık tedavi edilebilir.
              </p>
              <p>
                <b class="emphasize">Fizyoterapi</b>, sadece hastalıkların
                tedavisi için değil, aynı zamanda sporcuların performansını
                artırmak ve sakatlıklarını önlemek için de kullanılan bir
                yöntemdir.
                <b>Ossa Fizik Tedavi Merkezi</b>, sporculardan işçilere,
                hamilelerden yaşlılara kadar birçok kişinin fizyoterapi
                ihtiyaçlarını karşılamakta ve her müşteriye özel tedavi
                paketleri sunmaktadır. Sonuç olarak,{" "}
                <b>Ossa Fizik Tedavi Merkezi</b>, kaliteli hizmet anlayışı ve
                uzman kadrosuyla fizyoterapi alanında öne çıkmaktadır.
              </p>
            </Col>
          </Row>
          <div className="title">
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>SIKÇA SORULAN SORULAR</b>
            </h2>
          </div>
          <br></br>
          <div className="card">
            <Accordion activeIndex={0}>
              <AccordionTab header="Fizyoterapi nedir?">
                <p className="m-0">
                  Fizyoterapi, bir dizi hareket, masaj ve diğer teknikleri
                  kullanarak hastalıkların tedavisinde kas, kemik, eklemler ve
                  sinir sistemini hedef alan bir tedavi yöntemidir.
                </p>
              </AccordionTab>
              <AccordionTab header="Fizyoterapi kimlere uygulanır?">
                <p className="m-0">
                  Fizyoterapi, spor yaralanmaları, iş kazaları, yaşlılıkla
                  birlikte ortaya çıkan hareket kısıtlılıkları, romatoid artrit,
                  felç, beyin hasarı gibi birçok farklı durumda kullanılabilir.
                </p>
              </AccordionTab>
              <AccordionTab header="Fizyoterapi ne zaman kullanılmalıdır?">
                <p className="m-0">
                  Fizyoterapi, ağrı, hareket kısıtlılığı veya günlük yaşam
                  aktivitelerine katılımda zorluklar yaşayan herhangi bir kişi
                  için uygun olabilir. Doktorunuz veya fizyoterapistiniz, sizin
                  için en iyi tedavi yöntemini belirlemek için size tavsiyelerde
                  bulunacaktır.
                </p>
              </AccordionTab>
              <AccordionTab header="Fizyoterapide hangi teknikler kullanılır?">
                <p className="m-0">
                  Fizyoterapide birçok teknik kullanılabilir. Bu teknikler
                  arasında egzersizler, manuel terapi (masaj), elektroterapi
                  (elektriksel stimülasyon), ultrason, sıcak-soğuk tedavisi gibi
                  yöntemler yer alır.
                </p>
              </AccordionTab>
              <AccordionTab header="Fizyoterapi süreci nasıl işler?">
                <p className="m-0">
                  Fizyoterapi süreci, hastanın durumuna ve tedavi edilmesi
                  gereken sorunlara bağlı olarak değişebilir. Ancak genellikle,
                  hastanın fiziksel muayenesi yapılır ve ardından uygun tedavi
                  planı oluşturulur. Bu plan, belirli egzersizler, masajlar veya
                  diğer teknikleri içerebilir.
                </p>
              </AccordionTab>
              <AccordionTab header="Fizyoterapi ne kadar sürer?">
                <p className="m-0">
                  Fizyoterapi süresi, tedavi edilmesi gereken soruna bağlı
                  olarak değişebilir. Bazı durumlarda, tek bir seans yeterli
                  olabilirken, bazı durumlarda haftalar veya aylar boyunca
                  düzenli seanslar gerekebilir.
                </p>
              </AccordionTab>
              <AccordionTab header="Fizyoterapinin faydaları nelerdir?">
                <p className="m-0">
                  Fizyoterapi, kas gücünü artırabilir, ağrıyı azaltabilir,
                  hareket kısıtlılıklarını gidermeye yardımcı olabilir ve
                  sağlıklı yaşam tarzı alışkanlıkları geliştirmeye yardımcı
                  olabilir.
                </p>
              </AccordionTab>
              <AccordionTab header="Fizyoterapiye kimler başvurabilir?">
                <p className="m-0">
                  Fizyoterapiye herkes başvurabilir. Ancak, özellikle fiziksel
                  aktivitelerini sınırlayan kişiler, sporcular ve kronik
                  ağrıları olan kişiler için özellikle yararlı olabilir.
                </p>
              </AccordionTab>
              <AccordionTab header="Fizyoterapist nasıl seçilir?">
                <p className="m-0">
                  Fizyoterapist seçimi, deneyim, uzmanlık alanı ve referanslar
                  gibi faktörlere bağlı olarak yapılmalıdır. Ossa Fizik Tedavi
                  Merkezi, bu alanda deneyimli fizyoterapistleriyle hizmet
                  vermektedir.
                </p>
              </AccordionTab>
              <AccordionTab header="Ossa Fizik Tedavi Merkezi'ne nasıl ulaşabilirim?">
                <p className="m-0">
                  Ossa Fizik Tedavi Merkezi, Kocaeli'nin İzmit ilçesinde yer
                  almaktadır. Randevu almak için +90 530 898 4114 numaralı
                  telefondan veya www.ossaclinic.com internet adresinden
                  iletişime geçebilirsiniz.
                </p>
              </AccordionTab>
            </Accordion>
          </div>
          <div>
            <div className="title">
              <h2
                className="ml-auto mr-auto text-center"
                style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                <b>VİDEO İÇERİKLERİ</b>
              </h2>
            </div>
            <br></br>
            <br></br>

            <Row className="justify-content-center">
              <Col md="6">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/FbLxLsz3jIA?si=X_ygvd2ln---mCAY"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </Col>
            </Row>
          </div>
          <div className="team-2 pb-0 pt-0">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <div className="title">
                    <h2
                      className="title ml-auto mr-auto text-center"
                      style={{
                        color: "#123274",
                        fontFamily: "Benguiat Regular",
                      }}
                    >
                      UZMANLIK ALANLARIMIZ
                    </h2>
                  </div>
                </Col>
              </Row>
              <Row data-aos="fade-up" data-aos-duration="1000">
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/bel-fitigi")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/belFitigi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/boyun-agrisi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/boyunAgrisi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/diz-agrisi")
                        }
                      >
                        <img
                          alt="..."
                          style={{ border: "1px solid black" }}
                          className="img img-raised rounded img-hover"
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/dizAgrisi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row data-aos="fade-up" data-aos-duration="1000">
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/omurga-sagligi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/omurgaSagligi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/skolyoz")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/skolyoz.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/sporcu-sagligi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          style={{ border: "1px solid black" }}
                          className="img img-raised rounded img-hover"
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/sporcuSagligi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row
                data-aos="fade-up"
                data-aos-duration="1000"
                style={{ justifyContent: "center" }}
              >
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/sirt-agrisi")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/sırtAgrisi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/osteopati")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/osteopati.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/recovery")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/recovery.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                
              </Row>
              <Row
                data-aos="fade-up"
                data-aos-duration="1000"
                style={{ justifyContent: "center" }}
              >
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/manuel-terapi")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/manuelTerapi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/rehabilitasyonlar")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/rehabilitasyon.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/klinik-plates")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/klinikPlates.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row
                data-aos="fade-up"
                data-aos-duration="1000"
                style={{ justifyContent: "center" }}
              >
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/gebelik-platesi")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/gebelikPlates.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default FizikTedavi;
