import WhiteNavbar from "components/Navbars/WhiteNavbar";
import React, { useEffect } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async";
import { FaCheck } from "react-icons/fa";
import Footer from "components/Footers/Footer";
import { HashLink } from "react-router-hash-link";
import { useSize } from "components/SizeControl/SizeControl";
import { Accordion, AccordionTab } from "primereact/accordion";
import imageHeader from "../assets/ossaPictures/fizyoterapi/alanlar/GebelikPlatesHeader.png";
import PictureHeader from "components/PictureHeader/PictureHeader";
import { useNavigate } from "react-router-dom";

const GebelikPlatesi = () => {
  const { under1200, under992 } = useSize();
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
    const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin

    if (targetLink) {
      // Otomatik tıklama
      targetLink.click();
      const imgElement = document.querySelector(".gt-current-lang img");
      const newImgSrc = "https://cdn.gtranslate.net/flags/32/tr.png"; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
      console.log(imgElement);
      imgElement.src = newImgSrc;
      imgElement.width = "32";
      imgElement.height = "32";
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>GEBELİK PLATESİ | OSSA CLINIC</title>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="OSSA CLINIC sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
          data-rh="true"
        />
        <link
          rel="canonical"
          href="https://www.ossaclinic.com"
        />
        <meta property="og:title" content="GEBELİK PLATESİ | OSSA CLINIC" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="OSSA CLINIC sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
        />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content="https://www.ossaclinic.com"
        />
      </Helmet>
      <WhiteNavbar navbarSelection={"about-us"} />
      <PictureHeader imageHeader={imageHeader} />
      <div>
        <Container style={{ fontFamily: "Nucleo Outline" }}>
          <div>
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>GEBELİK PLATESİ</b>
            </h2>
          </div>
          <Row>
            <Col className="ml-auto" md="6" style={{ alignContent: "center" }}>
              <img
                alt="..."
                className="img rounded"
                src={require("assets/ossaPictures/fizyoterapi/alanlar/gebelikPlates.png")}
              ></img>
            </Col>
            <Col
              className="mr-auto mt-5 mt-md-3"
              md="6"
              data-aos="zoom-in"
              data-aos-duration="2000"
              style={{ alignSelf: "center" }}
            >
              <h3><strong>Ante Sağlık Merkezi: Gebelik Pilatesi Programı</strong></h3>
<p><strong>Hamilelik</strong>, her kadının hayatında önemli bir dönüm noktasıdır. Bu süreçte fiziksel ve zihinsel sağlığın korunması, hem anne hem de bebeğin sağlığı açısından son derece önemlidir. <strong>Ante Sağlık Merkezi</strong> olarak, gebelik sürecinizi daha konforlu ve sağlıklı hale getirmek için özel olarak tasarlanmış gebelik pilatesi programımızı sunuyoruz.</p>

<br/>

<h3><strong>Gebelik Pilatesi Nedir?</strong></h3>
<p>Gebelik pilatesi, hamilelik döneminde kadınların fiziksel dayanıklılığını artırmayı, kas gücünü geliştirmeyi ve esnekliği artırmayı hedefleyen özel bir egzersiz programıdır. Nefes kontrolü ve postür odaklı hareketlerle, hamileliğin getirdiği bedensel değişimlere uyum sağlamaya yardımcı olur. Uzman eğitmenler eşliğinde gerçekleştirilen bu seanslar, hem bireysel hem de grup olarak planlanabilir.</p>

<br/>

<h3><strong>Neden Gebelik Pilatesi Yapılmalı?</strong></h3>
<p><strong>1. Fiziksel Sağlık:</strong> Hamilelik sırasında vücudun ağırlık merkezi değişir ve bu da bel, sırt ve pelvik bölgede rahatsızlıklara neden olabilir. Gebelik pilatesi, bu bölgeleri güçlendirerek olası sorunları önler.</p>
<p><strong>2. Ruhsal Destek:</strong> Pilates, zihinsel rahatlama sağlayarak stres, kaygı ve doğum korkusunu azaltır. Bu, doğum sürecine daha olumlu bir bakış açısıyla yaklaşmanıza yardımcı olur.</p>
<p><strong>3. Doğuma Hazırlık:</strong> Pilates, doğum sırasında gerekli kas gruplarını güçlendirir ve nefes kontrolü sağlar. Bu sayede doğum anında daha etkili bir şekilde hareket edebilir ve sancıları daha rahat yönetebilirsiniz.</p>

<br/>

<h3><strong>Gebelik Pilatesinin Faydaları</strong></h3>
<ul>
    <li><strong>Kas Güçlendirme:</strong> Karın, sırt ve pelvik taban kaslarını güçlendirir, bu da bebeğin taşınmasını kolaylaştırır.</li>
    <li><strong>Esneklik Artışı:</strong> Vücudunuzun esnekliğini artırarak hamilelik sürecinde daha rahat hareket etmenizi sağlar.</li>
    <li><strong>Dolaşım İyileşmesi:</strong> Kan dolaşımını artırarak ödem, kramplar ve varis oluşumunu azaltır.</li>
    <li><strong>Ağrı Yönetimi:</strong> Bel ve sırt ağrılarını azaltır, omurganın düzgün hizalanmasına yardımcı olur.</li>
    <li><strong>Mental Rahatlama:</strong> Zihinsel farkındalığı artırarak doğum sonrası dönemde daha zinde ve enerjik hissetmenizi sağlar.</li>
</ul>

<br/>

<p><strong>Ante Sağlık Merkezi</strong>’ndeki gebelik pilatesi programı, hamilelik sürecinizin her aşamasına uygun olarak tasarlanır. Uzman eğitmenler eşliğinde, bireysel ihtiyaçlarınızı göz önünde bulundurarak egzersiz programınız oluşturulur.</p>

            </Col>
          </Row>
          <div className="title">
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>SIKÇA SORULAN SORULAR</b>
            </h2>
          </div>
          <br></br>
          <div className="card">
          <Accordion>
  <AccordionTab header="Gebelik pilatesi nedir?">
    <p>
      Gebelik pilatesi, hamilelik dönemindeki kadınların fiziksel ve zihinsel sağlığını desteklemek amacıyla özel olarak tasarlanmış egzersiz programıdır. Ante Sağlık Merkezi'nde, uzman fizyoterapistler eşliğinde güvenli ve etkili bir şekilde uygulanarak, anne adaylarının doğuma hazırlık süreçlerine katkı sağlar.
    </p>
  </AccordionTab>
  <AccordionTab header="Gebelik pilatesi ne işe yarar?">
    <p>
      Gebelik pilatesi, Ante Sağlık Merkezi'nde, anne adaylarının fiziksel sağlığını koruyarak doğuma hazırlanmalarına yardımcı olur, bel ve sırt ağrılarını hafifletir ve genel zindelik sağlar.
    </p>
  </AccordionTab>
  <AccordionTab header="Gebelik pilatesi nasıl yapılır?">
    <p>
      Ante Sağlık Merkezi'nde gebelik pilatesi, uzman fizyoterapistler eşliğinde, bireysel ihtiyaçlara uygun olarak güvenli hareketlerle ve nefes kontrolü ile yapılır.
    </p>
  </AccordionTab>
  <AccordionTab header="Gebelik pilatesinin faydaları nelerdir?">
    <p>
      Ante Sağlık Merkezi'nde yapılan gebelik pilatesi, anne adaylarının bel ve sırt ağrılarını hafifletir, kas tonusunu artırarak doğuma hazırlık sürecini kolaylaştırır ve genel fiziksel ve zihinsel sağlığı destekler.
    </p>
  </AccordionTab>
  <AccordionTab header="Gebelik pilatesi Ante Sağlıkta kim tarafından uygulanır?">
    <p>
      Gebelik pilatesi, Ante Sağlık Merkezi'nde uzman fizyoterapistler tarafından uygulanmaktadır.
    </p>
  </AccordionTab>
  <AccordionTab header="Gebelik Pilatesi için seans planlamaları nasıl oluyor?">
    <p>
      Ante Sağlık Merkezi'nde gebelik pilatesi seansları, anne adayının bireysel ihtiyaçlarına ve sağlık durumuna göre özel olarak planlanmakta; genellikle haftada 2-3 seans şeklinde düzenlenmektedir.
    </p>
  </AccordionTab>
  <AccordionTab header="Gebelikte pilates yapmak sağlıklı mı?">
    <p>
      Evet, gebelikte pilates yapmak, doktor onayı ile sağlıklı bir süreçtir; fiziksel rahatlama sağlar, bel ve sırt ağrılarını hafifletir, kas tonusunu artırarak doğuma hazırlığa yardımcı olur.
    </p>
  </AccordionTab>
  <AccordionTab header="Gebelik pilatesine ne zaman başlanmalı?">
    <p>
      Gebelik pilatesine, doktor onayı ile genellikle 14. haftadan itibaren başlanabilir.
    </p>
  </AccordionTab>
  <AccordionTab header="Kimler gebelik pilatesi yapabilir?">
    <p>
      Düşük riski olmayan, tansiyon ve diyabet gibi rahatsızlıkları bulunmayan ve erken doğum riski taşımayan anne adayları gebelik pilatesi yapabilir.
    </p>
  </AccordionTab>
  <AccordionTab header="Gebelik sürecinde pilates zayıflatır mı?">
    <p>
      Gebelik sürecinde pilates, kilo alımını kontrol etmeye yardımcı olabilir, ancak temel amacı zayıflatmak değil, kas tonusunu artırmak ve doğuma hazırlanmaktır.
    </p>
  </AccordionTab>
  <AccordionTab header="Gebelik pilatesi için nasıl randevu alınır?">
    <p>
      Gebelik pilatesi için randevu almak üzere 0530 898 4114 veya 0262 503 8575 numaralarını arayabilir ya da Ante Sağlık üzerinden ücretsiz ön değerlendirme randevusu oluşturabilirsiniz.
    </p>
  </AccordionTab>
</Accordion>

          </div>
          <div className="team-2 pb-0 pt-0">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <div className="title">
                    <h2
                      className="title ml-auto mr-auto text-center"
                      style={{
                        color: "#123274",
                        fontFamily: "Benguiat Regular",
                      }}
                    >
                      DİĞER UZMANLIK ALANLARIMIZ
                    </h2>
                  </div>
                </Col>
              </Row>
              <Row data-aos="fade-up" data-aos-duration="1000">
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/bel-fitigi")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/belFitigi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/boyun-agrisi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/boyunAgrisi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/diz-agrisi")
                        }
                      >
                        <img
                          alt="..."
                          style={{ border: "1px solid black" }}
                          className="img img-raised rounded img-hover"
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/dizAgrisi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row data-aos="fade-up" data-aos-duration="1000">
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/omurga-sagligi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/omurgaSagligi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/skolyoz")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/skolyoz.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/sporcu-sagligi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          style={{ border: "1px solid black" }}
                          className="img img-raised rounded img-hover"
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/sporcuSagligi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row
                data-aos="fade-up"
                data-aos-duration="1000"
                style={{ justifyContent: "center" }}
              >
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/sirt-agrisi")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/sırtAgrisi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/osteopati")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/osteopati.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/recovery")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/recovery.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                
              </Row>
              <Row
                data-aos="fade-up"
                data-aos-duration="1000"
                style={{ justifyContent: "center" }}
              >
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/manuel-terapi")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/manuelTerapi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/rehabilitasyonlar")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/rehabilitasyon.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/klinik-plates")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/klinikPlates.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
              
            </Container>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default GebelikPlatesi;
