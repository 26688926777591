import WhiteNavbar from "components/Navbars/WhiteNavbar";
import React, { useEffect } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async";
import { FaCheck } from "react-icons/fa";
import Footer from "components/Footers/Footer";
import { HashLink } from "react-router-hash-link";
import { useSize } from "components/SizeControl/SizeControl";
import { Accordion, AccordionTab } from "primereact/accordion";
import imageHeader from "../assets/ossaPictures/fizyoterapi/alanlar/sirtAgrisiHeader.png";
import PictureHeader from "components/PictureHeader/PictureHeader";
import { useNavigate } from "react-router-dom";

const SirtAgrisi = () => {

  useEffect(() => {
    AOS.init();
  }, []);

  const { under1200, under992 } = useSize();
  console.log(under1200, under992);
  const navigate = useNavigate();
  useEffect(() => {
    // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
    const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin

    if (targetLink) {
      // Otomatik tıklama
      targetLink.click();
      const imgElement = document.querySelector(".gt-current-lang img");
      const newImgSrc = "https://cdn.gtranslate.net/flags/32/tr.png"; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
      console.log(imgElement);
      imgElement.src = newImgSrc;
      imgElement.width = "32";
      imgElement.height = "32";
    }
  }, []);
  return (
    <>
      <Helmet>
        <title>SIRT AĞRISI | OSSA CLINIC</title>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="OSSA CLINIC sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
          data-rh="true"
        />
        <link
          rel="canonical"
          href="https://www.ossaclinic.com"
        />
        <meta property="og:title" content="SIRT AĞRISI | OSSA CLINIC" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="OSSA CLINIC sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
        />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content="https://www.ossaclinic.com"
        />
      </Helmet>
      <WhiteNavbar navbarSelection={"about-us"} />
      <PictureHeader imageHeader={imageHeader} />
      <div>
        <Container style={{ fontFamily: "Nucleo Outline" }}>
          <div>
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>SIRT AĞRISI</b>
            </h2>
          </div>
          <Row>
            <Col className="ml-auto" md="6" style={{ alignContent: "center" }}>
              <img
                alt="..."
                className="img rounded"
                src={require("assets/ossaPictures/fizyoterapi/alanlar/sırtAgrisi.png")}
              ></img>
            </Col>
            <Col
              className="mr-auto mt-5 mt-md-3"
              md="6"
              data-aos="zoom-in"
              data-aos-duration="2000"
              style={{ alignSelf: "center" }}
            >
              <p>
                <b>Sırt ağrısı</b>, birçok kişinin karşılaştığı yaygın bir
                sorundur. Özellikle masa başı işlerinde çalışanlar ve yanlış
                duruş pozisyonuyla oturanlar sırt ağrısı problemleriyle sık sık
                karşılaşır. <b>Ossa Fizik Tedavi Merkezi</b>, uzman
                fizyoterapistleri ve son teknolojik cihazlarıyla sırt ağrısı
                tedavisi konusunda müşterilerine etkili çözümler sunmaktadır.
              </p>

              <p>
                <b>Ossa Fizik Tedavi Merkezi</b>'nde sırt ağrısı tedavisi için
                uygulanan yöntemler arasında manuel terapi, egzersizler,
                akupunktur, masaj ve lazer terapi gibi alternatif tedavi
                seçenekleri yer almaktadır. Uzman fizyoterapistler, hastaların
                bireysel ihtiyaçlarına göre özel tedavi planları hazırlayarak
                sırt ağrısını azaltmak ve hareket kabiliyetini artırmak için
                çalışır.
              </p>

              <p>
                <b>Ossa Fizik Tedavi Merkezi</b>'nin sırt ağrısı tedavisi,
                sadece semptomları hafifletmekle kalmaz, aynı zamanda sorunun
                kökenine inerek nedenlerini de ortadan kaldırmaya çalışır. Bu
                sayede, hastaların sırt ağrısı problemleri uzun vadede
                tekrarlamaz. Sonuç olarak, <b>Ossa Fizik Tedavi Merkezi</b>,
                sırt ağrısı tedavisi konusunda uzman kadrosu ve son teknolojik
                cihazlarıyla müşterilerine en iyi hizmeti sunmaktadır.
              </p>
            </Col>
          </Row>

          <div className="title">
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>SIKÇA SORULAN SORULAR</b>
            </h2>
          </div>
          <br></br>
          <div className="card">
            <Accordion activeIndex={0}>
              <AccordionTab header="Sırt ağrısı neden olur?">
                <p className="m-0">
                  Sırt ağrısı birçok nedene bağlı olabilir. Bunlar arasında kötü
                  duruş, hareketsizlik, yaralanma, kireçlenme, disk problemleri
                  ve enfeksiyonlar yer alabilir.
                </p>
              </AccordionTab>
              <AccordionTab header="Sırt ağrısından nasıl korunabilirim?">
                <p className="m-0">
                  Sırt ağrısından korunmak için düzenli egzersiz yapmak, düzgün
                  duruş ve oturuş pozisyonlarına dikkat etmek, yüksek topuklu
                  ayakkabı giymemek gibi önlemler alabilirsiniz.
                </p>
              </AccordionTab>
              <AccordionTab header="Hangi yaş grubu sırt ağrısı riski altındadır?">
                <p className="m-0">
                  Sırt ağrısı her yaşta görülebilir ancak iş hayatında fazla
                  oturan ya da ağır iş yapan kişilerde daha sık rastlanır.
                </p>
              </AccordionTab>
              <AccordionTab header="Hangi testler sırt ağrısı teşhisi koymak için yapılır?">
                <p className="m-0">
                  Sırt ağrısı teşhisi genellikle fizik muayene ile konulur. Bazı
                  durumlarda röntgen ve MRI gibi görüntüleme testleri de
                  kullanılabilir.
                </p>
              </AccordionTab>
              <AccordionTab header="Sırt ağrısı için hangi tedaviler mevcut?">
                <p className="m-0">
                  Sırt ağrısı tedavisi, doktorunuzun muayene sonucuna göre
                  değişebilir. Tedavi seçenekleri arasında ilaçlar, fizik
                  tedavi, akupunktur ve cerrahi müdahale yer alabilir.
                </p>
              </AccordionTab>
              <AccordionTab header="Sırt ağrısına karşı egzersiz yapmak ne kadar etkilidir?">
                <p className="m-0">
                  Egzersiz, sırt ağrısı için etkili bir tedavi yöntemidir.
                  Ancak, hangi egzersizlerin yapılacağı konusunda mutlaka uzman
                  bir fizyoterapist ile görüşmeniz önerilir.
                </p>
              </AccordionTab>
              <AccordionTab header="Sırt ağrısıyla birlikte hangi belirtiler acil tıbbi müdahale gerektirir?">
                <p className="m-0">
                  Sırt ağrısı, mide bulantısı, kusma, ateş, idrar yaparken ağrı
                  veya kanama gibi belirtilerle birlikte ise, derhal bir doktora
                  başvurulması gereklidir.
                </p>
              </AccordionTab>
              <AccordionTab header="Sırt ağrısı ne kadar sürebilir?">
                <p className="m-0">
                  Sırt ağrısı, kişiden kişiye değişmekle birlikte, genellikle
                  birkaç gün içinde geçer. Ancak, bazı durumlarda haftalarca
                  hatta aylarca sürebilir.
                </p>
              </AccordionTab>
              <AccordionTab header="Ossa Fizik Tedavi Merkezi sırt ağrısı tedavisi hakkında ne kadar bilgiye sahiptir?">
                <p className="m-0">
                  Ossa Fizik Tedavi Merkezi, deneyimli uzmanlarıyla birlikte
                  sırt ağrısı tedavisi konusunda geniş bir yelpazede hizmet
                  vermektedir. Uygun tedavi seçenekleri ile hastalarına sağlıklı
                  bir yaşam sunmayı amaçlamaktadır.
                </p>
              </AccordionTab>
            </Accordion>
          </div>
          <div className="team-2 py-0">
          <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <div className="title">
                    <h2
                      className="title ml-auto mr-auto text-center"
                      style={{
                        color: "#123274",
                        fontFamily: "Benguiat Regular",
                      }}
                    >
                      DİĞER UZMANLIK ALANLARIMIZ
                    </h2>
                  </div>
                </Col>
              </Row>
              <Row data-aos="fade-up" data-aos-duration="1000">
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/bel-fitigi")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/belFitigi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/boyun-agrisi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/boyunAgrisi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/diz-agrisi")
                        }
                      >
                        <img
                          alt="..."
                          style={{ border: "1px solid black" }}
                          className="img img-raised rounded img-hover"
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/dizAgrisi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row data-aos="fade-up" data-aos-duration="1000">
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/omurga-sagligi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/omurgaSagligi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/skolyoz")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/skolyoz.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/sporcu-sagligi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          style={{ border: "1px solid black" }}
                          className="img img-raised rounded img-hover"
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/sporcuSagligi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row
                data-aos="fade-up"
                data-aos-duration="1000"
                style={{ justifyContent: "center" }}
              >
               <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/gebelik-platesi")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/gebelikPlates.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/osteopati")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/osteopati.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/recovery")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/recovery.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                
              </Row>
              <Row
                data-aos="fade-up"
                data-aos-duration="1000"
                style={{ justifyContent: "center" }}
              >
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/manuel-terapi")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/manuelTerapi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/rehabilitasyonlar")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/rehabilitasyon.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/klinik-plates")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/klinikPlates.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
            
            </Container>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default SirtAgrisi;
