import WhiteNavbar from "components/Navbars/WhiteNavbar";
import React, { useEffect } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async";
import { FaCheck } from "react-icons/fa";
import Footer from "components/Footers/Footer";
import { HashLink } from "react-router-hash-link";
import { useSize } from "components/SizeControl/SizeControl";
import { Accordion, AccordionTab } from "primereact/accordion";
import imageHeader from "../assets/ossaPictures/beslenmeVeDiyetetik/beslenmeVeDiyetetikHeader.png";
import PictureHeader from "components/PictureHeader/PictureHeader";
import { useNavigate } from "react-router-dom";

const Beslenme = () => {
  const { under1200, under992 } = useSize();
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
    const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin

    if (targetLink) {
      // Otomatik tıklama
      targetLink.click();
      const imgElement = document.querySelector(".gt-current-lang img");
      const newImgSrc = "https://cdn.gtranslate.net/flags/32/tr.png"; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
      console.log(imgElement);
      imgElement.src = newImgSrc;
      imgElement.width = "32";
      imgElement.height = "32";
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>BESLENME | OSSA CLINIC</title>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="OSSA CLINIC"
          data-rh="true"
        />
        <link
          rel="canonical"
          href="https://www.ossaclinic.com"
        />
        <meta property="og:title" content="BESLENME | OSSA CLINIC" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="OSSA CLINIC"
        />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content="https://www.ossaclinic.com"
        />
      </Helmet>
      <WhiteNavbar navbarSelection={"about-us"} />
      <PictureHeader imageHeader={imageHeader} />
      <div>
        <Container style={{ fontFamily: "Nucleo Outline" }}>
          <div>
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>BESLENME VE DİYETETİK</b>
            </h2>
          </div>
          <Row>
            <Col className="ml-auto" md="6" style={{ alignContent: "center" }}>
              <img
                alt="..."
                className="img rounded"
                src={require("assets/ossaPictures/beslenmeVeDiyetetik/beslenmeVeDiyetetik.png")}
              ></img>
            </Col>
            <Col
              className="mr-auto mt-5 mt-md-3"
              md="6"
              data-aos="zoom-in"
              data-aos-duration="2000"
              style={{ alignSelf: "center" }}
            >
              <p>
                Beslenme ve danışmanlık, sağlıklı bir yaşam sürdürmek için
                oldukça önemli bir konudur. <b>Ossa Fizik Tedavi Merkezi</b>,
                beslenme ve danışmanlık hizmetleri ile müşterilerine sağlıklı
                bir yaşam tarzı benimsemelerine yardımcı olmaktadır. Merkezin
                uzman diyetisyenleri, her müşterinin bireysel ihtiyaçlarına
                uygun özel beslenme programları hazırlayarak müşterilerin
                sağlıklı beslenme alışkanlığı kazanmalarına yardımcı olmaktadır.
              </p>
              <p>
                <b>Ossa Fizik Tedavi Merkezi</b>'nin beslenme ve danışmanlık
                hizmetleri, sadece kilo vermek isteyenler için değil, aynı
                zamanda sporcuların performansını artırmak ve hastalıklarla
                mücadele etmek isteyenler için de uygundur. Uzman diyetisyenler,
                müşterilerin yaşam tarzlarına ve hedeflerine göre özel beslenme
                planları hazırlayarak daha sağlıklı bir yaşam sürdürmelerini
                sağlar.
              </p>
              <p>
                <b>Ossa Fizik Tedavi Merkezi</b>'nin beslenme ve danışmanlık
                hizmetleri, fizyoterapi sürecinde de büyük önem taşır.
                Fizyoterapi tedavisi alan hastaların beslenme alışkanlıkları,
                tedavinin etkisini artırmak için çok önemlidir. Bu nedenle,
                merkezde yer alan uzman diyetisyenler, fizyoterapi tedavisi alan
                hastaların ihtiyaçlarına uygun beslenme planları hazırlayarak
                tedavi sürecine destek olmaktadır. Sonuç olarak,{" "}
                <b>Ossa Fizik Tedavi Merkezi</b>'nin beslenme ve danışmanlık
                hizmetleri, sağlıklı bir yaşam sürdürmek isteyenler için ideal
                bir seçenektir.
              </p>
            </Col>
          </Row>
          <div className="title">
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>SIKÇA SORULAN SORULAR</b>
            </h2>
          </div>
          <br></br>
          <div className="card">
            <Accordion>
              <AccordionTab
                header="Beslenme ve danışmanlık hizmetleri nedir?"
                className="accordion-one"
              >
                <p class="panel-txt">
                  Beslenme ve danışmanlık hizmetleri, kişinin sağlıklı beslenme
                  konusunda bilgilendirilmesi, gereksinimlerine uygun bir
                  beslenme planı hazırlanması, kilo yönetimi, hastalıkların
                  önlenmesi veya tedavisi gibi konularda yardımcı olmak amacıyla
                  verilen hizmetlerdir.
                </p>
              </AccordionTab>
              <AccordionTab
                header="Diyetisyen kimdir?"
                className="accordion-one"
              >
                <p class="panel-txt">
                  Diyetisyenler, insanların sağlıklı beslenme konusunda
                  bilgilendirilmesi, beslenme planları hazırlanması ve kilo
                  yönetimi ile ilgili konularda yardımcı olmak için eğitim almış
                  sağlık profesyonelleridir.
                </p>
              </AccordionTab>
              <AccordionTab
                header="Hangi durumlarda diyetisyene başvurulmalıdır?"
                className="accordion-one"
              >
                <p class="panel-txt">
                  Zayıflama, kilo alma, diyabet, kalp hastalığı, yüksek
                  tansiyon, çölyak hastalığı, gluten intoleransı, sporcu
                  beslenmesi ve emzirme dönemi gibi durumlarda diyetisyene
                  başvurulabilir.
                </p>
              </AccordionTab>
              <AccordionTab
                header="Online danışmanlık hizmetleri sunuyor musunuz?"
                className="accordion-one"
              >
                <p class="panel-txt">
                  Evet, Beslenme ve Danışmanlık hizmetimiz online olarak da
                  sunulmaktadır.
                </p>
              </AccordionTab>
              <AccordionTab
                header="Beslenme planları kişiye özel mi hazırlanmaktadır?"
                className="accordion-one"
              >
                <p class="panel-txt">
                  Evet, beslenme planları bireysel ihtiyaçlara göre
                  özelleştirilmektedir.
                </p>
              </AccordionTab>
              <AccordionTab
                header="Diyetisyenleriniz hangi konularda uzmandır?"
                className="accordion-one"
              >
                <p class="panel-txt">
                  Ossa Fizik Tedavi Merkezi'ndeki diyetisyenler, obezite,
                  diyabet, kalp hastalığı, yüksek tansiyon, sporcu beslenmesi,
                  çölyak hastalığı gibi konularda uzmandır.
                </p>
              </AccordionTab>
              <AccordionTab
                header="Diyetisyen randevuları ne kadar sürmektedir?"
                className="accordion-one"
              >
                <p class="panel-txt">
                  Diyetisyen randevuları genellikle 45-60 dakika arasında
                  sürmektedir.
                </p>
              </AccordionTab>
              <AccordionTab
                header="Hangi ödeme yöntemlerini kabul ediyorsunuz?"
                className="accordion-one"
              >
                <p class="panel-txt">
                  Ossa Fizik Tedavi Merkezi, nakit, kredi kartı veya banka
                  havalesi yoluyla ödeme kabul etmektedir.
                </p>
              </AccordionTab>
              <AccordionTab
                header="Randevu almak için nasıl iletişime geçebilirim?"
                className="accordion-one"
              >
                <p class="panel-txt">
                  Randevu almak için Ossa Fizik Tedavi Merkezi'nin web sitesinde
                  yer alan iletişim bilgilerinden veya telefon numaralarından
                  bizimle iletişime geçebilirsiniz.
                </p>
              </AccordionTab>
              <AccordionTab
                header="Beslenme ve danışmanlık hizmetlerinizden nasıl yararlanabilirim?"
                className="accordion-one"
              >
                <p class="panel-txt">
                  Ossa Fizik Tedavi Merkezi'ndeki diyetisyenlerimizle randevu
                  alarak beslenme ve danışmanlık hizmetlerinden
                  yararlanabilirsiniz. Diyetisyenlerimiz, size uygun bir
                  beslenme planı hazırlayabilir ve sağlıklı yaşam konusunda size
                  rehberlik edebilir. Ayrıca, web sitemizde yer alan
                  bilgilendirici yazılarımızdan da faydalanabilirsiniz.
                </p>
              </AccordionTab>
            </Accordion>
          </div>
          <div>
            <div className="title">
              <h2
                className="ml-auto mr-auto text-center"
                style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                <b>VİDEO İÇERİKLERİ</b>
              </h2>
            </div>
            <br></br>
            <br></br>

            <Row className="justify-content-center">
              <Col md="6">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/jVbBssr0-Rk?si=KrA0drZ1mTOQbDhd"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </Col>
              
            </Row>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default Beslenme;
