/*eslint-disable*/
import React, { useEffect, useState } from "react";

import { BiSend } from "react-icons/bi";
import {
  PiArrowBendLeftDownDuotone,
  PiArrowBendRightDownDuotone,
} from "react-icons/pi";
import AOS from "aos";
import "aos/dist/aos.css";

// reactstrap components
import {
  Container,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Collapse,
  Button,
} from "reactstrap";
import { Helmet } from "react-helmet-async";

// core components

const Hizmetlerimiz = () => {
  const [pageSubcategories, setpageSubcategories] = React.useState("ps1");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1200) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
    const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin

    if (targetLink) {
      // Otomatik tıklama
      targetLink.click();
      const imgElement = document.querySelector(".gt-current-lang img");
      const newImgSrc = "https://cdn.gtranslate.net/flags/32/tr.png"; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
      console.log(imgElement);
      imgElement.src = newImgSrc;
      imgElement.width = "32";
      imgElement.height = "32";
    }
  }, []);
  return (
    <>
      <Helmet>
        <title>HİZMETLERİMİZ | OSSA CLINIC</title>
        <meta name="theme-color" content="#000000" />
        <meta name="description" content="ossa clinic" data-rh="true" />
        <link rel="canonical" href="https://www.ossaclinic.com/hizmetlerimiz" />
        <meta property="og:title" content="ÜRÜNLERİMİZ | OSSA CLINIC" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="ossa clinic" />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content="https://www.ossaclinic.com/hizmetlerimiz"
        />
      </Helmet>

      <div className="projects-5 pb-0">
        <Container
          style={{ overflow: "hidden", fontFamily: "Nucleo Outline" }}
          fluid
        >
          <Row>
            <Col
              className="ml-auto mr-auto text-center"
              data-aos="fade-down"
              data-aos-duration="1000"
              md="8"
            >
              <h2
                className="title"
                style={{ color: "#2CA8FF", fontFamily: "Benguiat Regular" }}
              >
                YAŞAM MERKEZİMİZDE VERİLEN HİZMETLERİMİZ
              </h2>
            </Col>
          </Row>
          <Row>
            <Col md={12} xs={12} className="ml-auto mr-auto">
              <Card className="card-plain card-subcategories">
                {/* <CardHeader>
                <CardTitle tag="h4" className="text-center">
                  Page Subcategories
                </CardTitle>
                <br />
              </CardHeader> */}
                <CardBody>
                  <Nav
                    pills
                    className="nav-pills-primary nav-pills-icons justify-content-center"
                  >
                    <NavItem>
                      <NavLink
                        className={
                          (pageSubcategories === "ps1" && isMobile === true
                            ? "active "
                            : "") + " my-1 mx-1"
                        }
                        onClick={() => setpageSubcategories("ps1")}
                        style={{
                          cursor: "pointer",
                          fontWeight: isMobile && "bolder",
                          border: isMobile && "1px solid",
                        }}
                      >
                        {isMobile ? (
                          "FİZİK TEDAVİ VE REHABİLİTASYON"
                        ) : (
                          <img
                            alt="..."
                            className="img rounded img-hover img-width"
                            src={
                              pageSubcategories === "ps1"
                                ? require("assets/ossaPictures/hizmetlerimiz/fizik-tedavi-2.png")
                                : require("assets/ossaPictures/hizmetlerimiz/fizik-tedavi-2.png")
                            }
                            style={{
                              ...(pageSubcategories !== "ps1" && { filter: "opacity(0.5)" })
                            }}
                          ></img>
                        )}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={
                          (pageSubcategories === "ps2" && isMobile === true
                            ? "active"
                            : "") + " my-1 mx-1"
                        }
                        onClick={() => setpageSubcategories("ps2")}
                        style={{
                          cursor: "pointer",
                          fontWeight: isMobile && "bolder",
                          border: isMobile && "1px solid",
                        }}
                      >
                        {isMobile ? (
                          "BESLENME VE DİYETETİK"
                        ) : (
                          <img
                            alt="..."
                            className="img rounded img-hover img-width"
                            src={
                              pageSubcategories === "ps2"
                                ? require("assets/ossaPictures/hizmetlerimiz/beslenme-2.png")
                                : require("assets/ossaPictures/hizmetlerimiz/beslenme-2.png")
                            }
                            style={{
                              ...(pageSubcategories !== "ps2" && { filter: "opacity(0.5)" })
                            }}
                          ></img>
                        )}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={
                          (pageSubcategories === "ps3" && isMobile === true
                            ? "active"
                            : "") + " my-1 mx-1"
                        }
                        onClick={() => setpageSubcategories("ps3")}
                        style={{
                          cursor: "pointer",
                          fontWeight: isMobile && "bolder",
                          border: isMobile && "1px solid",
                        }}
                      >
                        {isMobile ? (
                          "PSİKOLOJİ VE DANIŞMANLIK"
                        ) : (
                          <img
                            alt="..."
                            className="img rounded img-hover img-width"
                            src={
                              pageSubcategories === "ps3"
                                ? require("assets/ossaPictures/hizmetlerimiz/psikoloji-2.png")
                                : require("assets/ossaPictures/hizmetlerimiz/psikoloji-2.png")
                            }
                            style={{
                              ...(pageSubcategories !== "ps3" && { filter: "opacity(0.5)" })
                            }}
                          ></img>
                        )}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={
                          (pageSubcategories === "ps4" && isMobile === true
                            ? "active"
                            : "") + " my-1 mx-1"
                        }
                        onClick={() => setpageSubcategories("ps4")}
                        style={{
                          cursor: "pointer",
                          fontWeight: isMobile && "bolder",
                          border: isMobile && "1px solid",
                        }}
                      >
                        {isMobile ? (
                          "TEKNOLOJİK CİHAZLAR İLE DESTEK"
                        ) : (
                          <img
                            alt="..."
                            className="img rounded img-hover img-width"
                            src={
                              pageSubcategories === "ps4"
                                ? require("assets/ossaPictures/hizmetlerimiz/teknoloji-2.png")
                                : require("assets/ossaPictures/hizmetlerimiz/teknoloji-2.png")
                            }
                            style={{
                              ...(pageSubcategories !== "ps4" && { filter: "opacity(0.5)" })
                            }}
                          ></img>
                        )}
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent
                    className="tab-space tab-subcategories"
                    activeTab={pageSubcategories}
                  >
                    <TabPane tabId="ps1">
                      <Container>
                        <p>
                          Fizyoterapi, yaşam kalitesini artırmak ve ağrıları
                          azaltmak için başvurulan bir yöntemdir.{" "}
                          <b>Fizik Tedavi Merkezi</b>, son teknoloji cihazları
                          ve uzman kadrosuyla öne çıkar.
                        </p>
                        <p>
                          Merkez, hastaların fizyoterapi sürecinde en iyi
                          deneyimi sunmak için son teknolojik imkanları
                          kullanır. Fizyoterapi ile hareket kabiliyeti artırılır
                          ve ağrılar azaltılır. <b>Ossa Fizik Tedavi Merkezi</b>
                          , geniş hizmet yelpazesi ile birçok hastalık ve
                          rahatsızlığı tedavi eder.
                        </p>
                        <p>
                          <b class="emphasize">Fizyoterapi</b>, sporcuların
                          performansını artırmak ve sakatlıkları önlemek için de
                          kullanılır. <b>Ossa Fizik Tedavi Merkezi</b>, her
                          müşteriye özel fizyoterapi hizmetleri sunar.
                        </p>
                        <Row>
                          <Col md="6">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              {/* <div className="responsiveArrow">
                                <PiArrowBendLeftDownDuotone size="50" />
                              </div> */}

                              <Button
                                className="btn-round"
                                style={{
                                  backgroundColor: "#cdffd8",
                                  color: "black",
                                  fontWeight: "bolder",
                                  width: "-webkit-fill-available",
                                }}
                                href="/fizik-tedavi-ve-rehabilitasyon"
                              >
                                FİZİK TEDAVİ VE REHABİLİTASYON HAKKINDA DAHA
                                FAZLA BİLGİ AL
                              </Button>
                            </div>
                          </Col>
                          <Col md="6">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              {/* <div className="responsiveArrow">
                                <PiArrowBendRightDownDuotone size="50" />
                              </div> */}

                              <Button
                                className="btn-round"
                                style={{
                                  backgroundColor: "#94b9ff",
                                  color: "black",
                                  fontWeight: "bolder",
                                  width: "-webkit-fill-available",
                                }}
                                href="https://www.doktortakvimi.com/klinikler/ante-saglik"
                                target="_blank"
                                // onClick={changeCourseValueBDE}
                              >
                                ONLINE RANDEVU AL
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </TabPane>

                    <TabPane tabId="ps2">
                      <Container>
                        <p>
                          <b>Beslenme ve Danışmanlık:</b> Sağlıklı yaşam için
                          önemlidir. <b>Ossa Fizik Tedavi Merkezi</b>,
                          müşterilere sağlıklı beslenme alışkanlıkları
                          kazandırır.
                        </p>
                        <p>
                          <b>Beslenme ve Danışmanlık Hizmetleri:</b> Kilo verme,
                          spor performansını artırma ve hastalıklarla mücadele
                          için uygundur. Uzman diyetisyenler, özel beslenme
                          planları hazırlar.
                        </p>
                        <p>
                          <b>
                            Beslenme ve Danışmanlık Hizmetleri ve Fizyoterapi:
                          </b>{" "}
                          Beslenme danışmanlığı, fizyoterapi sürecinde
                          önemlidir. Uzman diyetisyenler, fizyoterapi tedavisi
                          alan hastalar için özel beslenme planları hazırlar.
                        </p>
                        <Row>
                          <Col md="6">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              {/* <div className="responsiveArrow">
                                <PiArrowBendLeftDownDuotone size="50" />
                              </div> */}

                              <Button
                                className="btn-round"
                                style={{
                                  backgroundColor: "#cdffd8",
                                  color: "black",
                                  fontWeight: "bolder",
                                  width: "-webkit-fill-available",
                                }}
                                href="/beslenme-ve-diyetetik"
                              >
                                BESLENME VE DİYETETİK HAKKINDA DAHA FAZLA BİLGİ
                                AL
                              </Button>
                            </div>
                          </Col>
                          <Col md="6">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              {/* <div className="responsiveArrow">
                                <PiArrowBendRightDownDuotone size="50" />
                              </div> */}

                              <Button
                                className="btn-round"
                                style={{
                                  backgroundColor: "#94b9ff",
                                  color: "black",
                                  fontWeight: "bolder",
                                  width: "-webkit-fill-available",
                                }}
                                href="https://www.doktortakvimi.com/klinikler/ante-saglik"
                                target="_blank"
                                // onClick={changeCourseValueBDE}
                              >
                                ONLINE RANDEVU AL
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </TabPane>

                    <TabPane tabId="ps3">
                      <Container>
                        <p>
                          <b>Psikolojik Danışmanlık Hizmetleri:</b> Zihinsel ve
                          duygusal sağlık için önemlidir. <b>Ossa Klinik</b>,
                          müşterilere zihinsel sağlık desteği sunar.
                        </p>
                        <p>
                          <b>Psikolojik Danışmanlık ve Terapi:</b> Duygusal
                          denge ve yaşam kalitesi için uygundur. Uzman
                          psikologlar, bireysel terapi seansları düzenler.
                        </p>
                        <p>
                          <b>
                            Psikolojik Danışmanlık Hizmetleri ve Sağlıklı Yaşam:
                          </b>{" "}
                          Psikolojik danışmanlık, zihinsel ve duygusal sağlığı
                          destekler. <b>Ossa Klinik</b>'in psikologları,
                          müşterilerin sağlıklı yaşam tarzları benimsemelerine
                          yardımcı olur.
                        </p>
                        <Row>
                          <Col md="6">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              {/* <div className="responsiveArrow">
                                <PiArrowBendLeftDownDuotone size="50" />
                              </div> */}

                              <Button
                                className="btn-round"
                                style={{
                                  backgroundColor: "#cdffd8",
                                  color: "black",
                                  fontWeight: "bolder",
                                  width: "-webkit-fill-available",
                                }}
                                href="/psikolojik-danismanlik"
                              >
                                PSİKOLOJİK DANIŞMANLIK HAKKINDA DAHA FAZLA BİLGİ
                                AL
                              </Button>
                            </div>
                          </Col>
                          <Col md="6">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              {/* <div className="responsiveArrow">
                                <PiArrowBendRightDownDuotone size="50" />
                              </div> */}

                              <Button
                                className="btn-round"
                                style={{
                                  backgroundColor: "#94b9ff",
                                  color: "black",
                                  fontWeight: "bolder",
                                  width: "-webkit-fill-available",
                                }}
                                href="https://www.doktortakvimi.com/klinikler/ante-saglik"
                                target="_blank"
                                // onClick={changeCourseValueBDE}
                              >
                                ONLINE RANDEVU AL
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </TabPane>
                    <TabPane tabId="ps4">
                      <Container>
                        <p>
                          <b>Ante Sağlık Merkezi'nin Yenilikçi Yaklaşımları:</b>{" "}
                          &nbsp; Ante Sağlık Merkezi, modern sağlık
                          hizmetlerinin öncüsü olarak, danışanlarına en iyi
                          rehabilitasyon deneyimini sunmayı hedeflemektedir.
                          Gelişen teknoloji sayesinde, robotik cihazlar ve sanal
                          gerçeklik uygulamaları tedavi süreçlerini daha etkili
                          hale getirmektedir. Merkezimizde kullanılan{" "}
                          <b>Backup Teknolojisi, Robotik Taramalı Lazer</b> ve{" "}
                          <b>Andulasyon Terapi Cihazı</b> gibi yenilikçi
                          araçlar, danışanların hareket kabiliyetlerini yeniden
                          kazanmalarına ve günlük yaşam aktivitelerini daha
                          bağımsız bir şekilde gerçekleştirmelerine yardımcı
                          olmaktadır.
                        </p>
                        <p>
                          <b>
                            Deneyimli Fizyoterapistler ve Bireysel Tedavi
                            Programları:
                          </b>
                          &nbsp; Deneyimli fizyoterapistler, her danışanın
                          bireysel ihtiyaçlarına uygun tedavi programları
                          oluşturarak, teknolojinin potansiyelinden en iyi
                          şekilde faydalanılmasını sağlar. Teknolojik cihazlarla
                          desteklenen rehabilitasyon süreçleri daha hızlı ve
                          etkili ilerlemekte, sağlıklı bir yaşam için gerekli
                          temeller atılmaktadır.
                        </p>
                        <Row>
                          <Col md="6">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              {/* <div className="responsiveArrow">
                                <PiArrowBendLeftDownDuotone size="50" />
                              </div> */}

                              <Button
                                className="btn-round"
                                style={{
                                  backgroundColor: "#cdffd8",
                                  color: "black",
                                  fontWeight: "bolder",
                                  width: "-webkit-fill-available",
                                }}
                                href="/teknolojik-cihazlar-ile-destek"
                              >
                                TEKNOLOJİK CİHAZLARLA DESTEK HAKKINDA DAHA FAZLA BİLGİ
                                AL
                              </Button>
                            </div>
                          </Col>
                          <Col md="6">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              {/* <div className="responsiveArrow">
                                <PiArrowBendRightDownDuotone size="50" />
                              </div> */}

                              <Button
                                className="btn-round"
                                style={{
                                  backgroundColor: "#94b9ff",
                                  color: "black",
                                  fontWeight: "bolder",
                                  width: "-webkit-fill-available",
                                }}
                                href="https://www.doktortakvimi.com/klinikler/ante-saglik"
                                target="_blank"
                                // onClick={changeCourseValueBDE}
                              >
                                ONLINE RANDEVU AL
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Hizmetlerimiz;
