import WhiteNavbar from "components/Navbars/WhiteNavbar";
import React, { useEffect } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async";
import { FaCheck } from "react-icons/fa";
import Footer from "components/Footers/Footer";
import { HashLink } from "react-router-hash-link";
import { useSize } from "components/SizeControl/SizeControl";
import { Accordion, AccordionTab } from "primereact/accordion";
import imageHeader from "../assets/ossaPictures/teknolojikCihazlarlaDestek/alanlar/BackUpHeader.png";
import PictureHeader from "components/PictureHeader/PictureHeader";
import { useNavigate } from "react-router-dom";

const BackUpTeknolojisi = () => {
  const { under1200, under992 } = useSize();
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
    const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin

    if (targetLink) {
      // Otomatik tıklama
      targetLink.click();
      const imgElement = document.querySelector(".gt-current-lang img");
      const newImgSrc = "https://cdn.gtranslate.net/flags/32/tr.png"; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
      console.log(imgElement);
      imgElement.src = newImgSrc;
      imgElement.width = "32";
      imgElement.height = "32";
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>BACKUP TEKNOLOJİSİ | OSSA CLINIC</title>
        <meta name="theme-color" content="#000000" />
        <meta name="description" content="OSSA CLINIC" data-rh="true" />
        <link rel="canonical" href="https://www.ossaclinic.com/" />
        <meta property="og:title" content="BACKUP TEKNOLOJİSİ | OSSA CLINIC" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="OSSA CLINIC" />
        <meta property="og:image" content="" />
        <meta property="og:url" content="https://www.ossaclinic.com/" />
      </Helmet>
      <WhiteNavbar navbarSelection={"about-us"} />
      <PictureHeader imageHeader={imageHeader} />
      <div>
        <Container style={{ fontFamily: "Nucleo Outline" }}>
          <div>
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>BACKUP TEKNOLOJİSİ</b>
            </h2>
          </div>
          <Row>
            <Col className="ml-auto" md="6" style={{ alignContent: "center" }}>
              <img
                alt="..."
                className="img rounded"
                src={require("assets/ossaPictures/teknolojikCihazlarlaDestek/alanlar/backup.png")}
              ></img>
            </Col>
            <Col
              className="mr-auto mt-5 mt-md-3"
              md="6"
              data-aos="zoom-in"
              data-aos-duration="2000"
              style={{ alignSelf: "center" }}
            >
              <h3>
                <strong>Günümüzde Omurga Problemleri</strong>
              </h3>
              <p>
                Günümüzde, stresli yaşam tarzları, hareketsizlik ve yanlış duruş
                alışkanlıkları nedeniyle omurga problemleri yaygın bir sorun
                haline gelmiştir. Bu durum, bireylerin günlük yaşam kalitesini
                olumsuz etkileyerek kronik ağrılarla başa çıkmalarını
                zorlaştırmaktadır. <strong>Ante Sağlık Merkezi</strong>, bu
                bağlamda, BACK-UP teknolojisi ile elektriksel stimülasyon
                yöntemlerini bir araya getirerek ağrı yönetiminde devrim
                niteliğinde bir çözüm sunmaktadır.
              </p>

              <br />

              <h3>
                <strong>BACK-UP Teknolojisi Nedir?</strong>
              </h3>
              <p>
                BACK-UP cihazları, patentli teknolojisi sayesinde vücuda düşük
                seviyede elektrik akımları göndererek sinirleri ve kasları
                uyarır. Bu sistem, hem ağrıyı hafifletme hem de kasları
                rahatlatma amacı taşır. Özellikle omurga sağlığını korumak ve
                rehabilitasyon süreçlerini desteklemek için etkili bir yöntem
                olarak öne çıkmaktadır. BACK-UP teknolojisi, hem transkütanöz
                elektriksel sinir stimülasyonu (TENS) hem de interferansiyel
                stimülasyon yöntemlerini birleştirerek derin dokuları hedef
                alır. Bu sayede, vücutta kapı-kontrol sistemini devreye sokarak
                ağrı sinyallerinin iletimini engeller.
              </p>

              <br />

              <h3>
                <strong>Ante Sağlık Merkezi'nin Yaklaşımı</strong>
              </h3>
              <p>
                <strong>Ante Sağlık Merkezi</strong>, alanında uzman ekipleri ve
                yenilikçi tedavi yöntemleriyle BACK-UP teknolojisini kullanarak
                bireylere kişiye özel tedavi planları sunar. Yapay zeka destekli
                analizler sayesinde, her bireyin ihtiyaçlarına uygun en etkili
                tedavi sürecini mümkün kılmaktadır. Uzmanlar, hastaların
                bireysel durumlarına göre cihazın uygulayacağı stimülasyonun
                frekansını, yoğunluğunu ve süresini belirleyerek daha hedefli
                bir tedavi sağlar.
              </p>

              <br />

              <h3>
                <strong>Sonuç</strong>
              </h3>
              <p>
                Bu yaklaşım, hastaların konforunu ön planda tutarken, tedavi
                sürecinin etkinliğini de artırır.{" "}
                <strong>Ante Sağlık Merkezi</strong>’nde yapılan düzenli
                analizler, tedavi sürecinin gelişimini takip ederek gerekirse
                ayarlamalar yapma imkanı sunar.
              </p>
              <p>
                Sonuç olarak, <strong>Ante Sağlık Merkezi</strong>, BACK-UP
                teknolojisi ile ağrı yönetimini daha etkili ve doğal bir hale
                getirerek, bireylerin yaşam kalitesini artırmayı
                hedeflemektedir.
              </p>
            </Col>
          </Row>
          <div className="title">
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>SIKÇA SORULAN SORULAR</b>
            </h2>
          </div>
          <br></br>
          <div className="card">
            <Accordion>
              <AccordionTab header="Back-up teknolojisi nedir?">
                <p>
                  BACK-UP teknolojisi, Ante Sağlık Merkezi'nde kullanılan, düşük
                  seviyede elektrik akımları ile sinirleri ve kasları uyararak
                  ağrıyı hafifleten ve omurga sağlığını destekleyen yenilikçi
                  bir tedavi yöntemidir.
                </p>
              </AccordionTab>
              <AccordionTab header="Back-up teknolojisi nasıl çalışır?">
                <p>
                  BACK-UP teknolojisi, düşük seviyede elektrik akımları ile
                  sinirleri ve kasları uyararak ağrı yönetimi sağlar.
                </p>
              </AccordionTab>
              <AccordionTab header="Hangi sağlık problemlerinde Back-up cihazları kullanılabilir?">
                <p>
                  BACK-UP cihazları, omurga problemleri, kas yorgunluğu, kronik
                  ağrılar, fibromiyalji ve diğer fiziksel rahatsızlıkların
                  tedavisinde kullanılabilir.
                </p>
              </AccordionTab>
              <AccordionTab header="Back-up cihazı ile süreç ne kadar sürer?">
                <p>
                  Back-up cihazı ile tedavi süreci genellikle 30-40 dakika
                  sürer, ancak seans sayısı ve süresi bireysel ihtiyaçlara göre
                  değişiklik gösterebilir.
                </p>
              </AccordionTab>
              <AccordionTab header="Back-up cihazları güvenli midir?">
                <p>
                  Evet, Back-up cihazları, tıbbi standartlara uygun olarak
                  tasarlanmış ve uzmanlar tarafından güvenli bir şekilde
                  kullanılmak üzere onaylanmıştır.
                </p>
              </AccordionTab>
              <AccordionTab header="Yan etkileri var mı?">
                <p>
                  Back-up cihazlarının genellikle yan etkisi yoktur, ancak bazı
                  kullanıcılar hafif cilt irritasyonu veya kas ağrısı
                  hissedebilir. Bu tür durumlar nadirdir ve genellikle tedavi
                  sürecinin başlangıcında görülür.
                </p>
              </AccordionTab>
              <AccordionTab header="Tedavi sırasında ağrı hissedecek miyim?">
                <p>
                  Tedavi sırasında hafif bir rahatsızlık hissedilebilir, ancak
                  genellikle ağrı hissedilmez; aksine, çoğu kullanıcı
                  rahatlatıcı bir etki deneyimler.
                </p>
              </AccordionTab>
              <AccordionTab header="Yapay zeka destekli analizler nasıl çalışıyor?">
                <p>
                  Yapay zeka destekli analizler, bireyin tıbbi geçmişini ve
                  mevcut durumunu değerlendirerek en uygun tedavi
                  parametrelerini belirler; bu süreç, verileri analiz ederek
                  kişiye özel bir tedavi planı oluşturur.
                </p>
              </AccordionTab>
              <AccordionTab header="Bu teknoloji kimler tarafından uygulanmalı?">
                <p>
                  Bu teknoloji, uzman hekimler veya fizyoterapistler tarafından
                  uygulanmalı, çünkü doğru tanı ve tedavi planlaması için
                  gerekli bilgi ve deneyime sahiptirler.
                </p>
              </AccordionTab>
              <AccordionTab header="Back-up teknolojisi için seans planlaması nasıl yapılır?">
                <p>
                  Back-up teknolojisi için seans planlaması, uzman hekim veya
                  fizyoterapist tarafından hastanın bireysel ihtiyaçlarına,
                  sağlık durumuna ve tedavi hedeflerine göre yapılır; genellikle
                  seans süreleri ve sıklığı kişiye özel olarak belirlenir.
                </p>
              </AccordionTab>
            </Accordion>
          </div>
          <div className="team-2 pb-0 pt-0">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <div className="title">
                    <h2
                      className="title ml-auto mr-auto text-center"
                      style={{
                        color: "#123274",
                        fontFamily: "Benguiat Regular",
                      }}
                    >
                      DİĞER UZMANLIK ALANLARIMIZ
                    </h2>
                  </div>
                </Col>
              </Row>
              <Row data-aos="fade-up" data-aos-duration="1000">
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/teknolojik-cihazlar-ile-destek/andulasyon-terapi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/teknolojikCihazlarlaDestek/alanlar/andulasyon.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/teknolojik-cihazlar-ile-destek/robotik-taramali-lazer"
                          )
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/teknolojikCihazlarlaDestek/alanlar/robotik.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default BackUpTeknolojisi;
