import WhiteNavbar from "components/Navbars/WhiteNavbar";
import React, { useEffect } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async";
import { FaCheck } from "react-icons/fa";
import Footer from "components/Footers/Footer";
import { HashLink } from "react-router-hash-link";
import { useSize } from "components/SizeControl/SizeControl";
import { Accordion, AccordionTab } from "primereact/accordion";
import imageHeader from "../assets/ossaPictures/fizyoterapi/alanlar/KlinikPlatesHeader.png";
import PictureHeader from "components/PictureHeader/PictureHeader";
import { useNavigate } from "react-router-dom";

const KlinikPlates = () => {
  const { under1200, under992 } = useSize();
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
    const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin

    if (targetLink) {
      // Otomatik tıklama
      targetLink.click();
      const imgElement = document.querySelector(".gt-current-lang img");
      const newImgSrc = "https://cdn.gtranslate.net/flags/32/tr.png"; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
      console.log(imgElement);
      imgElement.src = newImgSrc;
      imgElement.width = "32";
      imgElement.height = "32";
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>KLİNİK PLATES | OSSA CLINIC</title>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="OSSA CLINIC sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
          data-rh="true"
        />
        <link rel="canonical" href="https://www.ossaclinic.com" />
        <meta property="og:title" content="KLİNİK PLATES | OSSA CLINIC" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="OSSA CLINIC sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
        />
        <meta property="og:image" content="" />
        <meta property="og:url" content="https://www.ossaclinic.com" />
      </Helmet>
      <WhiteNavbar navbarSelection={"about-us"} />
      <PictureHeader imageHeader={imageHeader} />
      <div>
        <Container style={{ fontFamily: "Nucleo Outline" }}>
          <div>
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>KLİNİK PLATES</b>
            </h2>
          </div>
          <Row>
            <Col className="ml-auto" md="6" style={{ alignContent: "center" }}>
              <img
                alt="..."
                className="img rounded"
                src={require("assets/ossaPictures/fizyoterapi/alanlar/klinikPlates.png")}
              ></img>
            </Col>
            <Col
              className="mr-auto mt-5 mt-md-3"
              md="6"
              data-aos="zoom-in"
              data-aos-duration="2000"
              style={{ alignSelf: "center" }}
            >
              <h3>
                <strong>Ante Sağlık Merkezi: Klinik Pilates Hizmetleri</strong>
              </h3>
              <p>
                <strong>Ante Sağlık Merkezi</strong>, bireylerin sağlığını ve
                yaşam kalitesini artırmak amacıyla klinik pilates hizmetleri
                sunmaktadır. Klinik pilates, kas iskelet sistemi
                rahatsızlıklarının önlenmesi ve tedavisi için özel olarak
                tasarlanmış bir egzersiz programıdır. Profesyonel
                fizyoterapistler eşliğinde gerçekleştirilen bu yöntem, kişiye
                özel ihtiyaçlara göre uyarlanarak uygulanır.
              </p>

              <br />

              <h3>
                <strong>Klinik Pilatesin Temel Prensipleri</strong>
              </h3>
              <p>
                <strong>1. Omurga Sağlığını Koruma:</strong> Omurganın doğal
                hizasını korur ve destekler.
              </p>
              <p>
                <strong>2. Kas Gücünü Artırma:</strong> Kasları güçlendirir ve
                dengenizi artırır.
              </p>
              <p>
                <strong>3. Vücut Farkındalığını Geliştirme:</strong> Hareketler
                sırasında vücudu daha iyi tanımayı sağlar.
              </p>

              <br />

              <p>
                Klinik pilates, bel fıtığı, boyun düzleşmesi, duruş bozuklukları
                gibi rahatsızlıklara yönelik etkili bir çözüm sunmanın yanı
                sıra, genel fiziksel dayanıklılığı artırmayı ve günlük yaşam
                aktivitelerini kolaylaştırmayı hedefler.
              </p>

              <br />

              <p>
                Ayrıca, masabaşı çalışanlar ve uzun süre hareketsiz kalmış
                bireyler için de faydalı bir programdır.{" "}
                <strong>Ante Sağlık Merkezi</strong>'nde, bu yenilikçi yaklaşımı
                deneyimleyerek sağlıklı bir yaşama adım atabilirsiniz. Uzman
                ekibimizle birlikte, sizin için en uygun pilates programını
                oluşturuyoruz ve sağlıklı yaşam yolculuğunuzda yanınızda
                oluyoruz.
              </p>
            </Col>
          </Row>
          <div className="title">
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>SIKÇA SORULAN SORULAR</b>
            </h2>
          </div>
          <br></br>
          <div className="card">
            <Accordion>
              <AccordionTab header="Klinik Pilates nedir?">
                <p>
                  Klinik pilates, Ante Sağlık Merkezi'nde kas iskelet sistemi
                  rahatsızlıklarını tedavi etmek ve genel fiziksel dayanıklılığı
                  artırmayı, günlük yaşam aktivitelerini kolaylaştırmayı
                  hedefleyen terapötik egzersizlerden oluşan bir programdır. Bu
                  yöntem, fizyoterapistler eşliğinde, bireylerin ihtiyaçlarına
                  göre uyarlanarak sağlıklı bir yaşamı destekler.
                </p>
              </AccordionTab>
              <AccordionTab header="Klinik Pilates kimlere uygulanabilir?">
                <p>
                  Klinik pilates, Ante Sağlık Merkezi'nde bel ve boyun fıtığı,
                  postür bozuklukları, eklem problemleri gibi kas iskelet
                  sistemi rahatsızlıkları yaşayan bireylere, ayrıca herhangi bir
                  sağlık sorunu olmayan, hareketsiz kalan kişilere de
                  uygulanabilir. Her yaştan birey için kişiye özel programlar
                  hazırlanarak egzersiz yapılır.
                </p>
              </AccordionTab>
              <AccordionTab header="Klinik Pilatesin etkinliği nedir?">
                <p>
                  Klinik pilates, Ante Sağlık Merkezi'nde uygulandığında, omurga
                  esnekliğini artırarak kas gücünü geliştirir ve postür
                  bozukluklarını düzeltir, böylece bireylerin yaşam kalitesini
                  artırır. Ayrıca, çeşitli kas iskelet sistemi
                  rahatsızlıklarının tedavisinde önemli bir destek sağlar.
                </p>
              </AccordionTab>
              <AccordionTab header="Klinik Pilates nasıl uygulanır?">
                <p>
                  Klinik pilates, Ante Sağlık Merkezi'nde bireysel ihtiyaçlara
                  göre özelleştirilmiş egzersiz programları ile uygulanır; uzman
                  bir fizyoterapist eşliğinde, hastanın durumuna uygun
                  ekipmanlar kullanılarak gerçekleştirilir. Seanslar sırasında,
                  doğru tekniklerle ve hedefe yönelik hareketlerle kas gücü,
                  esneklik ve duruş iyileştirilir.
                </p>
              </AccordionTab>
              <AccordionTab header="Klinik Pilates için seans planlamaları nasıl olmalıdır?">
                <p>
                  Klinik pilates seans planlamaları, Ante Sağlık Merkezi'nde
                  bireyin ihtiyaçlarına ve mevcut sağlık durumuna göre
                  özelleştirilir. Genellikle haftada 2-3 seans önerilir; her
                  seans 45-50 dakika sürer. İlerleme kaydedildikçe,
                  egzersizlerin yoğunluğu ve zorluğu artırılarak, tedavi
                  sürecinin etkili bir şekilde sürdürülmesi sağlanır.
                </p>
              </AccordionTab>
              <AccordionTab header="Klinik Pilatesin ne faydası vardır?">
                <p>
                  Klinik pilates, omurga esnekliğini artırır, kas gücünü ve
                  dayanıklılığı geliştirir, duruş bozukluklarını düzeltir,
                  ağrıları azaltır ve genel yaşam kalitesini yükseltir. Ayrıca,
                  kişiye beden farkındalığı kazandırarak günlük yaşam
                  aktivitelerini daha kolay gerçekleştirmesine yardımcı olur.
                </p>
              </AccordionTab>
              <AccordionTab header="Haftada kaç kez yapılması gerekir?">
                <p>
                  Klinik pilates, haftada en az 2 kez (ideal olarak 3-5 kez)
                  30-45 dakika süreyle yapılması önerilmektedir. Düzenli
                  uygulama, etkilerini artırarak vücut yapısını iyileştirir.
                </p>
              </AccordionTab>
              <AccordionTab header="Klinik Pilates her yaşa önerilir mi?">
                <p>
                  Evet, klinik pilates her yaş grubuna önerilebilir; ancak
                  uygulanmadan önce sağlık durumu ve mevcut hastalıklar dikkate
                  alınarak doktor onayı alınması önemlidir.
                </p>
              </AccordionTab>
              <AccordionTab header="Önermediğiniz bir hasta grubu var mıdır?">
                <p>
                  Kalp ve akciğer hastalığı olanlar, erken doğum riski taşıyan
                  gebeler ve ciddi sağlık problemleri yaşayan kişiler için
                  klinik pilates önerilmez.
                </p>
              </AccordionTab>
              <AccordionTab header="Aletli pilates olarak da bilinen klinik pilateste ne tür aletler kullanılır?">
                <p>
                  Klinik pilateste, "reformer" adı verilen alet en yaygın
                  kullanılanıdır. Bunun yanı sıra, "tower" ve "cadillac" gibi
                  ekipmanlar da bulunur. Bu aletler, farklı direnç seviyeleri ve
                  egzersiz çeşitliliği sunarak kişiye özel programların
                  uygulanmasına yardımcı olur.
                </p>
              </AccordionTab>
              <AccordionTab header="Zayıflama amaçlı yapılır mı?">
                <p>
                  Klinik pilates, zayıflama için doğrudan bir yöntem olmasa da,
                  omurga sağlığını iyileştirerek ve kas gücünü artırarak dolaylı
                  yoldan kilo verme sürecine katkı sağlayabilir. Ancak, kilo
                  vermek için sağlıklı bir diyet programıyla birlikte
                  uygulanması önerilir.
                </p>
              </AccordionTab>
              <AccordionTab header="Ante Sağlıkta Klinik Pilates kimler tarafından uygulanır?">
                <p>
                  Ante Sağlık Merkezi'nde klinik pilates, alanında uzman
                  fizyoterapistler tarafından uygulanmaktadır. Bu
                  profesyoneller, kişiye özel ihtiyaçlara göre programlar
                  geliştirerek, bireylerin sağlık hedeflerine ulaşmalarına
                  yardımcı olmaktadır.
                </p>
              </AccordionTab>
              <AccordionTab header="Klinik Pilates için nasıl randevu alırım?">
                <p>
                  Klinik Pilates randevusu almak için 0530 898 4114 veya 0262
                  503 8575 numaralarını arayabilir ya da Ante Sağlık üzerinden
                  ücretsiz ön değerlendirme randevusu oluşturabilirsiniz.
                </p>
              </AccordionTab>
            </Accordion>
          </div>
          <div>
            <div className="title">
              <h2
                className="ml-auto mr-auto text-center"
                style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                <b>VİDEO İÇERİKLERİ</b>
              </h2>
            </div>
            <br></br>
            <br></br>

            <Row className="justify-content-center">
              <Col md="6">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/UIRJSvf3nS4?si=-YwdXr2Tj5hEmSDZ"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </Col>
            </Row>
          </div>
          <div className="team-2 pb-0 pt-0">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <div className="title">
                    <h2
                      className="title ml-auto mr-auto text-center"
                      style={{
                        color: "#123274",
                        fontFamily: "Benguiat Regular",
                      }}
                    >
                      DİĞER UZMANLIK ALANLARIMIZ
                    </h2>
                  </div>
                </Col>
              </Row>
              <Row data-aos="fade-up" data-aos-duration="1000">
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/bel-fitigi")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/belFitigi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/boyun-agrisi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/boyunAgrisi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/diz-agrisi")
                        }
                      >
                        <img
                          alt="..."
                          style={{ border: "1px solid black" }}
                          className="img img-raised rounded img-hover"
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/dizAgrisi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row data-aos="fade-up" data-aos-duration="1000">
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/omurga-sagligi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/omurgaSagligi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/skolyoz")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/skolyoz.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/sporcu-sagligi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          style={{ border: "1px solid black" }}
                          className="img img-raised rounded img-hover"
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/sporcuSagligi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row
                data-aos="fade-up"
                data-aos-duration="1000"
                style={{ justifyContent: "center" }}
              >
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/sirt-agrisi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/sırtAgrisi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/osteopati")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/osteopati.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/recovery")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/recovery.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row
                data-aos="fade-up"
                data-aos-duration="1000"
                style={{ justifyContent: "center" }}
              >
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/manuel-terapi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/manuelTerapi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/rehabilitasyonlar"
                          )
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/rehabilitasyon.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/gebelik-platesi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/gebelikPlates.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default KlinikPlates;
