import WhiteNavbar from "components/Navbars/WhiteNavbar";
import React, { useEffect } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async";
import { FaCheck } from "react-icons/fa";
import Footer from "components/Footers/Footer";
import { HashLink } from "react-router-hash-link";
import { useSize } from "components/SizeControl/SizeControl";
import { Accordion, AccordionTab } from "primereact/accordion";
import imageHeader from "../assets/ossaPictures/fizyoterapi/alanlar/ManuelTerapiHeader.png";
import PictureHeader from "components/PictureHeader/PictureHeader";
import { useNavigate } from "react-router-dom";

const ManuelTerapi = () => {
  const { under1200, under992 } = useSize();
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
    const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin

    if (targetLink) {
      // Otomatik tıklama
      targetLink.click();
      const imgElement = document.querySelector(".gt-current-lang img");
      const newImgSrc = "https://cdn.gtranslate.net/flags/32/tr.png"; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
      console.log(imgElement);
      imgElement.src = newImgSrc;
      imgElement.width = "32";
      imgElement.height = "32";
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>MANUEL TERAPİ | OSSA CLINIC</title>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="OSSA CLINIC sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
          data-rh="true"
        />
        <link rel="canonical" href="https://www.ossaclinic.com" />
        <meta property="og:title" content="MANUEL TERAPİ | OSSA CLINIC" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="OSSA CLINIC sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
        />
        <meta property="og:image" content="" />
        <meta property="og:url" content="https://www.ossaclinic.com" />
      </Helmet>
      <WhiteNavbar navbarSelection={"about-us"} />
      <PictureHeader imageHeader={imageHeader} />
      <div>
        <Container style={{ fontFamily: "Nucleo Outline" }}>
          <div>
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>MANUEL TERAPİ</b>
            </h2>
          </div>
          <Row>
            <Col className="ml-auto" md="6" style={{ alignContent: "center" }}>
              <img
                alt="..."
                className="img rounded"
                src={require("assets/ossaPictures/fizyoterapi/alanlar/manuelTerapi.png")}
              ></img>
            </Col>
            <Col
              className="mr-auto mt-5 mt-md-3"
              md="6"
              data-aos="zoom-in"
              data-aos-duration="2000"
              style={{ alignSelf: "center" }}
            >
              <h3>
                <strong>
                  Manuel Terapi: Ellerle Uygulanan Fiziksel Tedavi Yöntemi
                </strong>
              </h3>
              <p>
                Manuel terapi, fiziksel tedavi yöntemlerinden biri olup, ellerle
                yapılan çeşitli teknikleri içerir. Genellikle eklem
                hareketliliğini artırmak, kas gerginliğini azaltmak ve ağrıyı
                hafifletmek amacıyla uygulanır.
              </p>

              <br />

              <h3>
                <strong>Uygulama Alanları</strong>
              </h3>
              <p>
                <strong>1. Kas ve İskelet Sistemi Problemleri:</strong> Bel,
                boyun, sırt ağrıları gibi sorunlarda.
              </p>
              <p>
                <strong>2. Eklem Problemleri:</strong> Diz, omuz gibi
                eklemlerdeki kısıtlılıklar.
              </p>
              <p>
                <strong>3. Rehabilitasyon Süreçleri:</strong> Ameliyat sonrası
                iyileşme ve fonksiyon kaybını gidermede.
              </p>

              <br />

              <h3>
                <strong>Ante Sağlık Merkezinde Uygulanan Yöntemler</strong>
              </h3>
              <p>
                <strong>1. Mobilizasyon:</strong> Eklemlerin ve dokuların
                hareketliliğini artırma.
              </p>
              <p>
                <strong>2. Manipülasyon:</strong> Eklemlerin hızlı ve kontrollü
                bir şekilde açılması.
              </p>
              <p>
                <strong>3. Yumuşak Doku Teknikleri:</strong> Kaslar ve bağ
                dokuları üzerindeki baskılarla gevşetme.
              </p>
              <p>
                <strong>4. Egzersiz Rehabilitasyonu:</strong> Güç ve esneklik
                kazandırma amacıyla egzersiz programları.
              </p>

              <br />

              <h3>
                <strong>Manuel Terapi ve Ante Sağlık Merkezi</strong>
              </h3>
              <p>
                Manuel terapi, genellikle <strong>Ante Sağlık</strong>{" "}
                bünyesinde bulunan Uzman Fizyoterapistler tarafından uygulanır
                ve kişiye özel planlar doğrultusunda gerçekleştirilir.{" "}
                <strong>Ante Sağlık Merkezi</strong>'nde uygulanan manuel
                terapi, ilaç veya cerrahi müdahale olmaksızın, elle
                gerçekleştirilen bir terapi yöntemidir. Bu yaklaşım, bel fıtığı,
                boyun fıtığı ve omurilik daralması gibi kas-iskelet sistemi
                sorunlarını hedef alır. Manuel terapi, bası ve germe
                tekniklerini kullanarak, ağrıyı hafifletmeyi ve hareketliliği
                artırmayı amaçlar. Bireysel ihtiyaçlara göre özelleştirilen bu
                yöntem, vücudun doğal iyileşme süreçlerini destekler ve
                hastaların yaşam kalitesini yükseltir.
              </p>
            </Col>
          </Row>
          <div className="title">
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>SIKÇA SORULAN SORULAR</b>
            </h2>
          </div>
          <br></br>
          <div className="card">
            <Accordion>
              <AccordionTab header="Manuel terapi nedir?">
                <p>
                  Ante Sağlık Merkezi'nde manuel terapi, elle uygulanan bir
                  terapi yöntemi olup, kas, sinir ve iskelet sistemi
                  rahatsızlıklarının iyileştirilmesine yardımcı olur. Bu
                  yaklaşım, uzman fizyoterapistler tarafından düzenli seanslar
                  halinde uygulanır ve bireysel ihtiyaçlara göre özelleştirilir.
                  Ağrının hafifletilmesi ve hareketliliğin artırılması amacıyla,
                  bütüncül bir bakış açısıyla sağlık sorunlarını ele alır.
                </p>
              </AccordionTab>
              <AccordionTab header="Manuel terapi nasıl yapılır?">
                <p>
                  Ante Sağlık Merkezi'nde manuel terapi, öncelikle hekim
                  tarafından konulan tanıya dayanarak uzman fizyoterapistler
                  tarafından uygulanır. Uygulama sırasında el teknikleri
                  kullanılarak kas ve eklemlerdeki gerginlikler azaltılır,
                  böylece hastanın hareket kabiliyeti ve yaşam kalitesi
                  artırılır.
                </p>
              </AccordionTab>
              <AccordionTab header="Manuel terapi kimlere uygulanmalıdır?">
                <p>
                  Manuel terapi, eklem sertliği, kas gerginliği ve fıtık gibi
                  şikayetleri olan bireylere uygulanır. Bu yöntem, ağrıyı
                  azaltmak ve hareket kabiliyetini artırmak amacıyla uzman
                  fizyoterapistler tarafından sadece elle gerçekleştirilir.
                </p>
              </AccordionTab>
              <AccordionTab header="Manuel terapi kimlere uygulanmaz?">
                <p>
                  Ante Sağlık Merkezi'nde manuel terapi, ileri derecede
                  osteoporoz, kanama bozuklukları veya ciddi enfeksiyonları olan
                  bireylere uygulanmaz. Uygulama öncesinde hekim değerlendirmesi
                  yapılır ve kişiye özel tedavi planları oluşturulur.
                </p>
              </AccordionTab>
              <AccordionTab header="Manuel terapi ile ameliyatsız fıtık terapisi mümkün müdür?">
                <p>
                  Ante Sağlık Merkezi'nde manuel terapi, bel, boyun ve sırt
                  fıtığı gibi sorunların tedavisinde etkili bir alternatif
                  sunar. Ameliyatsız yöntemler kullanılarak ağrıları azaltmak ve
                  hareket kabiliyetini artırmak mümkündür.
                </p>
              </AccordionTab>
              <AccordionTab header="Manuel terapi kaç seans yapılmalı?">
                <p>
                  Ante Sağlık Merkezi'nde manuel terapi seans sayısı, mevcut
                  probleminizin özelliklerine göre değişiklik gösterir. Uzman
                  fizyoterapistiniz, durumunuzu değerlendirerek size özel seans
                  planını oluşturacaktır.
                </p>
              </AccordionTab>
              <AccordionTab header="Manuel terapinin faydaları nelerdir?">
                <p>
                  Ante Sağlık Merkezi'nde uzman fizyoterapistler tarafından
                  uygulanan manuel terapi, eklemlerdeki blokajları ortadan
                  kaldırarak ağrıda hafifleme ve eklem hareketliliğinde
                  normalleşme sağlar. Ayrıca, doku onarımını hızlandırarak kas
                  gerginliğini azaltır ve hareket terapisi sürecinde ilerlemeyi
                  destekler.
                </p>
              </AccordionTab>
              <AccordionTab header="Manuel terapi kimler tarafından uygulanmalıdır?">
                <p>
                  Ante Sağlık Merkezi'nde manuel terapi, uzman fizyoterapistler
                  tarafından uygulanır. Bu yöntem, kas-iskelet sistemi
                  rahatsızlıklarının doğal yollarla tedavi edilmesini hedefler
                  ve öncelikle tıbbi değerlendirme gerektirir.
                </p>
              </AccordionTab>
              <AccordionTab header="Ante Sağlık'ta manuel terapi nasıl yapılır?">
                <p>
                  Ante Sağlık Merkezi'nde manuel terapi, öncelikle uzman
                  fizyoterapistler tarafından uygulanır. Tedavi süreci,
                  danışanın durumu ve şikayetleri doğrultusunda özel bir
                  değerlendirme ile başlar. Seanslarda, ellerle yapılan
                  teknikler kullanılarak ağrılı bölgelerdeki blokajlar
                  giderilir, eklem hareketliliği artırılır ve doku iyileşmesi
                  desteklenir. Her seans, bireyin ihtiyaçlarına göre
                  özelleştirilir ve düzenli takip ile ilerleme sağlanır.
                </p>
              </AccordionTab>
              <AccordionTab header="Manuel terapi için nasıl randevu alırım?">
                <p>
                  Manuel terapi randevusu için 0530 898 4114 veya 0262 503 8575
                  numaralarını arayabilir ya da Ante Sağlık üzerinden ücretsiz
                  ön değerlendirme randevusu oluşturabilirsiniz.
                </p>
              </AccordionTab>
              <AccordionTab header="Ante Sağlık'ta süreç nasıl ilerliyor?">
                <p>
                  Ante Sağlık Merkezi'nde manuel terapi süreci, öncelikle
                  danışanın tıbbi geçmişi ve şikayetleri doğrultusunda kapsamlı
                  bir değerlendirme ile başlar. Bu aşamadan sonra, uzman
                  fizyoterapist tarafından belirlenen kişiye özel tedavi planı
                  ile seanslar gerçekleştirilir ve tedavi süreci düzenli olarak
                  izlenir.
                </p>
              </AccordionTab>
            </Accordion>
          </div>
          <div>
            <div className="title">
              <h2
                className="ml-auto mr-auto text-center"
                style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                <b>VİDEO İÇERİKLERİ</b>
              </h2>
            </div>
            <br></br>
            <br></br>

            <Row className="justify-content-center">
              <Col md="6">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/gKBXkJcZmJs?si=S07KurpQof0k2QLs"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </Col>
              <Col md="6">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/mU8F_YaE51o?si=SLewpR7Ztk64Sa4r"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </Col>
              
            </Row>
          </div>
          <div className="team-2 pb-0 pt-0">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <div className="title">
                    <h2
                      className="title ml-auto mr-auto text-center"
                      style={{
                        color: "#123274",
                        fontFamily: "Benguiat Regular",
                      }}
                    >
                      DİĞER UZMANLIK ALANLARIMIZ
                    </h2>
                  </div>
                </Col>
              </Row>
              <Row data-aos="fade-up" data-aos-duration="1000">
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/bel-fitigi")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/belFitigi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/boyun-agrisi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/boyunAgrisi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/diz-agrisi")
                        }
                      >
                        <img
                          alt="..."
                          style={{ border: "1px solid black" }}
                          className="img img-raised rounded img-hover"
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/dizAgrisi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row data-aos="fade-up" data-aos-duration="1000">
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/omurga-sagligi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/omurgaSagligi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/skolyoz")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/skolyoz.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/sporcu-sagligi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          style={{ border: "1px solid black" }}
                          className="img img-raised rounded img-hover"
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/sporcuSagligi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row
                data-aos="fade-up"
                data-aos-duration="1000"
                style={{ justifyContent: "center" }}
              >
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/sirt-agrisi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/sırtAgrisi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/osteopati")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/osteopati.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/recovery")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/recovery.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row
                data-aos="fade-up"
                data-aos-duration="1000"
                style={{ justifyContent: "center" }}
              >
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/gebelik-platesi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/gebelikPlates.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/rehabilitasyonlar"
                          )
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/rehabilitasyon.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/klinik-plates"
                          )
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/klinikPlates.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default ManuelTerapi;
