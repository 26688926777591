import WhiteNavbar from "components/Navbars/WhiteNavbar";
import React, { useEffect } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async";
import { FaCheck } from "react-icons/fa";
import Footer from "components/Footers/Footer";
import { HashLink } from "react-router-hash-link";
import { useSize } from "components/SizeControl/SizeControl";
import { Accordion, AccordionTab } from "primereact/accordion";
import imageHeader from "../assets/ossaPictures/fizyoterapi/alanlar/OsteopatiHeader.png";
import PictureHeader from "components/PictureHeader/PictureHeader";
import { useNavigate } from "react-router-dom";

const Osteopati = () => {
  const { under1200, under992 } = useSize();
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
    const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin

    if (targetLink) {
      // Otomatik tıklama
      targetLink.click();
      const imgElement = document.querySelector(".gt-current-lang img");
      const newImgSrc = "https://cdn.gtranslate.net/flags/32/tr.png"; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
      console.log(imgElement);
      imgElement.src = newImgSrc;
      imgElement.width = "32";
      imgElement.height = "32";
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>OSTEOPATİ | OSSA CLINIC</title>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="OSSA CLINIC sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
          data-rh="true"
        />
        <link rel="canonical" href="https://www.ossaclinic.com" />
        <meta property="og:title" content="OSTEOPATİ | OSSA CLINIC" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="OSSA CLINIC sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
        />
        <meta property="og:image" content="" />
        <meta property="og:url" content="https://www.ossaclinic.com" />
      </Helmet>
      <WhiteNavbar navbarSelection={"about-us"} />
      <PictureHeader imageHeader={imageHeader} />
      <div>
        <Container style={{ fontFamily: "Nucleo Outline" }}>
          <div>
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>OSTEOPATİ</b>
            </h2>
          </div>
          <Row>
            <Col className="ml-auto" md="6" style={{ alignContent: "center" }}>
              <img
                alt="..."
                className="img rounded"
                src={require("assets/ossaPictures/fizyoterapi/alanlar/osteopati.png")}
              ></img>
            </Col>
            <Col
              className="mr-auto mt-5 mt-md-3"
              md="6"
              data-aos="zoom-in"
              data-aos-duration="2000"
              style={{ alignSelf: "center" }}
            >
              <h3>
                <strong>Osteopati: Bedenin Bütünsel Yaklaşımı</strong>
              </h3>
              <p>
                Osteopati, bedenin bütünsel bir yaklaşımını benimseyen bir
                sağlık branşıdır. Osteopatlar, vücudun yapısının ve
                fonksiyonlarının birbirini etkilediğini savunurlar. Bu nedenle,
                osteopatik tedavi, kas-iskelet sistemi ile diğer sistemler
                arasındaki ilişkileri göz önünde bulundurarak, el ile
                değerlendirme ve terapi teknikleri kullanır.
              </p>

              <br />

              <h3>
                <strong>Osteopati'nin Kullanım Alanları</strong>
              </h3>
              <p>
                Osteopati, genellikle kas iskelet sistemi problemleri, iç organ
                disfonksiyonları, nörolojik ve diğer sağlık problemleri ile
                ilgili sorunların tedavisinde kullanılır. Osteopatlar, tedavi
                sırasında vücudun kendi kendini iyileştirme yeteneğini teşvik
                etmeyi amaçlar.
              </p>

              <br />

              <h3>
                <strong>Ante Sağlık Merkezi'nde Osteopati</strong>
              </h3>
              <p>
                <strong>Ante Sağlık Merkezi</strong>'nde uygulanan, vücudu
                bütüncül bir şekilde ele alan Osteopati, elle uygulanan
                tekniklerle kas-iskelet sistemi, sinir sistemi ve iç organlar
                arasındaki dengenin sağlanmasına yardımcı olur. Bu yaklaşım,
                ağrıların ve hareket kısıtlılıklarının giderilmesine, vücudun
                kendini iyileştirme potansiyelinin artırılmasına odaklanır.
                Osteopati, cerrahi müdahale gerektirmeden, doğal iyileşmeyi
                destekleyen etkili bir seçenektir.
              </p>
            </Col>
          </Row>
          <div className="title">
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>SIKÇA SORULAN SORULAR</b>
            </h2>
          </div>
          <br></br>
          <div className="card">
            <Accordion>
              <AccordionTab header="Osteopati nedir?">
                <p>
                  Osteopati, vücudun kas-iskelet sistemi, iç organlar, sinir
                  sistemi ve dolaşım sistemi gibi yapılarına odaklanan bir
                  tedavi disiplinidir. Amaç, bu sistemlerin uyumlu bir şekilde
                  çalışmasını sağlamak ve vücudun kendini iyileştirme
                  potansiyelini harekete geçirmektir. Osteopati, hareket ve
                  dolaşımın düzgün işleyişini destekleyerek, fonksiyon
                  bozukluklarını tespit edip tedavi eder.
                </p>
              </AccordionTab>
              <AccordionTab header="Osteopati nasıl uygulanır?">
                <p>
                  Osteopati, ellerle uygulanan bütüncül bir tedavi yöntemidir.
                  Osteopati, öncelikle hastanın değerlendirilmesiyle başlar.
                  Ardından, ellerle kas, eklem, sinir sistemi ve organ tedavisi
                  yapılır. Son olarak, yaşam tarzı ve egzersiz önerileri
                  sunulur.
                </p>
              </AccordionTab>
              <AccordionTab header="Osteopati ve Fizyoterapi arasında farklar var mıdır?">
                <p>
                  Osteopatide sadece ağrı olan bölgeye tedavi uygulanmaz.
                  Osteopati bütüncül bir yaklaşım benimserken, fizik tedavi
                  genellikle belirli teknik ve egzersizlerle odaklanarak belirli
                  sorunları hedefler.
                </p>
              </AccordionTab>
              <AccordionTab header="Kimlere Osteopat denir?">
                <p>
                  Osteopat, fizyoterapist ve doktorların katılabildiği, ortalama
                  5 yıl süren osteopati eğitimini almış kişilere denir. ANTE
                  Sağlık Merkezi'nde çalışan lisanslı osteopatlar, tıbbın tüm
                  imkanlarını kullanarak danışanlara en iyi hizmeti sunmaktadır.
                </p>
              </AccordionTab>
              <AccordionTab header="Osteopati hangi durumlarda uygulanmalıdır?">
                <p>
                  Ante Sağlık Merkezi'nde uygulanan osteopati, ellerle yapılan
                  bütüncül bir tedavi yöntemi olarak birçok hastalıkta
                  kullanılmaktadır. Ağrının kaynağını tespit ederek uygulanan
                  bazı terapi durumları şunlardır:
                </p>
                <ul>
                  <li>Hareket, denge ve koordinasyon bozuklukları</li>
                  <li>Sinirsel gerginlikler, stres ve uyku sorunları</li>
                  <li>Bel, boyun ve sırt ağrıları</li>
                  <li>Migren ve gerilim tipi baş ağrıları</li>
                  <li>Skolyoz, kifoz gibi postür bozuklukları</li>
                  <li>Ameliyat sonrası ağrılar</li>
                  <li>Spor yaralanmaları</li>
                  <li>Sindirim sorunları (kabızlık, hazımsızlık)</li>
                  <li>Bebeklerde yutkunma ve kafa şekil bozuklukları</li>
                  <li>Alerjik ve romatizmal hastalıklar</li>
                  <li>İmmün sistem problemleri</li>
                </ul>
                <p>
                  Osteopati, bu şikayetlerde destekleyici bir yöntem olarak Ante
                  Sağlık Merkezi'nde uygulanmaktadır.
                </p>
              </AccordionTab>
              <AccordionTab header="Osteopatide hangi teknikler kullanılır?">
                <p>
                  Ante Sağlık Merkezi'nde osteopati tedavisinde paryetal,
                  cranial ve visseral osteopati teknikleri kullanılarak
                  kas-iskelet sistemi, sinir sistemi ve iç organlardaki sorunlar
                  ele alınmaktadır.
                </p>
              </AccordionTab>
              <AccordionTab header="Osteopati süreci nasıl ilerler?">
                <p>
                  Ante Sağlık Merkezi'nde osteopati süreci, öncelikle kapsamlı
                  bir değerlendirme ile başlar, ardından özel el teknikleriyle
                  terapi uygulanır ve yaşam tarzı önerileri ile desteklenir.
                </p>
              </AccordionTab>
              <AccordionTab header="Osteopatinin faydaları nelerdir?">
                <p>
                  Ante Sağlık Merkezi'nde uygulanan osteopati, ağrıları azaltma,
                  hareketliliği artırma, stres yönetimi ve sindirim sağlığını
                  destekleme gibi birçok fayda sunar.
                </p>
              </AccordionTab>
              <AccordionTab header="Osteopatinin bir zararı var mıdır?">
                <p>
                  Osteopati genellikle güvenli bir yöntemdir, ancak uygun eğitim
                  almamış kişiler tarafından uygulandığında bazı yan etkiler
                  veya komplikasyonlar ortaya çıkabilir.
                </p>
              </AccordionTab>
              <AccordionTab header="Osteopati için kimler başvurmalıdır?">
                <p>
                  Osteopati, bel, boyun ve sırt ağrısı çekenler, hareket
                  kısıtlılığı yaşayanlar, stres ve gerginlik sorunları olanlar
                  spor yaralanmaları yaşayan bireyler ve diğer tüm ortopedik
                  problem yaşayan bireyler için uygun bir terapi seçeneğidir.
                </p>
              </AccordionTab>
              <AccordionTab header="Osteopati için nasıl randevu alabilirim?">
                <p>
                  Osteopati randevusu için 0530 898 4114 veya 0262 503 8575
                  numaralarını arayabilir ya da Ante Sağlık üzerinden ücretsiz
                  ön değerlendirme randevusu oluşturabilirsiniz.
                </p>
              </AccordionTab>
              <AccordionTab header="Osteopati için ön değerlendirme şart mı?">
                <p>
                  Ante Sağlık Merkezi'nde osteopati için ön değerlendirme,
                  terapi sürecinin etkinliği açısından önemlidir.
                </p>
              </AccordionTab>
              <AccordionTab header="Ante Sağlık Merkezi'nde Osteopati uygulayan uzmanlar var mı?">
                <p>
                  Evet, Ante Sağlık Merkezi'nde osteopati uygulayan uzmanlar
                  bulunmaktadır.
                </p>
              </AccordionTab>
            </Accordion>
          </div>
          <div>
            <div className="title">
              <h2
                className="ml-auto mr-auto text-center"
                style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                <b>VİDEO İÇERİKLERİ</b>
              </h2>
            </div>
            <br></br>
            <br></br>

            <Row className="justify-content-center">
              <Col md="6">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/6rMXhgqhCnM?si=d1CzgBb_tIrkcDw8"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </Col>
              <Col md="6">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/Rcn1tifD9kE?si=V02Vy4YCBAFvsTOB"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </Col>
              <Col md="6">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/0skIuX8v2h0?si=9HUKdiYRWJFjxl_a"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </Col>
              <Col md="6">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/ahsm5MCIwKU?si=Aedmqi5K15nbG9qA"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </Col>
            </Row>
          </div>
          <br></br>
          <div className="team-2 pb-0 pt-0">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <div className="title">
                    <h2
                      className="title ml-auto mr-auto text-center"
                      style={{
                        color: "#123274",
                        fontFamily: "Benguiat Regular",
                      }}
                    >
                      DİĞER UZMANLIK ALANLARIMIZ
                    </h2>
                  </div>
                </Col>
              </Row>
              <Row data-aos="fade-up" data-aos-duration="1000">
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/bel-fitigi")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/belFitigi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/boyun-agrisi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/boyunAgrisi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/diz-agrisi")
                        }
                      >
                        <img
                          alt="..."
                          style={{ border: "1px solid black" }}
                          className="img img-raised rounded img-hover"
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/dizAgrisi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row data-aos="fade-up" data-aos-duration="1000">
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/omurga-sagligi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/omurgaSagligi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/skolyoz")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/skolyoz.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/sporcu-sagligi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          style={{ border: "1px solid black" }}
                          className="img img-raised rounded img-hover"
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/sporcuSagligi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row
                data-aos="fade-up"
                data-aos-duration="1000"
                style={{ justifyContent: "center" }}
              >
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/sirt-agrisi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/sırtAgrisi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/gebelik-platesi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/gebelikPlates.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/recovery")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/recovery.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row
                data-aos="fade-up"
                data-aos-duration="1000"
                style={{ justifyContent: "center" }}
              >
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/manuel-terapi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/manuelTerapi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/rehabilitasyonlar"
                          )
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/rehabilitasyon.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/klinik-plates"
                          )
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/klinikPlates.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default Osteopati;
