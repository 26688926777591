import WhiteNavbar from "components/Navbars/WhiteNavbar";
import React, { useEffect } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async";
import { FaCheck } from "react-icons/fa";
import Footer from "components/Footers/Footer";
import { HashLink } from "react-router-hash-link";
import { useSize } from "components/SizeControl/SizeControl";
import { Accordion, AccordionTab } from "primereact/accordion";
import imageHeader from "../assets/ossaPictures/fizyoterapi/alanlar/BoyunAgrisiHeader.png";
import PictureHeader from "components/PictureHeader/PictureHeader";
import { useNavigate } from "react-router-dom";

const BoyunAgrisi = () => {
  const { under1200, under992 } = useSize();
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
    const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin

    if (targetLink) {
      // Otomatik tıklama
      targetLink.click();
      const imgElement = document.querySelector(".gt-current-lang img");
      const newImgSrc = "https://cdn.gtranslate.net/flags/32/tr.png"; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
      console.log(imgElement);
      imgElement.src = newImgSrc;
      imgElement.width = "32";
      imgElement.height = "32";
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>BOYUN AĞRISI | OSSA CLINIC</title>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="OSSA CLINIC sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
          data-rh="true"
        />
        <link
          rel="canonical"
          href="https://www.ossaclinic.com"
        />
        <meta property="og:title" content="BOYUN AĞRISI | OSSA CLINIC" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="OSSA CLINIC sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
        />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content="https://www.ossaclinic.com"
        />
      </Helmet>
      <WhiteNavbar navbarSelection={"about-us"} />
      <PictureHeader imageHeader={imageHeader} />
      <div>
        <Container style={{ fontFamily: "Nucleo Outline" }}>
          <div>
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>BOYUN AĞRISI</b>
            </h2>
          </div>
          <Row>
            <Col className="ml-auto" md="6" style={{ alignContent: "center" }}>
              <img
                alt="..."
                className="img rounded"
                src={require("assets/ossaPictures/fizyoterapi/alanlar/boyunAgrisi.png")}
              ></img>
            </Col>
            <Col
              className="mr-auto mt-5 mt-md-3"
              md="6"
              data-aos="zoom-in"
              data-aos-duration="2000"
              style={{ alignSelf: "center" }}
            >
              <p>
                Boyun ağrısı, birçok insanın yaşadığı yaygın bir sorundur.
                Yanlış duruş pozisyonu, hareketsiz yaşam tarzı ve stres gibi
                faktörler boyun ağrısı problemlerine neden olabilir.
                <strong>Ossa Fizik Tedavi Merkezi</strong>, uzman
                fizyoterapistleri ve son teknolojik cihazlarıyla müşterilerine
                boyun ağrısı tedavisi konusunda etkili çözümler sunmaktadır.
              </p>

              <p>
                <strong>
                  Ossa Fizik Tedavi Merkezi'nde boyun ağrısı tedavisi için
                  uygulanan yöntemler
                </strong>{" "}
                arasında manuel terapi, akupunktur, masaj, lazer terapi ve
                egzersizler yer almaktadır. Uzman fizyoterapistler, hastaların
                bireysel ihtiyaçlarına göre özel tedavi planları hazırlayarak
                boyun ağrısını azaltmak ve hareket kabiliyetini artırmak için
                çalışır.
              </p>

              <p>
                <strong>
                  Ossa Fizik Tedavi Merkezi'nin boyun ağrısı tedavisi
                </strong>
                , sadece semptomları hafifletmekle kalmaz, aynı zamanda sorunun
                kökenine inerek nedenlerini de ortadan kaldırmaya çalışır. Bu
                sayede, hastaların boyun ağrısı problemleri uzun vadede
                tekrarlamaz. Ayrıca, merkezin uzman diyetisyenleri de
                müşterilere sağlıklı beslenme alışkanlıkları kazandırmak ve
                boyun ağrısı sorunlarını önlemek için özel beslenme programları
                hazırlamaktadır.
              </p>

              <p>
                Sonuç olarak, <strong>Ossa Fizik Tedavi Merkezi</strong>, boyun
                ağrısı tedavisi konusunda uzman kadrosu ve son teknolojik
                cihazlarıyla müşterilerine en iyi hizmeti sunmaktadır.
              </p>
            </Col>
          </Row>
          <div className="title">
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>SIKÇA SORULAN SORULAR</b>
            </h2>
          </div>
          <br></br>
          <div className="card">
            <Accordion activeIndex={0}>
              <AccordionTab header="Boyun ağrısı neden olur?">
                <p className="panel-txt">
                  Boyun ağrısının nedeni farklı faktörlere bağlı olabilir,
                  örneğin yanlış duruş, travma, kas spazmları, diyabet,
                  osteoartrit ve disk kayması gibi faktörler olabilir.
                </p>
              </AccordionTab>
              <AccordionTab header="Boyun ağrısı nasıl tedavi edilir?">
                <p className="panel-txt">
                  Boyun ağrısı tedavisi çeşitli yöntemlerle yapılabilir, bunlar
                  arasında egzersizler, masaj, akupunktur, ilaç tedavisi ve
                  fizyoterapi yer alır.
                </p>
              </AccordionTab>
              <AccordionTab header="Boyun ağrısını ne kadar süreyle hissedebilirim?">
                <p className="panel-txt">
                  Boyun ağrısı genellikle birkaç gün ile birkaç hafta arasında
                  sürer, ancak bazı kişilerde daha uzun sürebilir.
                </p>
              </AccordionTab>
              <AccordionTab header="Hangi durumlarda doktora gitmeliyim?">
                <p className="panel-txt">
                  Eğer boyun ağrınız şiddetliyse veya altta yatan bir hastalığı
                  düşündürüyorsa (örneğin kaza sonrası veya büyük bir yaralanma
                  sonrası), bir doktora danışmanız önerilir.
                </p>
              </AccordionTab>
              <AccordionTab header="Boyun ağrısı nasıl önlenir?">
                <p className="panel-txt">
                  Boyun ağrısını önlemek için doğru postürü koruyun, düzenli
                  olarak egzersiz yapın, aşırı stresli durumlardan kaçının ve
                  ergonomik çalışma koşulları sağlayın.
                </p>
              </AccordionTab>
              <AccordionTab header="Fizik tedavi boyun ağrısını nasıl tedavi eder?">
                <p className="panel-txt">
                  Fizik tedavi, boyun ağrısını tedavi etmek için egzersizler,
                  masaj, manuel terapi ve diğer teknikleri de içeren bir dizi
                  prosedürü içerir.
                </p>
              </AccordionTab>
              <AccordionTab header="Boyun ağrısı için hangi egzersizler önerilir?">
                <p className="panel-txt">
                  Boyun ağrısını hafifletmek için egzersiz yapmak önemlidir.
                  Bunlar arasında boyun esnetme hareketleri, omuz kaldırma ve
                  çevirme hareketleri, baş hareketleri ve egzersiz topları ile
                  yapılan egzersizler yer alabilir.
                </p>
              </AccordionTab>
              <AccordionTab header="Ossa Fizik Tedavi merkezi boyun ağrısı tedavisi için ne gibi hizmetler sunuyor?">
                <p className="panel-txt">
                  Ossa Fizik Tedavi merkezi, boyun ağrısı tedavisi için farklı
                  yöntemler sunar, bunlar arasında manuel terapi, egzersiz
                  programları, masaj terapisi, elektroterapi, lazer tedavisi ve
                  daha fazlası yer alır.
                </p>
              </AccordionTab>
              <AccordionTab header="Ossa Fizik Tedavi merkezine nasıl ulaşabilirim?">
                <p className="panel-txt">
                  Ossa Fizik Tedavi merkezine telefon veya internet üzerinden
                  randevu alarak ulaşabilirsiniz.
                </p>
              </AccordionTab>
              <AccordionTab header="Ossa Fizik Tedavi merkezi nerede bulunuyor?">
                <p className="panel-txt">
                  Adresi: Yahya Kaptan Mh. Şehit Ali İhsan Çakmak Sok No:10 Kat:
                  2, 41040 İzmit/Kocaeli
                </p>
              </AccordionTab>
            </Accordion>
          </div>
          <div>
            <div className="title">
              <h2
                className="ml-auto mr-auto text-center"
                style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                <b>VİDEO İÇERİKLERİ</b>
              </h2>
            </div>
            <br></br>
            <br></br>

            <Row className="justify-content-center">
              <Col md="6">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/Rcn1tifD9kE?si=V02Vy4YCBAFvsTOB"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </Col>
              <Col md="6">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/mU8F_YaE51o?si=JFnPZ6YJQwG1CMfi"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </Col>
            </Row>
          </div>
          <div className="team-2 pb-0 pt-0">
          <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <div className="title">
                    <h2
                      className="title ml-auto mr-auto text-center"
                      style={{
                        color: "#123274",
                        fontFamily: "Benguiat Regular",
                      }}
                    >
                      DİĞER UZMANLIK ALANLARIMIZ
                    </h2>
                  </div>
                </Col>
              </Row>
              <Row data-aos="fade-up" data-aos-duration="1000">
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/bel-fitigi")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/belFitigi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/gebelik-platesi")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/gebelikPlates.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/diz-agrisi")
                        }
                      >
                        <img
                          alt="..."
                          style={{ border: "1px solid black" }}
                          className="img img-raised rounded img-hover"
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/dizAgrisi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row data-aos="fade-up" data-aos-duration="1000">
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/omurga-sagligi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/omurgaSagligi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/skolyoz")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/skolyoz.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/sporcu-sagligi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          style={{ border: "1px solid black" }}
                          className="img img-raised rounded img-hover"
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/sporcuSagligi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row
                data-aos="fade-up"
                data-aos-duration="1000"
                style={{ justifyContent: "center" }}
              >
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/sirt-agrisi")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/sırtAgrisi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/osteopati")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/osteopati.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/recovery")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/recovery.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                
              </Row>
              <Row
                data-aos="fade-up"
                data-aos-duration="1000"
                style={{ justifyContent: "center" }}
              >
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/manuel-terapi")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/manuelTerapi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/rehabilitasyonlar")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/rehabilitasyon.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/klinik-plates")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/klinikPlates.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
             
            </Container>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default BoyunAgrisi;
