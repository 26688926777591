import WhiteNavbar from "components/Navbars/WhiteNavbar";
import React, { useEffect } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async";
import { FaCheck } from "react-icons/fa";
import Footer from "components/Footers/Footer";
import { HashLink } from "react-router-hash-link";
import { useSize } from "components/SizeControl/SizeControl";
import { Accordion, AccordionTab } from "primereact/accordion";
import imageHeader from "../assets/ossaPictures/fizyoterapi/alanlar/RecoveryHeader.png";
import PictureHeader from "components/PictureHeader/PictureHeader";
import { useNavigate } from "react-router-dom";

const Recovery = () => {
  const { under1200, under992 } = useSize();
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
    const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin

    if (targetLink) {
      // Otomatik tıklama
      targetLink.click();
      const imgElement = document.querySelector(".gt-current-lang img");
      const newImgSrc = "https://cdn.gtranslate.net/flags/32/tr.png"; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
      console.log(imgElement);
      imgElement.src = newImgSrc;
      imgElement.width = "32";
      imgElement.height = "32";
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>RECOVERY | OSSA CLINIC</title>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="OSSA CLINIC sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
          data-rh="true"
        />
        <link
          rel="canonical"
          href="https://www.ossaclinic.com"
        />
        <meta property="og:title" content="RECOVERY | OSSA CLINIC" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="OSSA CLINIC sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
        />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content="https://www.ossaclinic.com"
        />
      </Helmet>
      <WhiteNavbar navbarSelection={"about-us"} />
      <PictureHeader imageHeader={imageHeader} />
      <div>
        <Container style={{ fontFamily: "Nucleo Outline" }}>
          <div>
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>RECOVERY</b>
            </h2>
          </div>
          <Row>
            <Col className="ml-auto" md="6" style={{ alignContent: "center" }}>
              <img
                alt="..."
                className="img rounded"
                src={require("assets/ossaPictures/fizyoterapi/alanlar/recovery.png")}
              ></img>
            </Col>
            <Col
              className="mr-auto mt-5 mt-md-3"
              md="6"
              data-aos="zoom-in"
              data-aos-duration="2000"
              style={{ alignSelf: "center" }}
            >
              <h3><strong>Recovery (İyileşme): Fiziksel ve Mental Sağlık Süreçleri</strong></h3>
<p>Recovery (İyileşme), fiziksel ve mental sağlık açısından vücut ve zihin üzerinde iyileşme süreçlerini ifade eder. Sağlıklı bireylerde ve genellikle sporcular, yaralanma sonrası rehabilitasyon görenler veya kronik ağrı çeken bireyler için önemlidir. Recovery süreçleri, vücudun kendini yenilemesi, kasların onarılması ve genel performansın artırılması amacıyla uygulanır.</p>

<br/>

<h3><strong>Recovery Uygulama Alanları</strong></h3>
<p><strong>1. Spor Yaralanmaları:</strong> Kas ve eklem yaralanmaları sonrası iyileşme.</p>
<p><strong>2. Kronik Ağrı Yönetimi:</strong> Fibromyalji, bel ağrısı gibi durumlarda.</p>
<p><strong>3. Post-Operatif Süreçler:</strong> Ameliyat sonrası iyileşmeyi desteklemek.</p>
<p><strong>4. Aşırı Yorgunluk ve Stres:</strong> Fiziksel veya zihinsel aşırı yorgunluk durumlarında yapılmaktadır.</p>

<br/>

<h3><strong>Ante Sağlık Merkezinde Yapılan Recovery Uygulamaları</strong></h3>
<p><strong>1. Manuel Terapi:</strong> Kas gerginliğini azaltma ve hareketliliği artırma.</p>
<p><strong>2. Fizyoterapi Seansları:</strong> Özelleştirilmiş egzersiz programları ile destek.</p>
<p><strong>3. Masaj Terapisi:</strong> Kasları gevşetme ve kan dolaşımını artırma.</p>
<p><strong>4. Rehabilitasyon Programları:</strong> Yaralanma sonrası fonksiyonel iyileşme.</p>
<p><strong>5. Backup Teknolojisi:</strong> Kas gevşetme ve sinir sistemi regülasyonu.</p>
<p><strong>6. Pnömatik Kompresyon:</strong> Dolaşımın artırılması.</p>

<br/>

<h3><strong>Özel Terapiler ve Yaklaşımlar</strong></h3>
<p>Özel tasarlanmış terapiler, düzenli takip, yenilikçi teknikler ve profesyonel yaklaşımlarla <strong>Ante Sağlık Merkezi</strong>, spor yaralanmalarından kronik ağrıya kadar geniş bir yelpazede etkili recovery çözümleri sağlıyor.</p>

<br/>

<h3><strong>Yaşam Kalitesini Artırma ve Sağlık Sürdürme</strong></h3>
<p><strong>Ante Sağlık Merkezi</strong>, 7’den 70’e her yaştan bireyin yaşam kalitesini artırmayı ve sağlığını sürdürebilmesini hedefleyen bir yaklaşımla hizmet vermektedir. Spor veya aktivite kaynaklı yaralanmaların önlenmesi ve mevcut yaralanmaların iyileşme sürecinin hızlandırılması amacıyla özel rehabilitasyon programları sunulmaktadır. Uzman fizyoterapistlerimiz, bireylerin ihtiyaçlarına uygun değerlendirmeler yaparak kişiye özel tedavi planları oluşturur. Yoğun antrenman sonrası kas ağrısı ve yorgunluk gibi durumlarda, yenilenme sürecine yönelik bilimsel yöntemlerle uygulamalar gerçekleştirilir. Böylece, hem günlük yaşamda hem de spor aktivitelerinde sağlıklı ve ağrısız bir dönüş sağlanır. <strong>Ante Sağlık Merkezi</strong> olarak, fizyoterapi yaklaşımlarımız her zaman bireysel değerlendirmelere dayanmaktadır; bu nedenle, hayatınızın her döneminde fizyoterapiden faydalanabilirsiniz.</p>

            </Col>
          </Row>
          <div className="title">
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>SIKÇA SORULAN SORULAR</b>
            </h2>
          </div>
          <br></br>
          <div className="card">
          <Accordion>
  <AccordionTab header="Recovery nedir?">
    <p>
      Ante Sağlık Merkezi'nde uygulanan recovery, vücudu iyileştirmeyi ve rehabilitasyon sürecini hızlandırmayı hedefleyen özel bir tedavi yöntemidir. Bu yaklaşım, travma sonrası iyileşme ve spor yaralanmaları gibi durumlarda kişiye özel terapi planları ile etkin sonuçlar sunar.
    </p>
  </AccordionTab>
  <AccordionTab header="Recovery hangi durumlarda yapılır?">
    <p>
      Recovery, spor yaralanmaları, cerrahi sonrası rehabilitasyon ve yoğun antrenman dönemlerinde gereklidir. Ayrıca, kronik ağrı yönetimi ve genel fiziksel ve zihinsel iyilik hali sağlamak amacıyla da uygulanmaktadır.
    </p>
  </AccordionTab>
  <AccordionTab header="Sadece sağlıklı bireylerde mi Recovery uygulanmalıdır?">
    <p>
      Hayır, recovery yalnızca sağlıklı bireylere uygulanmaz; spor yaralanmaları, cerrahi sonrası iyileşme veya kronik ağrı gibi durumları olan kişilerde de etkili bir şekilde kullanılabilir. Ante Sağlık Merkezi, her bireyin ihtiyaçlarına uygun recovery programları sunarak iyileşmeyi hızlandırmayı hedefler.
    </p>
  </AccordionTab>
  <AccordionTab header="Ne sıklıkla Recovery seanslarına devam etmeliyim?">
    <p>
      Recovery seanslarının sıklığı, bireyin ihtiyaçlarına ve iyileşme sürecine bağlı olarak değişir. Ante Sağlık Merkezi'nde uzman fizyoterapistiniz, durumu değerlendirerek size özel bir seans programı oluşturur.
    </p>
  </AccordionTab>
  <AccordionTab header="Ante Sağlık bünyesinde Recovery uygulanmakta mıdır?">
    <p>
      Evet, Ante Sağlık Merkezi bünyesinde recovery uygulamaları yapılmaktadır. Uzman fizyoterapistlerimiz, bireylerin ihtiyaçlarına uygun özel recovery programları ile iyileşme sürecini desteklemektedir.
    </p>
  </AccordionTab>
  <AccordionTab header="Uzmanlar tarafından mı uygulanmaktadır?">
    <p>
      Evet, recovery uygulamaları Ante Sağlık Merkezi'nde uzman fizyoterapistler tarafından gerçekleştirilmektedir. Profesyonel ekip, bireylerin özel ihtiyaçlarına uygun tedavi planları oluşturarak süreci yönetmektedir.
    </p>
  </AccordionTab>
  <AccordionTab header="Ante Sağlık merkezi nerede bulunmaktadır?">
    <p>
      Ante Sağlık Merkezi, İzmit/Kocaeli'nin Yahya Kaptan Mahallesi'nde, Şehit Ali İhsan Çakmak Sokak No:10, Kat:2 adresinde bulunmaktadır. Modern sağlık hizmetleri sunan merkezimiz, herkesin erişimine açıktır.
    </p>
  </AccordionTab>
  <AccordionTab header="Daha önce Ante Sağlık'ta Recovery hizmeti alan kişiler var mıdır?">
    <p>
      Evet, Ante Sağlık Merkezi'nde daha önce recovery hizmeti alan birçok kişi bulunmaktadır. Bu hizmetten faydalanan bireyler, özel tedavi yöntemleriyle daha hızlı bir iyileşme süreci yaşamışlardır.
    </p>
  </AccordionTab>
  <AccordionTab header="Recovery seanslarının yaşamımıza nasıl etkisi olur?">
    <p>
      Recovery seansları, yaşam kalitenizi artırarak fiziksel ve zihinsel dengeyi sağlamanıza yardımcı olur. Bu seanslar, ağrıları azaltır, kas gerginliğini giderir ve genel performansı artırarak günlük aktivitelerde daha enerjik hissetmenizi sağlar.
    </p>
  </AccordionTab>
</Accordion>

          </div>
          <div className="team-2 pb-0 pt-0">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <div className="title">
                    <h2
                      className="title ml-auto mr-auto text-center"
                      style={{
                        color: "#123274",
                        fontFamily: "Benguiat Regular",
                      }}
                    >
                      DİĞER UZMANLIK ALANLARIMIZ
                    </h2>
                  </div>
                </Col>
              </Row>
              <Row data-aos="fade-up" data-aos-duration="1000">
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/bel-fitigi")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/belFitigi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/boyun-agrisi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/boyunAgrisi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/diz-agrisi")
                        }
                      >
                        <img
                          alt="..."
                          style={{ border: "1px solid black" }}
                          className="img img-raised rounded img-hover"
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/dizAgrisi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row data-aos="fade-up" data-aos-duration="1000">
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/omurga-sagligi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/omurgaSagligi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/skolyoz")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/skolyoz.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/sporcu-sagligi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          style={{ border: "1px solid black" }}
                          className="img img-raised rounded img-hover"
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/sporcuSagligi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row
                data-aos="fade-up"
                data-aos-duration="1000"
                style={{ justifyContent: "center" }}
              >
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/sirt-agrisi")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/sırtAgrisi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/osteopati")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/osteopati.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/gebelik-platesi")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/gebelikPlates.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                
              </Row>
              <Row
                data-aos="fade-up"
                data-aos-duration="1000"
                style={{ justifyContent: "center" }}
              >
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/manuel-terapi")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/manuelTerapi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/rehabilitasyonlar")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/rehabilitasyon.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/klinik-plates")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/klinikPlates.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
              
            </Container>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default Recovery;
