import React from "react";
import video from "../../assets/ossaVideos/homePageVideo.webm"
import videoMobile from "../../assets/ossaVideos/homePageVideoMobile.webm"
import styles from "./Headers.module.scss"
import { useSize } from "components/SizeControl/SizeControl";
// import ReactPlayer from "react-player";

const Headers = ({ videoEnd, setVideoEnd }) => {

  const {under427} = useSize()

  //  const videoEndFunc = () => {
  //   setVideoEnd(true)
  //   sessionStorage.setItem('videoEnd', 'true');
  //  }

  return (
    <>
      <div className="cd-section" id="headers" style={{position:"relative"}}>
        <h1 style={{ display: "none" }}>OSSA CLINIC</h1>
        <div className={`header-3 ${styles.videoContainer}`}>
          {/* <ReactPlayer
        url={video}
        width="100%"
        height="100%"
        muted
        playing="true"
        preload="auto"
        onEnded={videoEndFunc}
        /> */}
          <video
            playsInline
            data-autoPlay=''
            src={under427 ? videoMobile : video}
            muted
            autoPlay={"autoplay"}
            preload="auto"
            width="100%"
            height="100%"
            loop
          // onEnded={videoEndFunc}
          >
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          
        </div>
        <div
          className="page-header-image"
          style={{

            background: `linear-gradient(to bottom, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.2) 20%, rgba(0,0,0,0) 100%)`,
            position: "absolute",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            top: "0",
            width: "100%",
            height: "30%",
            zIndex: "999",
            overflow: "hidden"
          }}
        ></div>
      </div>

    </>
  )
}

export default Headers