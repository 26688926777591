import WhiteNavbar from "components/Navbars/WhiteNavbar";
import React, { useEffect } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async";
import { FaCheck } from "react-icons/fa";
import Footer from "components/Footers/Footer";
import { HashLink } from "react-router-hash-link";
import { useSize } from "components/SizeControl/SizeControl";
import { Accordion, AccordionTab } from "primereact/accordion";
import imageHeader from "../assets/ossaPictures/fizyoterapi/alanlar/BelFitigiHeader.png";
import PictureHeader from "components/PictureHeader/PictureHeader";
import { useNavigate } from "react-router-dom";

const BelFitigi = () => {
  const { under1200, under992 } = useSize();
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
    const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin

    if (targetLink) {
      // Otomatik tıklama
      targetLink.click();
      const imgElement = document.querySelector(".gt-current-lang img");
      const newImgSrc = "https://cdn.gtranslate.net/flags/32/tr.png"; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
      console.log(imgElement);
      imgElement.src = newImgSrc;
      imgElement.width = "32";
      imgElement.height = "32";
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>BEL FITIĞI | OSSA CLINIC</title>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="OSSA CLINIC"
          data-rh="true"
        />
        <link
          rel="canonical"
          href="https://www.ossaclinic.com/"
        />
        <meta property="og:title" content="BEL FITIĞI | OSSA CLINIC" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="OSSA CLINIC"
        />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content="https://www.ossaclinic.com/"
        />
      </Helmet>
      <WhiteNavbar navbarSelection={"about-us"} />
      <PictureHeader imageHeader={imageHeader} />
      <div>
        <Container style={{ fontFamily: "Nucleo Outline" }}>
          <div>
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>BEL FITIĞI</b>
            </h2>
          </div>
          <Row>
            <Col className="ml-auto" md="6" style={{ alignContent: "center" }}>
              <img
                alt="..."
                className="img rounded"
                src={require("assets/ossaPictures/fizyoterapi/alanlar/belFitigi.png")}
              ></img>
            </Col>
            <Col
              className="mr-auto mt-5 mt-md-3"
              md="6"
              data-aos="zoom-in"
              data-aos-duration="2000"
              style={{ alignSelf: "center" }}
            >
              <p>
                Bel fıtığı, özellikle yaşlı insanlar arasında yaygın bir
                sorundur. Bel fıtığı, bel bölgesinde oluşan ağrı, uyuşma ve
                güçsüzlük gibi semptomlarla kendini gösterir.{" "}
                <b>Ossa Fizik Tedavi Merkezi</b>, uzman fizyoterapistleri ve son
                teknolojik cihazlarıyla müşterilerine bel fıtığı tedavisi
                konusunda etkili çözümler sunmaktadır.
              </p>
              <p>
                <b>Ossa Fizik Tedavi Merkezi</b>'nde bel fıtığı tedavisinde
                kullanılan yöntemler arasında manuel terapi, egzersizler,
                akupunktur, masaj ve lazer terapi yer almaktadır. Uzman
                fizyoterapistler, hastaların bireysel ihtiyaçlarına göre özel
                tedavi planları hazırlayarak bel fıtığının semptomlarını
                azaltmak ve hareket kabiliyetini artırmak için çalışır.
              </p>
              <p>
                <b>Ossa Fizik Tedavi Merkezi</b>'nin bel fıtığı tedavisi, sadece
                semptomları hafifletmekle kalmaz, aynı zamanda sorunun kökenine
                inerek nedenlerini de ortadan kaldırmaya çalışır. Bu sayede,
                hastaların bel fıtığı problemleri uzun vadede tekrarlamaz.
                Ayrıca, merkezin uzman diyetisyenleri de müşterilere sağlıklı
                beslenme alışkanlıkları kazandırmak ve bel fıtığı sorunlarını
                önlemek için özel beslenme programları hazırlamaktadır. Sonuç
                olarak, <b>Ossa Fizik Tedavi Merkezi</b>, bel fıtığı tedavisi
                konusunda uzman kadrosu ve son teknolojik cihazlarıyla
                müşterilerine en iyi hizmeti sunmaktadır.
              </p>
            </Col>
          </Row>
          <div className="title">
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>SIKÇA SORULAN SORULAR</b>
            </h2>
          </div>
          <br></br>
          <div className="card">
          <Accordion>
                <AccordionTab header="Bel fıtığı nedir?">
                    <p>
                        Bel fıtığı, omurganın alt bölgesinde yer alan bel bölgesindeki disklerin hasar görmesi sonucu ortaya çıkan bir rahatsızlıktır. Bu hasar, disk içerisindeki jelin sert dış tabakasının zedelenmesi ve yer değiştirmesiyle oluşur.
                    </p>
                </AccordionTab>
                <AccordionTab header="Bel fıtığına neden olan faktörler nelerdir?">
                    <p>
                        Bel fıtığına neden olan faktörler arasında aşırı kilo, kötü duruş alışkanlıkları, yetersiz egzersiz, uzun süreli oturma veya ayakta kalma gibi etkenler sayılabilir.
                    </p>
                </AccordionTab>
                <AccordionTab header="Bel fıtığı belirtileri nelerdir?">
                    <p>
                        Bel fıtığı belirtileri arasında sırt ve bel ağrısı, bacakta uyuşma, karıncalanma veya güçsüzlük hissi, idrar veya bağırsak kontrolünde zorluk, cinsel fonksiyonlarda sorunlar gibi problemler yer alır.
                    </p>
                </AccordionTab>
                <AccordionTab header="Bel fıtığının tanısı nasıl konulur?">
                    <p>
                        Bel fıtığının tanısı için doktorlar genellikle fizik muayene, manyetik rezonans görüntüleme (MRI) veya bilgisayarlı tomografi (BT) taraması yaparlar.
                    </p>
                </AccordionTab>
                <AccordionTab header="Bel fıtığı tedavisi nasıl yapılır?">
                    <p>
                        Bel fıtığı tedavisi, hastanın belirtilerinin şiddetine, sağlık durumuna ve diğer faktörlere göre değişebilir. Tedavide kullanılan yöntemler arasında ilaç, fizik tedavi, epidural enjeksiyon veya ameliyat gibi seçenekler bulunur.
                    </p>
                </AccordionTab>
                <AccordionTab header="Bel fıtığı ameliyatı riskli midir?">
                    <p>
                        Bel fıtığı ameliyatı, diğer cerrahi işlemler gibi bazı riskleri barındırabilir. Ancak, modern teknolojiler sayesinde bu risklerin büyük ölçüde azaldığı ve başarı oranının yüksek olduğu bilinmektedir.
                    </p>
                </AccordionTab>
                <AccordionTab header="Bel fıtığının önlenmesi mümkün müdür?">
                    <p>
                        Bel fıtığının tamamen önlenmesi mümkün olmasa da, düzenli egzersiz yapmak, doğru duruş alışkanlıkları edinmek, aşırı kilodan kaçınmak ve stresi azaltmak gibi önleyici adımlar alarak riski azaltmak mümkündür.
                    </p>
                </AccordionTab>
                <AccordionTab header="Ossa Fizik Tedavi Merkezi'nde bel fıtığı tedavisi nasıl yapılır?">
                    <p>
                        Ossa Fizik Tedavi Merkezi uzmanları, hastaların bel fıtığı problemine özel olarak tasarlanmış bir dizi tedavi yöntemi sunmaktadır. Bu yöntemler arasında egzersiz programları, manuel terapi, omurga çekme tedavisi, masaj terapisi ve akupunktur yer alır.
                    </p>
                </AccordionTab>
                <AccordionTab header="Ossa Fizik Tedavi Merkezi'ndeki uzmanlar kimlerdir?">
                    <p>
                        Ossa Fizik Tedavi Merkezi'ndeki uzmanlar, deneyimli fizyoterapistler ve rehabilitasyon uzmanlarıdır. Bu eğitimli profesyoneller, bel fıtığı gibi omurga rahatsızlıkları konusunda uzmanlaşmışlardır.
                    </p>
                </AccordionTab>
                <AccordionTab header="Ossa Fizik Tedavi Merkezi'ne nasıl ulaşabilirim?">
                    <p>
                        Ossa Fizik Tedavi Merkezi, Kocaeli'nin İzmit ilçesinde yer almaktadır. Tam Adresimiz : Yahya Kaptan Mh. Şehit Ali İhsan Çakmak Sok No:10 Kat: 2, 41040 İzmit/Kocaeli ayrıca iletişime geçmek için telefon veya e-posta yoluyla da ulaşabilirsiniz.
                    </p>
                </AccordionTab>
            </Accordion>
          </div>
          <div>
            <div className="title">
              <h2
                className="ml-auto mr-auto text-center"
                style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                <b>VİDEO İÇERİKLERİ</b>
              </h2>
            </div>
            <br></br>
            <br></br>

            <Row className="justify-content-center">
              <Col md="6">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/0skIuX8v2h0?si=z6JgYFMDU7DqGUA0"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </Col>
              <Col md="6">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/ahsm5MCIwKU?si=nBVyoPcqFtJjPGTI"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </Col>
            </Row>
          </div>
          <div className="team-2 pb-0 pt-0">
          <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <div className="title">
                    <h2
                      className="title ml-auto mr-auto text-center"
                      style={{
                        color: "#123274",
                        fontFamily: "Benguiat Regular",
                      }}
                    >
                      DİĞER UZMANLIK ALANLARIMIZ
                    </h2>
                  </div>
                </Col>
              </Row>
              <Row data-aos="fade-up" data-aos-duration="1000">
              <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/gebelik-platesi")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/gebelikPlates.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/boyun-agrisi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/boyunAgrisi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/diz-agrisi")
                        }
                      >
                        <img
                          alt="..."
                          style={{ border: "1px solid black" }}
                          className="img img-raised rounded img-hover"
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/dizAgrisi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row data-aos="fade-up" data-aos-duration="1000">
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/omurga-sagligi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/omurgaSagligi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/skolyoz")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/skolyoz.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/sporcu-sagligi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          style={{ border: "1px solid black" }}
                          className="img img-raised rounded img-hover"
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/sporcuSagligi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row
                data-aos="fade-up"
                data-aos-duration="1000"
                style={{ justifyContent: "center" }}
              >
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/sirt-agrisi")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/sırtAgrisi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/osteopati")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/osteopati.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/recovery")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/recovery.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                
              </Row>
              <Row
                data-aos="fade-up"
                data-aos-duration="1000"
                style={{ justifyContent: "center" }}
              >
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/manuel-terapi")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/manuelTerapi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/rehabilitasyonlar")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/rehabilitasyon.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/klinik-plates")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/klinikPlates.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
             
            </Container>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default BelFitigi;
