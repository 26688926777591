import WhiteNavbar from "components/Navbars/WhiteNavbar";
import React, { useEffect } from "react";
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet-async";
import { FaCheck } from "react-icons/fa";
import Footer from "components/Footers/Footer";
import { HashLink } from "react-router-hash-link";
import { useSize } from "components/SizeControl/SizeControl";
import { Accordion, AccordionTab } from "primereact/accordion";
import imageHeader from "../assets/ossaPictures/fizyoterapi/alanlar/SporcuSagligiHeader.png";
import PictureHeader from "components/PictureHeader/PictureHeader";
import { useNavigate } from "react-router-dom";

const SporcuSagligi = () => {
  const { under1200, under992 } = useSize();
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    // Sayfa yüklendiğinde .gt-lang-code elementini bul ve değişiklik yap
    const targetLink = document.querySelector('a[data-gt-lang="tr"]'); // Değiştirmeniz gereken class veya id'yi buraya ekleyin

    if (targetLink) {
      // Otomatik tıklama
      targetLink.click();
      const imgElement = document.querySelector(".gt-current-lang img");
      const newImgSrc = "https://cdn.gtranslate.net/flags/32/tr.png"; // Değiştirmeniz gereken yeni resim yolunu buraya ekleyin
      console.log(imgElement);
      imgElement.src = newImgSrc;
      imgElement.width = "32";
      imgElement.height = "32";
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>SPORCU SAĞLIĞI | OSSA CLINIC</title>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="OSSA CLINIC sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
          data-rh="true"
        />
        <link
          rel="canonical"
          href="https://www.ossaclinic.com"
        />
        <meta property="og:title" content="SPORCU SAĞLIĞI | OSSA CLINIC" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="OSSA CLINIC sizin ihtiyaçlarınızı bilir ve en uygun çözümleri sunar"
        />
        <meta property="og:image" content="" />
        <meta
          property="og:url"
          content="https://www.ossaclinic.com"
        />
      </Helmet>
      <WhiteNavbar navbarSelection={"about-us"} />
      <PictureHeader imageHeader={imageHeader} />
      <div>
        <Container style={{ fontFamily: "Nucleo Outline" }}>
          <div>
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>SPORCU SAĞLIĞI</b>
            </h2>
          </div>
          <Row>
            <Col className="ml-auto" md="6" style={{ alignContent: "center" }}>
              <img
                alt="..."
                className="img rounded"
                src={require("assets/ossaPictures/fizyoterapi/alanlar/sporcuSagligi.png")}
              ></img>
            </Col>
            <Col
              className="mr-auto mt-5 mt-md-3"
              md="6"
              data-aos="zoom-in"
              data-aos-duration="2000"
              style={{ alignSelf: "center" }}
            >
              <p>
                Spor aktiviteleri, insan vücudu üzerinde birçok olumlu etkiye
                sahiptir ancak aynı zamanda sporcuların da yaşadıkları belirli
                sağlık sorunları vardır. <b>Ossa Fizik Tedavi Merkezi</b>,
                sporcuların sağlığını en üst düzeyde tutmak için uzman
                fizyoterapistleri ve son teknolojik cihazları ile sporcu sağlığı
                konusunda etkili çözümler sunmaktadır.
              </p>

              <p>
                <b>Ossa Fizik Tedavi Merkezi</b>'nde sporcu sağlığına yönelik
                tedaviler arasında spor yaralanmalarının tedavisi, performans
                arttırma egzersizleri, kas güçlendirme çalışmaları, masaj ve
                lazer terapiler yer almaktadır. Uzman fizyoterapistler,
                sporcuların bireysel ihtiyaçlarına göre özel tedavi planları
                hazırlayarak spor aktivitelerine devam edebilmeleri için gereken
                tedavileri uygularlar.
              </p>

              <p>
                <b>Ossa Fizik Tedavi Merkezi</b>'nin sporcu sağlığı tedavisi,
                sporcuların vücutlarına zarar vermeden stresle başa çıkmalarına
                yardımcı olur. <b>Ossa Fizik Tedavi Merkezi</b>, sporcuların
                performansını artırmak ve sakatlanma riskini azaltmak için özel
                programlar hazırlar. Ayrıca, merkezin uzman diyetisyenleri de
                sporculara sağlıklı beslenme programları sunarak,
                performanslarını artırmak ve sakatlanma risklerini en aza
                indirmek için gereken besinleri alabilmelerine yardımcı olurlar.
                Sonuç olarak, <b>Ossa Fizik Tedavi Merkezi</b>, sporcu sağlığı
                tedavisi konusunda uzman kadrosu ve son teknolojik cihazları ile
                müşterilerine en iyi hizmeti sunmaktadır.
              </p>
            </Col>
          </Row>
          <div className="title">
            <br></br>

            <h2
              className="ml-auto mr-auto text-center"
              style={{ color: "#123274", fontFamily: "Benguiat Regular" }}
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <b>SIKÇA SORULAN SORULAR</b>
            </h2>
          </div>
          <br></br>
          <div className="card">
          <Accordion activeIndex={0}>
                <AccordionTab header="Spor yaralanmalarının önlenmesi için neler yapılabilir?">
                    <p className="panel-txt">
                        Spor yaralanmalarını önlemek için uygun bir ısınma rutini, doğru teknik, uygun ekipman kullanımı ve düzenli egzersiz gibi önlemler alınabilir. <b>Ossa Fizik Tedavi merkezi</b>miz spor fizyoterapistleri, sporculara özelleştirilmiş egzersiz programları oluşturarak yaralanmaları azaltmak ve spor performansını artırmak için yardımcı olabilir.
                    </p>
                </AccordionTab>
                <AccordionTab header="Sporda en sık rastlanan yaralanmalar nelerdir?">
                    <p className="panel-txt">
                        Sporda en sık rastlanan yaralanmalar arasında burkulma, çekişme, kas gerilmeleri, tendonit ve kemik kırıkları sayılabilir.
                    </p>
                </AccordionTab>
                <AccordionTab header="Spor yaralanmaları nasıl tedavi edilir?">
                    <p className="panel-txt">
                        Spor yaralanmalarının tedavisi, yaralanmanın türüne, ciddiyetine ve sporcunun yaşına bağlı olarak değişebilir. Tedavide genellikle dinlenme, buz uygulama, ilaçlar, fizik tedavi ve rehabilitasyon gibi yöntemler kullanılır. <b>Ossa Fizik Tedavi merkezi</b>, sporculardaki yaralanmaların hızlı ve doğru şekilde teşhis edilerek, en etkili tedavilerin uygulanması için uzman bir ekibe sahiptir.
                    </p>
                </AccordionTab>
                <AccordionTab header="Spor yaralanmalarından korunmak için hangi egzersizler yapılabilir?">
                    <p className="panel-txt">
                        Spor yaralanmalarından korunmak için sporcuların esneme, kuvvet ve denge egzersizleri yapması önerilir. <b>Ossa Fizik Tedavi merkezi</b>, sporculara özelleştirilmiş egzersiz programları oluşturarak yaralanmaları azaltmak ve spor performansını artırmak için yardımcı olabilir.
                    </p>
                </AccordionTab>
                <AccordionTab header="Spor yaralanmalarının önlenmesinde doğru ekipman seçimi ne kadar önemlidir?">
                    <p className="panel-txt">
                        Spor yaralanmalarının önlenmesinde doğru ekipman seçimi son derece önemlidir. Sporcu ayakkabıları, koruyucu ekipmanlar ve spor giysilerinin uygun şekilde seçilmesi, spor yaralanmalarını azaltmada yardımcı olabilir. <b>Ossa Fizik Tedavi merkezi</b>, sporculara uygun ekipman seçimleri konusunda da danışmanlık hizmeti sunmaktadır.
                    </p>
                </AccordionTab>
                <AccordionTab header="Hangi durumlarda sporcular fizik tedaviye ihtiyaç duyabilirler?">
                    <p className="panel-txt">
                        Sporcular, sakatlanma veya yaralanma sonrası ağrı, sertleşme, hareket kısıtlılığı veya zayıflık gibi sorunlar yaşadıklarında fizik tedaviye ihtiyaç duyabilirler. Ayrıca, sportif performanslarını artırmak için de fizik tedavi yardımı alabilirler. <b>Ossa Fizik Tedavi merkezi</b>miz spor fizyoterapistleri, sporculara özelleştirilmiş egzersiz programları oluşturarak yaralanmaları azaltmak ve spor performansını artırmak için yardımcı olabilir.
                    </p>
                </AccordionTab>
                <AccordionTab header="Hangi sporlarda sakatlanma riski daha yüksektir?">
                    <p className="panel-txt">
                        Kontak sporları, futbol, basketbol, voleybol, dövüş sporları gibi spor dallarında sakatlanma riski daha yüksek olabilir. Ancak, herhangi bir spor dalında yaralanma riski vardır. <b>Ossa Fizik Tedavi merkezi</b>, her tür spor yaralanmasıyla ilgili uzmanlık ve deneyime sahip bir ekibe sahiptir.
                    </p>
                </AccordionTab>
                <AccordionTab header="Spor yaralanmalarının önlenmesinde diyetin rolü nedir?">
                    <p className="panel-txt">
                        Beslenme, spor yaralanmalarının önlenmesinde önemli bir rol oynar. Sporcuların yeterli ve dengeli beslenmeleri, kaslarının iyileşmesi ve yaralanmaların önlenmesinde yardımcı olabilir. <b>Ossa Fizik Tedavi merkezi</b>mizdeki beslenme uzmanları, sporcuların ihtiyaçlarına göre özelleştirilmiş diyet planları hazırlayarak yaralanma riskini azaltmaya ve sporcunun performansını artırmaya yardımcı olur.
                    </p>
                </AccordionTab>
                <AccordionTab header="Hangi durumlarda bir sporcu, rehabilitasyon için fizyoterapistle çalışmalıdır?">
                    <p className="panel-txt">
                        Bir sporcu, yaralanma sonrası hareket kısıtlılığı, ağrı veya sertleşme yaşarsa veya spor performansını artırmak istiyorsa rehabilitasyon için bir fizyoterapistle çalışmalıdır. <b>Ossa Fizik Tedavi merkezi</b>, sporculara özel olarak tasarlanmış rehabilitasyon programları sunar ve sporcuların yaralanmalarını en hızlı şekilde iyileştirmeye yardımcı olur.
                    </p>
                </AccordionTab>
                <AccordionTab header="Ossa Fizik Tedavi merkezi, sporculardaki yaralanmaların tedavisi için neler sunuyor?">
                    <p className="panel-txt">
                        <b>Ossa Fizik Tedavi merkezi</b>, sporculardaki yaralanmaların teşhisi ve tedavisi için uzman bir ekip sunuyor. Fizyoterapi, egzersiz terapisi, manuel terapi ve diğer tedaviler gibi bir dizi yöntem kullanarak sporcuların en hızlı şekilde iyileşmelerini sağlar. Ayrıca, sporculara özelleştirilmiş egzersiz programları ve beslenme danışmanlığı da sunar.
                    </p>
                </AccordionTab>
            </Accordion>
          </div>
          <div className="team-2 pb-0 pt-0">
          <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <div className="title">
                    <h2
                      className="title ml-auto mr-auto text-center"
                      style={{
                        color: "#123274",
                        fontFamily: "Benguiat Regular",
                      }}
                    >
                      DİĞER UZMANLIK ALANLARIMIZ
                    </h2>
                  </div>
                </Col>
              </Row>
              <Row data-aos="fade-up" data-aos-duration="1000">
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/bel-fitigi")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/belFitigi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/boyun-agrisi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/boyunAgrisi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/diz-agrisi")
                        }
                      >
                        <img
                          alt="..."
                          style={{ border: "1px solid black" }}
                          className="img img-raised rounded img-hover"
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/dizAgrisi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row data-aos="fade-up" data-aos-duration="1000">
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate(
                            "/fizik-tedavi-ve-rehabilitasyon/omurga-sagligi"
                          )
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/omurgaSagligi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/skolyoz")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/skolyoz.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/gebelik-platesi")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/gebelikPlates.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
              <Row
                data-aos="fade-up"
                data-aos-duration="1000"
                style={{ justifyContent: "center" }}
              >
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/sirt-agrisi")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/sırtAgrisi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/osteopati")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/osteopati.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/recovery")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/recovery.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                
              </Row>
              <Row
                data-aos="fade-up"
                data-aos-duration="1000"
                style={{ justifyContent: "center" }}
              >
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/manuel-terapi")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/manuelTerapi.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/rehabilitasyonlar")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/rehabilitasyon.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-image">
                      <a
                        href="#"
                        onClick={() =>
                          navigate("/fizik-tedavi-ve-rehabilitasyon/klinik-plates")
                        }
                      >
                        <img
                          alt="..."
                          className="img img-raised rounded img-hover"
                          style={{ border: "1px solid black" }}
                          src={require("assets/ossaPictures/fizyoterapi/alanlar/klinikPlates.png")}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
           
            </Container>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default SporcuSagligi;
